import React, { useState } from "react";
import Select from "react-select";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Label } from "reactstrap";
import { ME_QUERY } from "../../../graphql/queries";
import Grid from "../../../components/Grid";
import { Error } from "../../../components/Loading";

const ASSIGN_STUDENT_TO_CLASSROOM_MUTATION = gql`
  mutation ASSIGN_STUDENT_TO_CLASSROOM_MUTATION(
    $userId: Int!
    $classroomId: Int!
    $licenseId: Int!
  ) {
    assignStudentToClassroom(
      userId: $userId
      classroomId: $classroomId
      licenseId: $licenseId
    ) {
      id
    }
  }
`;

const INSTRUCTORS_QUERY = gql`
  query INSTRUCTORS_QUERY {
    instructors {
      id
      user {
        id
        firstName
        lastName
      }
      classroom {
        id
        className
      }
    }
  }
`;

const Classroom = ({ currentUser, updateUser = null }) => {
  const initialState = {
    userId: currentUser.id,
    licenseId: currentUser.licenses[0].id,
    instructor: null,
    classroom: null,
    classroomOptions: [],
    disabledButton: true
  };

  const [form, updateForm] = useState(initialState);

  const queriesToRefetch = [
    {
      query: ME_QUERY
    }
  ];

  const [
    assignStudentToClassroom,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(ASSIGN_STUDENT_TO_CLASSROOM_MUTATION, {
    refetchQueries: queriesToRefetch
  });

  const {
    data: instructorsData,
    loading: instructorsLoading,
    error: instructorsError
  } = useQuery(INSTRUCTORS_QUERY);

  if (instructorsLoading) return "Loading instructors...";
  if (instructorsError) return <Error error={instructorsError} />;
  if (mutationError) return <Error error={mutationError} />;

  const instructorOptions = () => {
    const options = instructorsData.instructors.map(instructor => {
      return {
        value: instructor.user.id,
        label: `${instructor.user.firstName} ${instructor.user.lastName}`
      };
    });

    const filteredOptions = [
      { value: options[0].value, label: options[0].label }
    ];

    for (let i = 1; i < options.length; i++) {
      if (
        filteredOptions.find(item => item.value === options[i].value) ===
        undefined
      ) {
        filteredOptions.push({
          value: options[i].value,
          label: options[i].label
        });
      }
    }

    return filteredOptions;
  };

  const classroomOptions = userId => {
    const instructorEntries = instructorsData.instructors.filter(
      instructor => instructor.user.id === userId
    );

    const options = instructorEntries.map(entry => {
      return { value: entry.classroom.id, label: entry.classroom.className };
    });

    return options;
  };

  const onSelectInstructor = value => {
    updateForm({
      ...form,
      instructor: value,
      classroom: null,
      classroomOptions: classroomOptions(value.value),
      disabledButton: true
    });
  };

  const onSelectClassroom = value => {
    updateForm({
      ...form,
      classroom: value,
      disabledButton: false
    });
  };

  const onClickAssign = () => {
    assignStudentToClassroom({
      variables: {
        userId: form.userId,
        licenseId: form.licenseId,
        classroomId: form.classroom.value
      }
    }).then(() => {
      if (updateUser) {
        updateUser("ASSIGNED");
      }
      updateForm(initialState);
    });
  };

  return (
    <Grid columns="1fr 1fr">
      {currentUser && currentUser.currentClassroom !== null ? (
        <div>
          <p>Current assignment:</p>
          <h4>
            {`${currentUser.currentClassroom.instructors[0].user.firstName} `}
            {currentUser.currentClassroom.instructors[0].user.lastName}
          </h4>
          <p>{currentUser.currentClassroom.className}</p>
        </div>
      ) : (
        <span>No assignment yet</span>
      )}
      <div>
        <Label id="label_instructor">1. Select Instructor</Label>
        <Select
          name="instructor"
          aria-labelledby="label_instructor"
          options={instructorOptions()}
          onChange={onSelectInstructor}
          value={form.instructor}
        />
        <br />
        <Label id="label_classroom">2. Select Classroom</Label>
        <Select
          name="classroom"
          aria-labelledby="label_classroom"
          options={form.classroomOptions}
          onChange={onSelectClassroom}
          value={form.classroom}
        />
        <br />
        <Button
          disabled={form.disabledButton}
          color="primary"
          onClick={onClickAssign}
          outline
        >
          {mutationLoading ? "processing" : "Assign"}
        </Button>
      </div>
    </Grid>
  );
};

export default Classroom;
