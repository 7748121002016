import React from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { Row, Col } from "reactstrap";
import { Chart } from "react-google-charts";
import { getCurrentDate } from "../../../utilities/utils";
import Loading, { Error } from "../../../components/Loading";
import splLogo from "../../../static/images/message/spl-logo.jpg";
import { GET_VELOCITY_TREND } from "../../../graphql/queries";

const VelocityTrendChart = ({ teamId, runNumber, user }) => {
  const { loading, error, data } = useQuery(GET_VELOCITY_TREND, {
    variables: { teamId },
    skip: !teamId,
    fetchPolicy: "network-only"
  });
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <span>No data yet.</span>;
  const labels = ["Story Points", "Points", "Average Velocity"];

  const chartData = [labels];

  for (let i = 0; i < data.velocityTrend.velocities.length; i++) {
    const velocity = data.velocityTrend.velocities[i];

    if (velocity.average > 0)
      chartData.push([
        velocity.sprintTitle.toString(),
        parseInt(velocity.completedPoints, 10),
        parseInt(velocity.average, 10)
      ]);
    // else chartData.push([velocity.sprintTitle.toString(), 0, 0]);
  }
  const chartOptions = {
    titlePosition: "none",
    height: 560,
    legend: { position: "bottom" },
    backgroundColor: "#FFFFFF",
    seriesType: "bars",
    pointsVisible: true,
    series: {
      0: { color: "#5F8AD5" },
      1: { type: "line", color: "#32D378", lineWidth: 3 }
    },
    chartArea: {
      width: "90%",
      height: "80%",
      top: "10",
      left: "80",
      right: "10"
    },
    hAxis: {
      title: "Sprint",
      titleTextStyle: { italic: false, fontSize: 12, color: "grey" }
    },
    vAxis: {
      title: "Story Points",
      titleTextStyle: { italic: false, fontSize: 12, color: "grey" },
      viewWindow: {
        max: data.velocityTrend.maxVelocity + 5,
        min: 0
      }
    }
  };

  const comboChartHeader = {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    marginLeft: "12%"
  };

  const renderDate = dateString => {
    if (dateString === null || dateString === "") {
      return <span />;
    }
    if (Date.parse(dateString) < 0) {
      return <span />;
    }
    const date = new Date(dateString);
    return <span>{moment(date).format("DD-MMM-YYYY")}</span>;
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <span>{user}</span>
          <br />
          <span>Run {runNumber} </span>
          <br />
          <span>{(renderDate(getCurrentDate()))}</span>
          <br />
          <br />
        </Col>
        <Col>
          <h2 style={comboChartHeader}>Velocity Trend Chart</h2>
        </Col>
        <Col className="text-center text-md-right">
          <img src={splLogo} style={{ width: "20%" }} alt="spl logo" />
        </Col>
      </Row>

      <Chart
        chartType="ComboChart"
        loader={<div>Loading Chart...</div>}
        data={chartData}
        options={chartOptions}
      />
    </React.Fragment>
  );
};

export default VelocityTrendChart;
