import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Card,
  CardHeader,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import styled from "styled-components";

import { slugify } from "../../utilities/utils";

import Backlog from "./content/Backlog";
import ScrumBoard from "./content/ScrumBoard";
import Calendar from "./content/Calendar";
import RunSprint from "./content/RunSprint";
import Reports from "./content/Reports/Reports";
import HistoryReport from "./content/Reports/HistoryReport";
import MemberLoad from "./content/Reports/MemberLoad";
import BurndownChart from "./content/Reports/BurndownChart";
import VelocityTrend from "./content/Reports/VelocityTrend";
import EndOfRun from "./content/Reports/EndOfRun";
import Runs from "./content/Runs";
import Settings from "./content/Settings";
import ContactSupport from "./content/ContactSupport";
import SprintPlanning from "./content/SprintPlanning";

// This is for the side navigation
const helpCategories = [
  {
    title: "Product Backlog",
    key: 1,
    topics: []
  },
  {
    title: "Scrum Board",
    key: 2,
    topics: []
  },
  {
    title: "Calendar",
    key: 3,
    topics: []
  },
  {
    title: "Run Sprint",
    key: 4,
    topics: []
  },
  {
    title: "Reports",
    key: 5,
    topics: [
      "History Report",
      "Member Load",
      "Burndown Chart",
      "Velocity Trend",
      "End of Run"
    ]
  },
  {
    title: "Runs",
    key: 6,
    topics: []
  },
  {
    title: "Settings",
    key: 7,
    topics: []
  },
  {
    title: "Contacting Support",
    key: 8,
    topics: []
  },
  {
    title: "How to Perform Sprint Planning",
    key: 9,
    topics: []
  }
];

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`;

const HelpModal = ({ toggleModal }) => {
  const [activeTab, toggleTab] = useState("backlog");
  // const [collapse, toggleCollapse] = useState(1);

  const toggleEvent = key => {
    if (key === 1) {
      toggleTab("backlog");
    }
    if (key === 2) {
      toggleTab("scrum-board");
    }
    if (key === 3) {
      toggleTab("calendar");
    }
    if (key === 4) {
      toggleTab("run-sprint");
    }
    if (key === 5) {
      toggleTab("reports");
    }
    if (key === 6) {
      toggleTab("runs");
    }
    if (key === 7) {
      toggleTab("settings");
    }
    if (key === 8) {
      toggleTab("contacting-support");
    }
    if (key === 9) {
      toggleTab("how-to-perform-sprint-planning");
    }
    // toggleCollapse(key);
  };
  return (
    <Modal isOpen fade={false} centered toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>Help Documentation</ModalHeader>
      <ModalBody>
        <StyledRow>
          <Col>
            If at any point you need help or are struggling to use the agile
            board, this Help Documentation or the Team Reference Guide should
            answer most of your questions.
          </Col>
        </StyledRow>
        <StyledRow>
          <Col md="4">
            {helpCategories.map(({ title, key, topics }) => (
              <Card className="ListGroup-Card" key={key}>
                <CardHeader onClick={() => toggleEvent(key)} data-event={key}>
                  {title}
                </CardHeader>

                <ListGroup>
                  {topics.map(topic => (
                    <ListGroupItem
                      key={`topic-${topic}`}
                      className={classnames({
                        active: activeTab === slugify(topic)
                      })}
                      onClick={() => {
                        toggleTab(slugify(topic));
                      }}
                    >
                      {topic}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Card>
            ))}
          </Col>
          <Col>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="backlog">
                <Backlog />
              </TabPane>
              <TabPane tabId="scrum-board">
                <ScrumBoard />
              </TabPane>
              <TabPane tabId="calendar">
                <Calendar />
              </TabPane>
              <TabPane tabId="run-sprint">
                <RunSprint />
              </TabPane>
              <TabPane tabId="reports">
                <Reports />
              </TabPane>
              <TabPane tabId="history-report">
                <HistoryReport />
              </TabPane>
              <TabPane tabId="member-load">
                <MemberLoad />
              </TabPane>
              <TabPane tabId="burndown-chart">
                <BurndownChart />
              </TabPane>
              <TabPane tabId="velocity-trend">
                <VelocityTrend />
              </TabPane>
              <TabPane tabId="end-of-run">
                <EndOfRun />
              </TabPane>
              <TabPane tabId="runs">
                <Runs />
              </TabPane>
              <TabPane tabId="settings">
                <Settings />
              </TabPane>
              <TabPane tabId="contacting-support">
                <ContactSupport />
              </TabPane>
              <TabPane tabId="how-to-perform-sprint-planning">
                <SprintPlanning />
              </TabPane>
            </TabContent>
          </Col>
        </StyledRow>
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default HelpModal;
