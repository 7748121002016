import React from "react";
import HistoryReportImage from "../../../../static/images/helpModal/HistoryReport.png";

const HistoryReport = () => (
  <div>
    <h3>History Report</h3>
    <p>
      These are weekly reports that provide a view into how the week progressed, 
      the decisions you made and the outcomes of these decisions. 
      The History report is a day by day playback of team activities, 
      decisions and your responses.
    </p>

    <img key="HistoryReport1" className="HelpModalimage" alt="History Report screenshot" src={HistoryReportImage} />
    <br/><br/>
  </div>
);

export default HistoryReport;
