/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
import React, { Component, Fragment } from "react";
import { Row, Col, Card, ListGroup, ListGroupItem } from "reactstrap";
import styled from "styled-components";

import { Redirect } from "react-router-dom";
import ReportContentPanel from "./ReportContentPanel";
import { HISTORY_QUERY } from "../../../graphql/queries";
// import AgileStoryPointsCard from "./components/AgileStoryPointsCard";
// this mutation runs if we come to reports from runweek with EOW - will update to OFF
import Loading, { Error } from "../../../components/Loading";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ReportHeader from "./components/HistoryItems/ReportHeader"

const StyledListGroupItem = styled(ListGroupItem)`
  padding: 0.45rem 1.25rem !important &.active {
    background-color: #c1c1c1 !important;
    border-color: #c1c1c1 !important;
  }
`;

class TeamReview extends Component {
  state = {
    selectedReport: "history",
    historyForTheWeek: [],
    status: "Closed",
    collapse: false,
    HistoryCardOpen: false,
    ChartCardOpen: false,
    teamWeeks: 8,
    selectedWeek: 8,
    selectedSprintNumber: 4,
    selectedSprintId: "",
    activeTab: 1
  };

  onEntering() {
    this.setState({ status: "Opening..." });
  }

  onEntered() {
    this.setState({ status: "Opened" });
  }

  onExiting() {
    this.setState({ status: "Closing..." });
  }

  onExited() {
    this.setState({ status: "Closed" });
  }

  getWeeks = weekNumber => {
    const weeks = [];
    for (let i = 1; i <= weekNumber; i += 1) {
      weeks.push({ id: i, name: `Week ${i}` });
    }
    return weeks;
  };

  getSprints = sprintNumber => {
    // at this point, weekNumber is the current week which is in planning.
    // we only have reports for prior weeks so subtract 1.
    const sprints = [];
    for (let i = 1; i <= sprintNumber - 1; i += 1) {
      sprints.push({ id: i, name: `Sprint ${i}` });
    }
    return sprints;
  };

  getFilteredHistory = historyItems => {
    if (historyItems.length > 0) {
      return historyItems.filter(h => h.weekNumber === this.state.selectedWeek);
    }
    return [];
  };

  // toggleNavLink = activeReport => {
  //   this.setState({ activeReport });
  // };

  setActiveWeek(id) {
    this.setState({
      selectedWeek: id,
      selectedReport: "history"
    });
  }

  setActiveSprint(sprintNumber, id) {
    this.setState({
      selectedReport: "burndown",
      selectedSprintNumber: sprintNumber,
      selectedSprintId: id,
      activeTab: sprintNumber
    });
  }

  setActiveReport(report) {
    this.setState({
      selectedReport: report
    });
  }

  toggleHistoryCard = () => {
    this.setState(prevState => ({
      HistoryCardOpen: !prevState.HistoryCardOpen
    }));
  };

  toggleChartCard = () => {
    this.setState(prevState => ({
      ChartCardOpen: !prevState.ChartCardOpen
    }));
  };

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  closeCollapse() {
    this.setState({ collapse: false });
  }

  renderSprintListItems(sprints) {
    const returnArray = [];
    for (let i = 0; i < sprints.length; i += 1) {
      const sprint = sprints[i];
      returnArray.push(
        <StyledListGroupItem
          key={sprint.id}
          className={
            sprint.sprintNumber === this.state.activeTab ? "active" : ""
          }
          tag="a"
          href="#"
          action
          onClick={() => this.setActiveSprint(sprint.sprintNumber, sprint.id)}
        >
          Sprint: {sprint.sprintNumber}
        </StyledListGroupItem>
      );
    }
    return returnArray;
  }

  render() {
    if (!this.props.match.params.id) {
      return (
        <Redirect
          to={{
            pathname: "/teams"
          }}
        />
      );
    }

    return (
      <span>
        Changing this to a menu option displaying everything except run sprint.
        also, should make scrumboard and backlog read-only
      </span>
    );
  }
}

export default TeamReview;

/** *
 * <Query
        query={HISTORY_QUERY}
        variables={{ teamId: this.props.match.params.id }}
      >
        {({ data, loading, error }) => {
          if (loading) return <Loading />;
          if (error) return <Error error={error} />;
          const { historyItems } = data;
          return (
            <Fragment>
              <Row>
                <Col lg="3">
                  <Card body>
                    <ListGroup>
                      <ListGroupItem>
                        <span>History Reports</span>
                        <ListGroup>
                          {this.getWeeks(this.state.teamWeeks).map(
                            ({ id, name }) => (
                              <StyledListGroupItem
                                key={id}
                                className={
                                  this.state.activeWeek === id ? "active" : ""
                                }
                                tag="a"
                                href="#"
                                action
                                onClick={() => this.setActiveWeek(id)}
                              >
                                {name}
                              </StyledListGroupItem>
                            )
                          )}
                        </ListGroup>
                      </ListGroupItem>

                      <ListGroupItem>
                        <span>Burndown Charts</span>
                         <ListGroup>
                          {selectedTeam &&
                            selectedTeam.sprints &&
                            this.renderSprintListItems(selectedTeam.sprints)}
                        </ListGroup> 
                      </ListGroupItem>

                      <ListGroupItem>
                        <span>Other Reports</span>
                        <ListGroup>
                          <StyledListGroupItem 
                            key="vt"
                            className={
                              this.state.activeRerport === "velocity"
                                ? "active"
                                : ""
                            }
                            tag="a"
                            href="#"
                            action
                            onClick={() => this.setActiveReport("velocity")}
                          >
                            Velocity Trend
                          </StyledListGroupItem>
                          <StyledListGroupItem
                            key="cfc"
                            className={
                              this.state.activeReport === "CFD" ? "active" : ""
                            }
                            tag="a"
                            href="#"
                            action
                            onClick={() => this.setActiveReport("CFD")}
                          >
                            Cumulative Flow Diagram
                          </StyledListGroupItem> 
                          <StyledListGroupItem
                            key="cp"
                            tag="a"
                            href="#"
                            action
                            onClick={() => this.setActiveReport("capacity")}
                          >
                            Capacity Planning
                        </StyledListGroupItem>
                          <StyledListGroupItem
                            key="eor"
                            className={
                              this.state.activeReport === "EOR" ? "active" : ""
                            }
                            tag="a"
                            href="#"
                            action
                            onClick={() => this.setActiveReport("EOR")}
                          >
                            End of Run Report
                          </StyledListGroupItem>
                        </ListGroup>
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
                <Col lg="9">
                  <ReportContentPanel
                    teamId={this.props.match.params.id}
                    history={this.getFilteredHistory(historyItems)}
                    activeTab={this.state.selectedReport}
                    selectedWeek={this.state.selectedWeek}
                    sprintId={this.state.selectedSprintId}
                    sprintNumber={this.state.selectedSprintNumber}
                    currentUser={this.props.currentUser}
                  />
                </Col>
              </Row>
            </Fragment>
          );
        }}
      </Query>
 * 
 * 
 */
