import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { STORIES_QUERY, TEAM_QUERY } from "graphql/queries";
import Loading, { Error } from "components/Loading";

import { ProjectContext } from "GlobalState";
import Backlog from "./components/Backlog";
// this component gets the data loading before showing the backlog

const BacklogContainer = ({ currentUser }) => {
  const [state] = useContext(ProjectContext);
  const { loading: tLoading, error: tError } = useQuery(TEAM_QUERY, {
    variables: { teamId: state.currentTeamId },
    skip: !state.currentTeamId
  });
  const { loading, error, data } = useQuery(STORIES_QUERY, {
    variables: { teamId: state.currentTeamId },
    skip: !state.currentTeamId
  });

  if (loading || tLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  if (tError) {
    return <Error error={tError} />;
  }
  return (
    <Backlog
      currentUser={currentUser}
      teamId={state.currentTeamId}
      userId={currentUser.id}
      data={data}
      projectContext={state}
    />
  );
};
export default BacklogContainer;
