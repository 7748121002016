import { UncontrolledTooltip as ReactstrapUncontrolledTooltip } from "reactstrap";
import styled from "styled-components";

const UncontrolledTooltip = styled(ReactstrapUncontrolledTooltip)`
  font-size: 0.75rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  padding: 6px !important;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #d2d7df;
  transition: opacity 0.2s ease;
  opacity: 0;

  .tooltip-inner {
    background: none;
    color: black;
    font-weight: bold;
    padding: 0;
    line-height: 1.3;
  }

  ul {
    padding-left: 20px;
    margin-top: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul > li {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.show {
    opacity: 1;
  }

  .arrow:before {
    display: none !important;
  }
`;

export { UncontrolledTooltip };
