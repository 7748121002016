import styled from "styled-components";
import { Card } from "reactstrap";

const StyledStoryContainer = styled(Card)`
  cursor: pointer !important;
  .card-header {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
  }

  > div .badge {
    margin-left: 5px;
  }

  .card-body {
    max-height: none;
    overflow-y: visible;
  }

  .card-body > div {
    min-height: 200px;
  }
`;

export default StyledStoryContainer;
