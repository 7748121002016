import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import auth from "../../Auth";
import SentryErrorBoundary from "../../components/SentryErrorBoundary";
// import LogoSrc from "../../static/images/logo.png";
// const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   text-align: center;

//   img {
//     max-width: 150px;
//     margin-bottom: 20px;
//   }
// `;

class Login extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      auth.login();
    }
  }

  render() {
    if (auth.isAuthenticated()) {
      return (
        <SentryErrorBoundary>
          <Redirect to="/Planning" />
        </SentryErrorBoundary>
      );
    }

    return (
      // <div className="Login-component">
      //   <div className="app">
      //     <div className="app-body">
      //       <main className="main Login-btn">
      //         <Container fluid>
      //           {/* <Card>
      //             <CardBody>
      //               <img src={LogoSrc} alt="SPL Logo" />
      //               <CardTitle>Welcome to SPL's Agile Simulation</CardTitle>
      //               <p>Shall we get started?</p>
      //               <Button tag={Link} color="primary" to="/backlog">
      //                 Log in
      //             </Button>
      //             </CardBody>
      //           </Card> */}
      //           <Button className="getstartButton" tag={Link} to="/backlog">
      //             Get Started
      //           </Button>
      //         </Container>
      //       </main>
      //     </div>
      //     <Footer />
      //   </div>
      // </div>
      null
    );
  }
}

export default Login;
