import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import gql from "graphql-tag";
import { initialCap } from "../../../utilities/utils";

export const SPRINT_QUERY = gql`
  query SPRINT_QUERY($id: ID!) {
    sprints(where: { team: { id: $id } }) {
      id
      sprintName
      sprintNumber
    }
  }
`;

const StyledBootstrapTable = styled(BootstrapTable)`
  .table-bordered thead th,
  .table-bordered thead td {
    font-size: 0.75rem;
  }
  td {
    vertical-align: middle;
  }
  .page-item.active .page-link,
  .pagination-datatables li.active .page-link,
  .pagination li.active .page-link,
  .page-item.active .pagination-datatables li a,
  .pagination-datatables li .page-item.active a,
  .pagination-datatables li.active a,
  .page-item.active .pagination li a,
  .pagination li .page-item.active a,
  .pagination li.active a {
    background-color: #5378AD;
    border-color: #5378AD;
  }
  }
`;
const StyledTableHeaderRow = styled(Row)`
  .search {
    margin-bottom: 1.5rem;
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > .col:last-child {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  dl {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
  dl .col {
    margin-bottom: 0;
  }
`;
class StoryStatusTable extends Component {
  formatRequiredStories = cell => {
    if (cell) {
      return (
        <i className="fa fa-check" style={{ background: "transparent" }} />
      );
    }
    return <span />;
  };

  formatStatus = cell => {
    if (!cell) {
      return "";
    }
    if (cell === "IN_PROGRESS") {
      return "In Progress";
    }

    return initialCap(cell);
  };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  createCustomToolBar = props => (
    <Col>
      <StyledTableHeaderRow>
        <Col sm="12" md={{ size: 6, offset: 6 }}>
          <div className="search">{props.components.searchPanel}</div>
        </Col>
      </StyledTableHeaderRow>
    </Col>
  );

  render() {
    if (!this.props.stories || this.props.stories.length < 1) {
      return <span></span>;
    }
    const tableOptions = {
      // toolBar: this.createCustomToolBar,
      clearSearch: true,
      sizePerPage: 40,
      sizePerPageList: [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "40",
          value: 40
        }
      ]
    };
    return (
      <Fragment>
        {this.props.sprintNumber > 0 && (
          <b>Sprint Number: {this.props.sprintNumber}</b>
        )}
        {this.props.sprintNumber === 0 && <b>Remaining Backlog </b>}
        <StyledBootstrapTable
          data={this.props.stories.filter(s => s.storyStatus !== "Complete")}
          options={tableOptions}
          hover
          condensed
          version="4"
          striped
        >
          <TableHeaderColumn isKey dataField="storyName" width="40%">
            Story Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="requiredStory"
            dataAlign="center"
            dataFormat={this.formatRequiredStories}
            width="15%"
          >
            Required for MVP?
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="storyPoints"
            dataAlign="center"
            width="15%"
          >
            Points
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            dataAlign="center"
            dataFormat={this.formatStatus}
            width="15%"
          >
            Status
          </TableHeaderColumn>
        </StyledBootstrapTable>
      </Fragment>
    );
  }
}

export default StoryStatusTable;
