import React, { Fragment, useContext, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { useQuery } from "@apollo/client";
import { ProjectContext } from "GlobalState";
import ClassroomManagement from "./components/ClassroomManagement";
// import CreateClassroomCard from "../../components/CreateClassroomCard";
import { INSTRUCTOR_CLASSES_QUERY } from "../../graphql/queries";
import Loading, { Error } from "../../components/Loading";

const Classrooms = props => {
  const [state, dispatch] = useContext(ProjectContext);
  const { id } = props.currentUser;
  const { loading, error, data: cdata } = useQuery(INSTRUCTOR_CLASSES_QUERY, {
    variables: { instructorId: id },
    onCompleted: async data => {
      if (
        data &&
        data.instructors.length > 0 &&
        data.instructors[0].classroom &&
        data.instructors[0].classroom.id &&
        state.instructorSelectedClassIndex < 0
      ) {
        const payload = {
          index: 0
        };
        await dispatch({
          type: "SET_INSTRUCTOR_CLASS_INDEX",
          payload
        });
      }
    }
  });

  const classroomList = useMemo(() => {
    const pList = [];
    if (cdata && cdata.instructors && cdata.instructors.length > 0) {
      const keys = Object.keys(cdata.instructors);
      for (let r = 0; r < keys.length; r++) {
        const { classroom } = cdata.instructors[r];

        pList.push({
          ...classroom
        });
      }
      return pList;
    }
    return [];
  }, [cdata]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <Fragment>
      <Row>
        <Col>
          <ClassroomManagement
            instructorId={id}
            classrooms={[...classroomList]}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Classrooms;
