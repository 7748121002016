import styled from "styled-components";

const StoryCardIcons = styled.div`
  display: flex;

  > div {
    margin-right: 5px;
  }

  img {
    width: 20px;
  }
`;

export default StoryCardIcons;
