import React, { Fragment } from "react";
import { Badge } from "reactstrap";
import { useQuery } from "@apollo/client";
import Worker from "../../../components/Worker/Worker";
import Loading, { Error } from "../../../components/Loading";
import { STORIES_QUERY } from "../../../graphql/queries";
import { UncontrolledTooltip } from "../../../components/styled/UncontrolledTooltip";
import StoryCardIcons from "../../../components/styled/StoryCardIcons";

const StoryFooter = props => {
  const { loading: loadingS, error: errorS, data: storyData } = useQuery(
    STORIES_QUERY,
    {
      variables: { teamId: props.teamId },
      skip: !props.teamId
    }
  );
  if (loadingS) return <Loading />;
  if (errorS) return <Error error={errorS} />;

  const currentStory = storyData.stories.find(s => s.id === props.storyId);
  return (
    <Fragment>
      <StoryCardIcons id={`storyDevelopers-${currentStory.id}`}>
        {currentStory.workersLink
          .map(w => w.teamWorker)
          .filter(t => t.skillNumber === 1)
          .map(filteredWorker => (
            <Worker
              key={filteredWorker.id}
              workerData={filteredWorker}
              imageOnly
            />
          ))}
      </StoryCardIcons>
      <StoryCardIcons id={`storyTesters-${currentStory.id}`}>
        {currentStory.workersLink
          .map(w => w.teamWorker)
          .filter(t => t.skillNumber === 2)
          .map(filteredWorker => (
            <Worker
              key={filteredWorker.id}
              workerData={filteredWorker}
              imageOnly
            />
          ))}
      </StoryCardIcons>
      <div>
        {/* <span id={`totalEffort-${story.totalPlannedEffort}`}>
                              <Badge color="light">{story.totalPlannedEffort}</Badge>
                              <UncontrolledTooltip placement="top" target={`totalEffort-${story.totalPlannedEffort}`}>
                                planned effort
                              </UncontrolledTooltip>
                            </span> */}
        {/* <span id = {`storyEffort-${story.totalPlannedEffort}}`>
                              <Badge color="info">{story.totalPlannedEffort}</Badge>
                              <UncontrolledTooltip
                              placement="top"
                              target={`storyEffort-${story.totalPlannedEffort}`} >
                           
                              story total planned effort
                            </UncontrolledTooltip>
             </span> */}
        <span id={`storyPoints-${currentStory.id}`}>
          <Badge color="warning">{currentStory.storyPoints}</Badge>
          <UncontrolledTooltip
            placement="top"
            target={`storyPoints-${currentStory.id}`}
          >
            story points
          </UncontrolledTooltip>
        </span>

        <span id={`businessValue-${currentStory.id}`}>
          <Badge color="primary">{currentStory.businessPoints}</Badge>
          <UncontrolledTooltip
            placement="top"
            target={`businessValue-${currentStory.id}`}
          >
            business value
          </UncontrolledTooltip>
        </span>
        <span id={`priority-${currentStory.businessPriority}`}>
          <Badge color="dark">{currentStory.sprintPriority}</Badge>
          <UncontrolledTooltip
            placement="top"
            target={`priority-${currentStory.businessPriority}`}
          >
            story priority
          </UncontrolledTooltip>
        </span>
      </div>
    </Fragment>
  );
};

export default StoryFooter;
