import React from "react";

/* 
0 training
*/
export const TeamTraining = props => (
  <div className="content">
    <p>
      <strong>Team Training</strong>
    </p>
    <p>The team attended the Agile Bootcamp all day today. </p>
  </div>
);
