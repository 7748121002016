import React from "react";
import StyledDiv from "./StyledDiv";

const ProductGoal = () => (
  <StyledDiv>
    <h3>Product Goal</h3>
    <p>
      The product goal will help us achieve the vision of creating an awesome
      experience for employees at every interaction with our company assets and
      resources.
    </p>
    <p>
      The immediate impact of this product will be to provide our employees with
      universal access and control over their personal information or choices.
      The customer for this product is Human Resource group and the users are
      the employees of our company. Our minimal viable product needs to be ready
      to launch in time for the benefits open enrollment period and needs to be
      ready for use within two months.
    </p>
    <p>
      The portal needs to be accessible 24 hours a day, 7 days a week. It will
      be accessed by employees and spouses from home, by multiple branch office
      locations, and by employees on the go. It will serve as the single point
      of management for all employee benefits including medical, dental,
      prescription, vision, flex spending accounts, and 401K options.
    </p>
    <p>
      To assist the human resource group and to help employees, the portal will
      be comprised of a full set of documents including benefit plan coverage,
      enrollment information, plan descriptions, claim forms, links to provider
      directories, frequently asked questions and answers, government
      requirements, and anything that your employees need regarding their
      benefits. The portal will offer online enrollment options and access to
      the Uniworld data management system currently in place.
    </p>
    <p>
      <b>Minimal Viable Product</b>
    </p>
    <p>
      The minimal viable product should include the basic benefits and payroll
      management portal. MVP stories in the backlog are tagged as “Required for
      MVP.”
    </p>
    <p>The MVP is not expected to have the following:</p>
    <ul>
      <li>Provide supporting training brochures or content</li>
      <li>Provide follow-on support (transitioned to the helpdesk)</li>
    </ul>
    <p>
      The portal must be capable of responding to requests in less than 2
      seconds and work with all the major browsers.
    </p>
  </StyledDiv>
);

export default ProductGoal;
