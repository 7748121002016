import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import styled from "styled-components";

const StyledDropdown = styled(Dropdown)`
  &.btn-group-sm > .btn {
    padding: 0 0.5rem;
  }

  .dropdown-item {
    padding: 5px 10px;
    border-bottom: 0;
  }

  .dropdown-divider {
    margin: 0;
  }
`;

const InteractionMenu = props => {
  const { toggleModal, disabled } = props;
  const [dropdownOpen, toggle] = useState(false);

  return (
    <StyledDropdown
      size="sm"
      isOpen={dropdownOpen}
      toggle={() => toggle(!dropdownOpen)}
    >
      <DropdownToggle disabled={disabled} color="link">
        <i className="fa fa-plus" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Interaction Types</DropdownItem>
        <DropdownItem onClick={() => toggleModal("1-on-1 Session")}>
          1-on-1 Session
        </DropdownItem>
        <DropdownItem onClick={() => toggleModal("Scrum Event")}>
          Scrum Event / Meeting
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => toggleModal("Individual Reward")}>
          Individual Reward
        </DropdownItem>
        <DropdownItem onClick={() => toggleModal("Team Reward")}>
          Team Reward
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => toggleModal("Training")}>
          Training
        </DropdownItem>
      </DropdownMenu>
    </StyledDropdown>
  );
};

export default InteractionMenu;
