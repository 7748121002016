/* eslint-disable import/prefer-default-export */
import React from "react";
import moment from "moment";
import styled from "styled-components";
import splLogo from "../../../../static/images/message/spl-logo.jpg";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const renderDate = dateString => {
  if (dateString === null || dateString === "") {
    return <span />;
  }
  if (Date.parse(dateString) < 0) {
    return <span />;
  }
  const date = new Date(dateString);
  return <span>{moment(date).format("DD-MMM-YYYY, hh:mm A")}</span>;
};

export const ReportHeader = props => (
  <StyledDiv>
    <h6>History Report For Week# {props.weekNumber}</h6>

    <div>
      &nbsp; For: <strong>{props.properties[1].itemValue}</strong>
    </div>
    <div>
      &nbsp; Run Time: <strong>{(renderDate(props.properties[2].itemValue))}</strong>
    </div>
    <div>
      &nbsp; Team Id: #
      <strong>{props.properties[3].itemValue.slice(-5)}</strong>
    </div>
    <img src={splLogo} style={{ width: "12%" }} alt="spl logo" />
  </StyledDiv>
);
