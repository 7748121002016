import React, { Fragment } from "react";
import {
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  ButtonGroup
} from "reactstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Mutation } from "@apollo/client/react/components";
import StyledInteractionComponent from "../../../components/styled/StyledInteractionComponent";
import StoryCardIcons from "../../../components/styled/StoryCardIcons";
import {
  getDisplayName,
  formatIndividualReward,
  formatTeamReward,
  formatConferenceDuration,
  formatMeetingDuration
} from "../../../utilities/utils";
import { DELETE_INTERACTION_MUTATION } from "../../../graphql/mutations";
import {
  WEEKDAY_INTERACTIONS_QUERY,
  MEMBER_LOAD_QUERY
} from "../../../graphql/queries";
import { Error } from "../../../components/Loading";
import Worker from "../../../components/Worker/Worker";

const StyledCardHeader = styled(CardHeader)`
  &.card-header {
    padding: 0.25rem 0.5rem;
  }
`;

const Interaction = ({
  data,
  toggleModal,
  sprintId,
  weekNumber,
  disabled,
  teamId
}) => {
  const renderEditButton = () => {
    const isStandUpMeeting = data?.meetingDetail?.topic === "DAILYSTANDUP";

    if (!disabled && !isStandUpMeeting && data.type !== "TRAINING") {
      return (
        <Button
          disabled={disabled}
          outline
          size="sm"
          onClick={() => {
            toggleModal({ type: interaction.modalType, interData: data });
          }}
        >
          <FontAwesomeIcon icon={["far", "edit"]} />
        </Button>
      );
    }
    return <span />; // eslint consistent return
  };

  let interaction = {};

  switch (data.type) {
    case "CONFERENCE":
      interaction = {
        body: `${_.startCase(
          _.toLower(data.conferenceDetail.topic)
        )} ${_.startCase(_.toLower(data.conferenceDetail.feedback))}`,
        footer: (
          <StoryCardIcons>
            <Worker
              key={data.conferenceDetail.teamWorker.id}
              workerData={data.conferenceDetail.teamWorker}
              imageOnly
            />
            <div>
              {formatConferenceDuration(data.conferenceDetail.duration)}
            </div>
          </StoryCardIcons>
        ),
        modalType: "1-on-1 Session"
      };
      break;
    case "MEETING":
      interaction = {
        body: getDisplayName(data.meetingDetail.topic), // ${data.meetingDetail.feedback}
        // footer: data.meetingDetail.recurring ? <i className="fa fa-recycle" style={{ color: "black" }} /> : <span />,
        footer: (
          <Fragment>
            <StoryCardIcons>
              <Worker
                key={data.meetingDetail.id}
                workerData={{ name: "Team" }}
                imageOnly
              />
              <div>{formatMeetingDuration(data.meetingDetail.duration)}</div>
            </StoryCardIcons>
          </Fragment>
        ),
        modalType: "Scrum Event"
      };
      break;
    case "INDVREWARD":
      interaction = {
        body: `${formatIndividualReward(data.indvRewardDetail.rewardKind)}`,
        footer: (
          <StoryCardIcons>
            <Worker
              key={data.indvRewardDetail.teamWorker.id}
              workerData={data.indvRewardDetail.teamWorker}
              imageOnly
            />
          </StoryCardIcons>
        ),
        modalType: "Individual Reward"
      };
      break;
    case "TEAMREWARD":
      interaction = {
        body: `${formatTeamReward(data.teamRewardDetail.rewardKind)}`,
        footer: "",
        modalType: "Team Reward"
      };
      break;
    case "TRAINING":
      interaction = {
        body: `${_.startCase(
          _.toLower(data.trainingDetail.training.trainingName)
        )}`,
        footer: (
          <Fragment>
            <StoryCardIcons>
              <Worker
                key={
                  data.trainingDetail.training.requiresTeam
                    ? data.trainingDetail.id
                    : data.trainingDetail.teamWorker.id
                }
                workerData={
                  data.trainingDetail.training.requiresTeam
                    ? { name: "Team" }
                    : data.trainingDetail.teamWorker
                }
                imageOnly
              />
              <div>8 hrs</div>
            </StoryCardIcons>
          </Fragment>
        ),
        modalType: "Training"
      };
      break;
    default:
      interaction = {};
  }
  return (
    <StyledInteractionComponent>
      <StyledCardHeader
        onClick={() => toggleModal({ type: interaction.modalType, data })}
      >
        {interaction.modalType}
      </StyledCardHeader>
      <CardBody
        onClick={() => toggleModal({ type: interaction.modalType, data })}
      >
        {interaction.body}
      </CardBody>
      {interaction.footer === "" && disabled === true ? null : (
        <CardFooter>
          <span>{interaction.footer}</span>{" "}
          <ButtonGroup>
            {renderEditButton()}

            <Mutation
              mutation={DELETE_INTERACTION_MUTATION}
              variables={{ interactionId: data.id }}
              refetchQueries={[
                {
                  query: WEEKDAY_INTERACTIONS_QUERY,
                  variables: { teamId, sprintId, weekNumber }
                },
                {
                  query: MEMBER_LOAD_QUERY,
                  variables: { teamId, sprintId }
                }
              ]}
            >
              {(deleteInteraction, { loading, error }) => {
                if (error) return <Error error={error} />;
                return (
                  <div>
                    {disabled ? null : (
                      <Button
                        disabled={loading ? true : !!disabled}
                        onClick={() => deleteInteraction()}
                        size="sm"
                        outline
                        color="danger"
                      >
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                      </Button>
                    )}
                  </div>
                );
              }}
            </Mutation>
          </ButtonGroup>
        </CardFooter>
      )}
    </StyledInteractionComponent>
  );
};

export default Interaction;
