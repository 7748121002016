/* eslint-disable max-len */
import React from "react";
import styled from "styled-components";

const StyledListItem = styled.li`
  margin-bottom: 10px;
`;

const ScrumEvents = () => (
  <div id="ScrumEvents">
    <ul>
      <StyledListItem>
        <strong>Sprint</strong> <br />
        Turn ideas into value
      </StyledListItem>
      <StyledListItem>
        <strong>Sprint Planning</strong> <br />
        Layout the work for the sprint
      </StyledListItem>
      <StyledListItem>
        <strong>Daily Scrum / Stand Up</strong> <br />
        Inspect progress towards the Sprint goal and adapt as necessary
      </StyledListItem>
      <StyledListItem>
        <strong>Sprint Review</strong> <br />
        Inspect and adapt the outcome of the Sprint
      </StyledListItem>
      <StyledListItem>
        <strong>Sprint Retrospective </strong>
        <br />
        Discuss how to increase quality and effectiveness
      </StyledListItem>
    </ul>
  </div>
);
export default ScrumEvents;
