import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Browser } from "react-kawaii";
import styled from "styled-components";

import { Button } from "reactstrap";

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 50px auto;
  max-width: 500px;

  .ant-btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.75rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error && process.env.REACT_APP_ENVIRONMENT === "local") {
      return <Message>{this.state.error}</Message>;
    }

    if (this.state.error) {
      console.log("Error! Error! 🤖");
      // render fallback UI
      return (
        <Message>
          <Browser size={100} mood="shocked" color="var(--gray-color)" />
          <h2>Uh-oh. It looks like there was an error.</h2>
          <p>
            It&apos;s been automatically reported, but we&apos;d be grateful if
            you could give us some feedback on what you were trying to do so we
            can whip
            <sup>1</sup> our server monkeys into fixing it ASAP.
          </p>
          <Button color="primary" onClick={() => Sentry.showReportDialog()}>
            Report feedback
          </Button>
          <p>
            <small>
              <sup>1</sup> We don&apos;t <em>really</em> whip them.
            </small>
          </p>
        </Message>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default SentryErrorBoundary;
