import React from "react";
import Worker from "../../../components/Worker/Worker";

const AssistantPanel = props => {
  const tempWorkerData = {
    name: "Assistant"
  };

  return (
    <div className="component-Alert">
      <div className="resource-Profile">
        <Worker imageOnly workerData={tempWorkerData} />
      </div>{" "}
      <div className="alert-Message">
        {props &&
          props.playScriptList &&
          props.playScriptList.map(s => (
            <div key={99} id="message">
              {s}
            </div>
          ))}
      </div>
      <br />
      <br />
    </div>
  );
};
export default AssistantPanel;
