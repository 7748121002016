import styled from "styled-components";

const CenteredLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  > * {
    margin-bottom: 35px;
  }
`;

export default CenteredLayout;
