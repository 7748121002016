/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup
} from "reactstrap";
import { Redirect, useHistory } from "react-router-dom";
// import Loading, { Error } from "../../components/Loading";
import { useApolloClient, useQuery, useLazyQuery } from "@apollo/client";
import moment from "moment";
import TeamList from "routes/ScrumBoard/components/TeamList";
import { TEAM_QUERY } from "../../graphql/queries";
import Table from "../../components/styled/Table";
import DeleteTeam from "./components/DeleteTeam";
import InitializeTeam from "./components/InitializeTeam";
import Loading, { Error } from "../../components/Loading";
import { ProjectContext } from "../../GlobalState";
import { useAsyncState } from "../../utilities/utils";

const Teams = props => {
  const [state, dispatch] = useContext(ProjectContext);
  const history = useHistory();
  const {
    id,
    firstName,
    lastName,
    welcomeStatus,
    classStudentRuns,
    classrooms,
    currentTeam
  } = props.currentUser;
  const [runDeleted, setRunDeleted] = useAsyncState(false);
  const [activeRunId, setActiveRunId] = useAsyncState(0);
  const [nextActiveRunId, setNextActiveRunId] = useAsyncState(0);
  const [clickedRunSeqNo, setClickedRunSeqNo] = useAsyncState(0);
  const [skipQuery, setSkipQuery] = useAsyncState(true);
  const [toBacklog, setToBacklog] = useAsyncState(false);
  const [endOfRuns, setEndOfRuns] = useAsyncState(false);
  const [
    getTeamData,
    { teamData, loading, error, refresh: refreshTeam }
  ] = useLazyQuery(TEAM_QUERY, {
    onCompleted: async data => {
      if (data && data.team) {
        const payload = {
          currentTeamId: data.team.id,
          currentTeamStatus: data.team.currentStatus,
          currentSprintId: data.team.currentSprintId,
          currentSprintNumber: data.team.currentSprintNumber,
          currentWeekNumber: data.team.currentWeekNumber,
          currentWeekDay: data.team.currentWeekDay,
          currentRunDay: data.team.currentRunDay,
          runSequenceNumber: clickedRunSeqNo,
          hasActiveTeam: true,
          studentId: props.currentUser.id,
          studentFirst: props.currentUser.firstName,
          studentLast: props.currentUser.lastName,
          sprints: data.team.sprints
        };
        if (data && data.team) {
          refreshTheRun(data.team.id);
        }

        await dispatch({
          type: "UPDATE_LOCAL_TEAM",
          payload
        });
      }
    }
  });
  useEffect(() => {
    const activeRecord = classStudentRuns.find(c => c.status === "ACTIVE");
    if (activeRecord) {
      setActiveRunId(activeRecord.id);
    } else {
      const found = classStudentRuns.find(c => c.status === "WAITING");
      if (found) {
        setNextActiveRunId(found.id);
      }
    }
  }, [
    runDeleted, // need this to recalculate
    classStudentRuns,
    setActiveRunId,
    setNextActiveRunId,
    activeRunId,
    nextActiveRunId
  ]);

  const refreshTheTeam = async teamId => {
     getTeamData({ variables: { teamId } });
    setToBacklog(true);
  };
  const refreshTheRun = async (teamId, itemId) => {
     getTeamData({ variables: { teamId: teamId, itemId } });

    setEndOfRuns(true);
  };

  const renderDate = dateString => {
    if (dateString === null || dateString === "") {
      return <span />;
    }
    if (Date.parse(dateString) < 0) {
      return <span />;
    }
    const date = new Date(dateString);
    return <span>{moment(date).format("DD-MMM-YYYY, hh:mm A")}</span>;
  };

  const elevatedUser = () => {
    if (
      props.currentUser.isAdmin ||
      props.currentUser.role === "INSTRUCTOR" ||
      process.env.NODE_ENV === "stage" ||
      process.env.NODE_ENV === "dev" ||
      process.env.NODE_ENV === "development"
    ) {
      return true;
    }
    return false;
  };

  const renderActions = studentRun => {
    if (studentRun.id === activeRunId && studentRun.team) {
      return (
        <>
          <Button
            color="primary"
            onClick={
              () => history.push("/backlog")
              // async () =>
              // getTeamData({ variables: { teamId: studentRun.team.id } })
            }
            // {
            //   setClickedRunSeqNo(studentRun.runSequenceNumber);
            //   setActiveRunId(studentRun.teamId);
            //   setSkipQuery(false);
            // }}
          >
            {loading && <Loading />} Continue
          </Button>
          {/* {elevatedUser &&
            studentRun &&
            studentRun.team &&
            studentRun.team.id && (
              <DeleteTeam
                seqNo={studentRun.runSequenceNumber}
                teamId={studentRun.team.id}
                classStudentRunId={studentRun.id}
                setRunDeleted={setRunDeleted}
              />
            )} */}
        </>
      );
    }
    if (studentRun.status === "COMPLETED") {
      return (
        <>
          <Button
            color="primary"
            onClick={() => {
              getTeamData({ variables: { teamId: studentRun.team.id } });
            }}
          >
            {loading && <Loading />} Review
          </Button>
          {/* {elevatedUser &&
            studentRun &&
            studentRun.team &&
            studentRun.team.id && (
              <DeleteTeam
                seqNo={studentRun.runSequenceNumber}
                teamId={studentRun.team.id}
                classStudentRunId={studentRun.id}
                setRunDeleted={setRunDeleted}
              />
            )} */}
        </>
      );
    }
    if (nextActiveRunId > 0) {
      return (
        <InitializeTeam
          userId={id}
          firstName={firstName}
          lastName={lastName}
          updateUserStatus={false}
          classStudentRunId={studentRun.id}
          seqNo={studentRun.runSequenceNumber}
          projectContext={state}
          dispatch={dispatch}
          refreshTheTeam={refreshTheTeam}
        />
      );
    }
    return <span></span>;
  };
  // NOTE: Seems like a weird to navigate, but it's recommended over history.push()
  // https://tylermcginnis.com/react-router-programmatically-navigate
  if (toBacklog === true) {
    return <Redirect to="/backlog" />;
  }
  if (endOfRuns === true) {
    //return <Redirect to="/reports/endOfRun" />;
    return (
      <Redirect
        to={{ pathname: "/reports/endOfRun", state: { review: "true" } }}
      />
    );
  }

  return (
    <>
      <Row>
        <Col lg="10">
          <Card>
            <CardHeader>
              <b>Simulation Runs</b>
            </CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Team ID</th>
                    <th>Classroom</th>
                    <th>Status</th>
                    <th>Started</th>
                    <th>Finished</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {classStudentRuns.map(studentRun => (
                    <tr
                      key={studentRun.id}
                      className={
                        studentRun.team && studentRun.team.status === "ACTIVE"
                          ? "table-active"
                          : "table-inactive"
                      }
                    >
                      <th scope="row">{studentRun.runSequenceNumber}</th>

                      <>
                        <td>{studentRun.team && studentRun.team.id}</td>
                        <td>
                          {studentRun &&
                            studentRun.classroom &&
                            studentRun.classroom.className}
                        </td>
                        <td>{studentRun && studentRun.status}</td>
                        <td>
                          {studentRun.team &&
                          studentRun.team.id &&
                          studentRun.team.startDate ? (renderDate(studentRun.team.startDate)) :
                           (
                            <span />
                          )}
                        </td>
                        <td>
                          {studentRun.team &&
                          studentRun.team.id &&
                          studentRun.team.endDate ? (
                            renderDate(studentRun.team.endDate)
                          ) : (
                            <span />
                          )}
                        </td>
                        <td className="text-right">
                          <div className="text-right">
                            <ButtonGroup>
                              {renderActions(studentRun)}
                            </ButtonGroup>
                          </div>
                        </td>
                      </>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Teams;
