import React from "react";
import MemberLoadImage from "../../../../static/images/helpModal/MemberLoad.png";

const MemberLoad = () => (
  <div>
    <h3>Member Load Report</h3>
    <p>
      The Member Load Report provides the capacity view for team members. This
      report can be used during Sprint planning to determine how much work can
      be allocated to the team members.
    </p>
    <img
      key="MemberLoad1"
      className="HelpModalimage"
      alt="Member Load Report screenshot"
      src={MemberLoadImage}
    />
    <br />
    <br />
    <p>
      The Sprint Capacity column will show the available capacity for a team
      member per Sprint. Default team member capacity is 80 hrs for a two week
      Sprint.
    </p>
    <p>
      The Allocated column represents the work associated with the stories
      assigned to a Team Member for that Sprint and the sum of all the
      interactions that have been scheduled. The interactions can be Scrum
      events, training, and 1-on-1 sessions.
    </p>
    <p>
      The Status column will inform you when the team member allocation has
      exceeded the available capacity. You can allocate more work a team member
      over and above their available Sprint capacity, but recognize that it may
      not get done.
    </p>
  </div>
);

export default MemberLoad;
