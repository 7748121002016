import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { SentryLink, excludeGraphQLFetch } from "apollo-link-sentry";
import { ApolloProvider, ApolloLink } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
// import { getMainDefinition } from "apollo-utilities";
// import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
// import { WebSocketLink } from "apollo-link-ws";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// If taking advantage of included instrumentation (highly recommended)
import { resolvers, typeDefs } from "./resolvers";
import auth from "./Auth";
import Root from "./Root";

  Sentry.init({
     dsn: process.env.REACT_APP_SENTRY_DSN,
     environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    release: "Agileproject",
    ignoreErrors: [
      "ResizeObserver loop limit exceeded"
    ],
    tracesSampleRate: 1.0,
    beforeBreadcrumb: excludeGraphQLFetch
  });
  
// Set up our apollo-client to point at the server we created
// this can be local or a remote endpoint

const cache = new InMemoryCache();
// Set up b2c-server Apollo links

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URI
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = auth.getAccessToken(); //
  if (!token) {
    // try local storage
    token = localStorage.getItem("access_token");
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const sentryLink = new SentryLink({
  setFingerprint: ["on-error"],
  setTransaction: ["on-error"],
  attachBreadcrumbs: {
    includeQuery: ["on-error", "on-success"],
    includeVariables: ["on-error"],
    includeFetchResult: ["on-error"],
    includeError: ["on-error"]
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([sentryLink, authLink.concat(httpLink)]),
  fetchOptions: {
    mode: "no-cors",
    ssrMode: false
  },
  cache,
  typeDefs,
  resolvers,
  defaultOptions: {
    ssrMode: false,
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    }
  },
  onError: ({ graphQLErrors, networkError }) => {
    // render(<Error text={error.message} />, $app)
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    // eslint-disable-next-line no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
});
cache.writeData({
  data: {
    isLoggedIn: !!localStorage.getItem("access-token"),
    currentTeamId: 0,
    currentTeamStatus: null,
    currentSprintId: 0,
    currentSprintNumber: 0,
    currentWeekNumber: 0,
    hasActiveTeam: false,
    runSequenceNumber: 0,
    autoRun: false,
    autoRunRandom: false, // if not random, always 1
    returnMessage: "",
    conditionId: 0,
    returnStatus: "",
    conditionResponseList: [],
    reassignResponseList: [],
    playScriptList: [],
    currentWeekDay: "",
    conditionResponse: 0,
    condMessage: "waiting",
    condTitle: "waiting",
    condAnnouncement: "waiting",
    conditionNumber: 0,
    silent: true
  }
});
client.disableNetworkFetches = true;

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Root />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);