import React, { useContext } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { ProjectContext } from "GlobalState";
import Loading, { Error } from "../Loading";
import ChatMessage from "./ChatMessage";

export const GET_CHAT_MESSAGES = gql`
  query messages($teamId: Int!, $releaseWeek: Int!) {
    messages(teamId: $teamId, releaseWeek: $releaseWeek) {
      id
      messageNumber
      messageFrom
      messageContent
      imageLink
      audioLink
      videoLink
      isRead
    }
  }
`;

export const MARK_BULK_MESSAGES_AS_READ_MUTATION = gql`
  mutation MARK_BULK_MESSAGES_AS_READ_MUTATION(
    $teamId: Int!
    $maxMessageNumber: Int!
  ) {
    markBulkMessagesAsRead(teamId: $teamId, maxMessageNumber: $maxMessageNumber)
  }
`;

const ChatMessagesView = () => {
  const [state] = useContext(ProjectContext);
  const teamId = state.currentTeamId;
  const releaseWeek = state.currentWeekNumber;

  const [markBulkMessagesAsRead] = useMutation(
    MARK_BULK_MESSAGES_AS_READ_MUTATION
  );

  const { loading, error, data } = useQuery(GET_CHAT_MESSAGES, {
    variables: { teamId, releaseWeek }
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  // eslint-disable-next-line prefer-spread
  const maxMessageNumber = Math.max.apply(
    Math,
    data.messages.map(item => item.messageNumber)
  );
  markBulkMessagesAsRead({
    variables: { teamId, maxMessageNumber }
  });

  return data.messages
    .map(message => {
      return <ChatMessage key={message.id} message={message} />;
    })
    .reverse();
};

export default ChatMessagesView;
