/* eslint-disable no-unreachable */
/* eslint-disable no-shadow */
import React from "react";
import styled from "styled-components";
import { Alert } from "reactstrap";
import * as Sentry from "@sentry/browser";

const LoadingLayout = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-right: 0.5rem;
  }
`;

const Loading = ({ text }) => (
  <LoadingLayout>
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />{" "}
    {text}
  </LoadingLayout>
);

const Error = ({ error }) => {
  throw error;
  let networkErrorMessage;
  if (!error || !error.message) return null;
  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors.length > 0
  ) {
    error.networkError.result.errors
      .filter(error => error.message.includes("Network error: Failed to fetch"))
      .map(err => {
        return Sentry.captureEvent({
          message: `Server exeception ${"Connection to the server has been lost. Please refresh the page. If this problem persists, please try log in again later."} ${
            err.message
          }`,
          stacktrace: [err.stacktrace]
        });

        // auth.logout();
        // return <Redirect to="/" />; lori

        // auth.logout();
        // return <Redirect to="/" />; lori
        // return <Alert color="danger">{error.message || "There seems to have been an error. Check the console."}</Alert>;
      });
  }

  if (error.message.includes("NetworkError")) {
    networkErrorMessage =
      "Connection to the server has been lost. Please login in again.";
    Sentry.captureEvent({
      message: `Server execption ${networkErrorMessage} ${error.message}`,
      stacktrace: [error.stacktrace]
    });
    // auth.logout();
    // return <Redirect to="/" />; lori
  }
  // console.error(networkErrorMessage || error.message.replace("GraphQL error: ", ""));
  return (
    <div className="float-left">
      <Alert width="50%" style={{ textAlign: "left" }} color="danger">
        <p>
          <strong>An error has occurred</strong>
        </p>

        <p>
          <strong>Step 1: </strong>Please reload the page and try your operation
          again.
        </p>

        <p>If that doesn&rsquo;t work:</p>

        <p>
          <strong>Step 2:</strong>Send an email with your username and a
          screenshot of this screen to support@simulationpl.com.
        </p>

        <p>
          <strong>Error message: </strong>
          {error.message}
        </p>
      </Alert>
    </div>
  );
};

const Info = ({ info }) => {
  if (info) return <Alert color="info">{info}</Alert>;
  return <span />;
};

Error.defaultProps = {
  error: {}
};

export default Loading;
export { Error, Info };
