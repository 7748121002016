import React from "react";

const SprintPlanning = () => (
  <div>
    <h3>How to perform Sprint planning</h3>
    <p>
      The following are the general steps to plan a Sprint: 
    </p>
    <ol>
      <li>Assign backlog items to a Sprint in the Product Backlog page</li>
      <li>Determine the priority of the backlog items within the Sprint. You can drag the cards to change their priority on the Scrum Board.</li>
      <li>Set up the Sprint events on the Calendar </li>
      <li>Assign team members to backlog items on the Scrum Board</li>
      <li>Use the Member Load report to understand team member capacity and allocation for the Sprint</li>
    </ol>
  </div>
);

export default SprintPlanning;
