import React from "react";

const ContactSupport = () => (
  <div>
    <h3>Contacting Support</h3>
    <p>
      Send all support requests to support@simulationpl.com
    </p>
    <p>
      Technical support is available Monday to Friday from 9:00 a.m. to 5:00 p.m. US Eastern Time. 
    </p>
    <p>When you submit a support request, please provide the following information:</p>
    <ol>
      <li>User Name ( you will find this in the top right header )</li>
      <li>Your Team Id ( you will find this in the top right header ) </li>
      <li>A detailed description of your problem</li>
      <li>A screen print/capture of the problem</li>
      <li>Your telephone number, if you would like us to call you</li>
    </ol>
  </div>
);

export default ContactSupport;
