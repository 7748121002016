import styled from "styled-components";

const SectionHeader = styled.header`
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f3f3f4;
  font-size: 1.25rem;
  padding: 10px;
`;

export default SectionHeader;
