import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import license from "../../../static/pdf/EULA_Saarthi_Simulations_Inc.pdf";

const LicenseDocumentation = ({ toggleModal }) => {
  return (
    <Row>
      <Col>
        <Modal
          className="PdfPopup"
          size="lg"
          isOpen
          fade={false}
          centered
          toggle={toggleModal}
        >
          <ModalHeader>License Agreement</ModalHeader>
          <ModalBody>
            <div className="pdfViewer">
              <iframe
                src={license}
                width="100%"
                height="770px"
                title="Spl"
              ></iframe>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Col>
    </Row>
  );
};

export default LicenseDocumentation;
