import React, { Fragment, useMemo, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import LicenseDocumentation from "routes/Teams/components/LicenseDocumentation";
import { Redirect, useHistory } from "react-router-dom";
import { isNewUser, useAsyncState } from "utilities/utils";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  UPDATE_USER_WELCOMESTATUS_MUTATION,
  INITIALIZE_TEAM_MUTATION
} from "graphql/mutations";
import { ME_QUERY } from "graphql/queries";
import OrientationModal from "components/Overview/OrientationModal";
import HelpModal from "components/help/HelpModal";
import Loading, { Error } from "components/Loading";
import { ProjectContext } from "GlobalState";

import Classroom from "./Classroom";

const CheckList = styled(ListGroup)`
  svg {
    margin-right: 12px;
  }
`;

const Welcome = ({ currentUser }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const { data: meData, refetch: refetchMe } = useQuery(ME_QUERY);
  const [whichModal, setWhichModal] = useAsyncState(false);
  const [toBacklog, setToBacklog] = useAsyncState(false);
  const history = useHistory();

  const checkList = useMemo(() => {
    const list = [
      {
        key: "ACCEPTED_LICENSE",
        text: "Agree to License",
        value: meData.me.acceptedLicense
      },
      {
        key: "VIEWED_ORIENTATION",
        text: "View Orientation Guide",
        value: meData.me.viewedOrientation
      },
      {
        key: "VIEWED_HELP",
        text: "View Help Documentation",
        value: meData.me.viewedHelp
      },
      {
        key: "ASSIGNED",
        text: "Assign yourself to a classroom",
        value: meData.me.assignedToClassroom
      },
      {
        key: "STARTED_PROJECT",
        text: "Start a project",
        value: meData.me.startedFirstProject
      }
    ];
    return list;
  }, [
    meData.me.viewedHelp,
    meData.me.viewedOrientation,
    meData.me.acceptedLicense,
    meData.me.assignedToClassroom,
    meData.me.startedFirstProject
  ]);

  const [
    updateUser,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(UPDATE_USER_WELCOMESTATUS_MUTATION);

  const [initializeTeam, { loading: initializeLoading }] = useMutation(
    INITIALIZE_TEAM_MUTATION
  );

  if (!isNewUser(currentUser)) {
    return <Redirect to="/" />;
  }
  if (mutationError) return <Error error={mutationError} />;

  const toggleModal = modal => {
    setWhichModal(modal);
  };

  const handleUpdate = welcomeStatus => {
    updateUser({
      variables: {
        id: currentUser.id,
        welcomeStatus
      }
    })
      .then(() => {
        refetchMe();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleInitializeTeam = async () => {
    const csrId = currentUser.classStudentRuns.find(s => s.status === "WAITING")
      .id;
    await initializeTeam({
      variables: {
        userId: currentUser.id,
        classStudentRunId: csrId,
        updateUser: "true"
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ME_QUERY
        }
      ],
      onCompleted: async data => {
        const payload = {
          currentTeamId: data.team.id,
          currentTeamStatus: data.team.currentStatus,
          currentSprintId: data.team.currentSprintId,
          currentSprintNumber: data.team.currentSprintNumber,
          currentWeekNumber: data.team.currentWeekNumber,
          currentWeekDay: data.team.currentWeekDay,
          currentRunDay: data.team.currentRunDay,
          runSequenceNumber: currentUser.classStudentRuns[0].runSequenceNumber,
          hasActiveTeam: true,
          studentId: currentUser.id,
          studentFirst: currentUser.firstName,
          studentLast: currentUser.lastName,
          sprints: data.team.sprints
        };

        Promise.all([
          dispatch({
            type: "UPDATE_LOCAL_TEAM",
            payload
          }),
          setToBacklog(true)
        ]);
      }
    });
  };
  if (!initializeLoading && !state.loading && toBacklog) {
    history.push("/backlog");
  }
  return (
    <Card>
      <CardHeader>
        Welcome to Agile Simulation
        <div className="pull-right">{mutationLoading && <Loading />}</div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <Fragment>
                  {currentUser.acceptedLicense && (
                    <i
                      className="fa fa-check"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  License Agreement
                </Fragment>
              </CardHeader>
              <CardBody>
                <h4>Simulation Powered Learning</h4>
                <p>
                  SPL Corporation reserves all rights to both the content
                  contained within the simulation and the content on this
                  website. The SPL simulation is for personal use or for use
                  within a SPL-licenced university or college-class only.
                </p>
                <p>
                  No part of this simulation may be reproduced, redistributed or
                  sold for profit of any kind. Licences may not be transferred
                  or sold.
                </p>
                <p>
                  <b>
                    <i> Click to review / accept the terms and conditions </i>
                  </b>
                </p>

                <Button
                  color="primary"
                  onClick={() => {
                    toggleModal("licenseModal");
                    handleUpdate("ACCEPTED_LICENSE");
                  }}
                >
                  {currentUser.acceptedLicense === true ? (
                    <span>Review the terms & conditions</span>
                  ) : (
                    <span>I agree to the License Terms</span>
                  )}
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card>
              <CardHeader>
                <Fragment>
                  {currentUser.viewedOrientation && (
                    <i
                      className="fa fa-check"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Getting acquainted
                </Fragment>
              </CardHeader>
              <CardBody>
                <p>
                  The orientation guide will introduce you to the characters,
                  The Agile Board and the references that will be helpful to
                  you, as you get ready to plan and run your project.
                </p>
                <Button
                  color="primary"
                  onClick={() => {
                    toggleModal("orientationModal");
                    handleUpdate("VIEWED_ORIENTATION");
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "users"]} /> View Orientation
                  Guide
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <CardHeader>
                <Fragment>
                  {currentUser.viewedHelp && (
                    <i
                      className="fa fa-check"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Getting Help
                </Fragment>
              </CardHeader>
              <CardBody>
                <p>
                  If at any point you need help or are struggling to use the
                  Agile Board, the help documentation should cover most of your
                  questions. You can access it from anywhere via the help icon
                  in the top right corner of your Welcome.
                </p>
                <p>
                  Before getting started, it may be a good idea to review it
                  now.
                </p>
                <Button
                  color="primary"
                  onClick={() => {
                    toggleModal("helpModal");
                    handleUpdate("VIEWED_HELP");
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "life-ring"]} /> View Help
                  Documentation
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <CardHeader>Checklist</CardHeader>
              <CheckList>
                {checkList &&
                  checkList.map(item => (
                    <ListGroupItem
                      id={item.key}
                      key={item.key}
                      color={item.value ? "success" : ""}
                    >
                      <FontAwesomeIcon
                        icon={["far", item.value ? "check-square" : "square"]}
                      />
                      {item.text}
                    </ListGroupItem>
                  ))}
              </CheckList>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="7">
            <Card>
              <CardHeader>
                <Fragment>
                  {currentUser.assignedToClassroom && (
                    <i
                      className="fa fa-check"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Assign yourself to a classroom
                </Fragment>
              </CardHeader>
              <CardBody>
                <Classroom
                  currentUser={currentUser}
                  updateUser={handleUpdate}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="5">
            <Card>
              <CardHeader>
                <Fragment>
                  {currentUser.startedFirstProject && (
                    <i
                      className="fa fa-check"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Start your first team project
                </Fragment>
              </CardHeader>
              <CardBody>
                <Button
                  color="primary"
                  disabled={
                    !meData.me.viewedHelp ||
                    !meData.me.viewedOrientation ||
                    !meData.me.acceptedLicense ||
                    !meData.me.assignedToClassroom
                  }
                  onClick={() => handleInitializeTeam()}
                >
                  {initializeLoading && <Loading />} Start First Team
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
      {whichModal === "orientationModal" && (
        <OrientationModal toggleModal={toggleModal} />
      )}
      {whichModal === "helpModal" && <HelpModal toggleModal={toggleModal} />}
      {whichModal === "licenseModal" && (
        <LicenseDocumentation toggleModal={toggleModal} />
      )}
    </Card>
  );
};

export default Welcome;
