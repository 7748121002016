import React from "react";
/**
 * 0 = worker
 * 1 = reward type
 */
export const IndvReward = props => (
  <div className="content">
    <h5>
      <span role="img" aria-label="party">
        🎉
      </span>
      {props.properties[0].itemValue}&nbsp;
      <small>was rewarded with: {props.properties[1].itemValue}</small>
    </h5>
  </div>
);
