import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { ProjectContext } from "GlobalState";
import { ME_QUERY } from "graphql/queries";
import AppRouter from "components/AppRouter";
import { Error } from "components/Loading";

const UserDataInjector = props => {
  const [, dispatch] = useContext(ProjectContext);

  const { auth } = props;
  const { data, error } = useQuery(ME_QUERY);
  useEffect(() => {
    async function populateState() {
      if (data?.me?.id) {
        let runSequenceNumber = 1;
        if (
          data.me.classStudentRuns &&
          data.me.classStudentRuns.length > 0 &&
          data.me.currentTeam &&
          data.me.currentTeam.id
        ) {
          // eslint-disable-next-line prefer-destructuring
          runSequenceNumber = data.me.classStudentRuns.find(
            c => c.team !== null && c.team.id === data.me.currentTeam.id
          ).runSequenceNumber;
        }
        let payload = {};
        if (data.me.currentTeam == null) {
          payload = {
            hasActiveTeam: false,
            studentId: data.me.id,
            studentFirst: data.me.firstName,
            studentLast: data.me.lastName
          };
          await dispatch({
            type: "SET_STUDENT",
            payload
          });
        } else {
          payload = {
            currentTeamId: data.me.currentTeam.id,
            currentSprintId: data.me.currentTeam.currentSprintId,
            currentSprintNumber: data.me.currentTeam.currentSprintNumber,
            currentWeekNumber: data.me.currentTeam.currentWeekNumber,
            currentWeekDay: data.me.currentTeam.currentWeekDay,
            currentRunDay: data.me.currentTeam.currentRunDay,
            currentTeamStatus: data.me.currentTeam.currentStatus,
            runSequenceNumber,
            hasActiveTeam: true,
            studentId: data.me.id,
            studentFirst: data.me.firstName,
            studentLast: data.me.lastName,
            sprints: data.me.currentTeam.sprints
          };
          await dispatch({
            type: "UPDATE_LOCAL_TEAM",
            payload
          });
        }
      }
    }
    if (!error) {
      populateState();
    }
  }, [data, error, dispatch]);
  if (error) return <Error error={error} />;
  return <AppRouter auth={auth} path={props.location.pathname} />;
};

export default UserDataInjector;
