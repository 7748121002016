/* eslint-disable no-shadow */
import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import gql from "graphql-tag";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { store } from "react-notifications-component";
import Loading, { Error } from "../../../../components/Loading";
import WeekDaySelector from "../../../../components/WeekDaySelector";
import {
  WEEKDAY_INTERACTIONS_QUERY,
  MEMBER_LOAD_QUERY
} from "../../../../graphql/queries";

const UPSERT_MEETING_MUTATION = gql`
  mutation UPSERT_MEETING_MUTATION(
    $id: Int
    $teamId: Int!
    $sprintId: Int!
    $weekNumber: Int!
    $weekDay: Weekday!
    $topic: MeetingTopic!
    $recurring: Boolean!
  ) {
    upsertMeeting(
      id: $id
      teamId: $teamId
      sprintId: $sprintId
      weekNumber: $weekNumber
      weekDay: $weekDay
      topic: $topic
      recurring: $recurring
    ) {
      id
    }
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 200px 200px;
  grid-gap: 10px;

  .form-check-input {
    margin-left: 0;
    margin-right: 8px;
    margin-top: 6px;
  }

  > .last {
    grid-column: 1 / -1;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  display: inline-grid;
`;

const StyledDiv = styled.div`
  padding-left: 18px;
`;

const MeetingForm = ({
  toggleModal,
  weekDay,
  type,
  teamId,
  sprintId,
  currentWeekNumber,
  weekNumber,
  data
}) => {
  const [form, setValues] = useState({
    id: data ? data.id : null,
    teamId,
    sprintId,
    weekNumber,
    weekDay,
    type,
    currentWeekNumber,
    topic: data ? data.meetingDetail.topic : null,
    recurring: data ? data.meetingDetail.recurring : false
  });

  const nextWeekToRefresh =
    weekNumber % 2 === 0 ? weekNumber - 1 : weekNumber + 1;

  const queriesToRefetch = [
    {
      query: WEEKDAY_INTERACTIONS_QUERY,
      variables: { teamId, sprintId, weekNumber }
    },
    {
      query: WEEKDAY_INTERACTIONS_QUERY,
      variables: { teamId, sprintId, weekNumber: nextWeekToRefresh }
    },
    {
      query: MEMBER_LOAD_QUERY,
      variables: { teamId, sprintId }
    }
  ];

  const [
    upsertMeeting,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(UPSERT_MEETING_MUTATION, {
    refetchQueries: queriesToRefetch
  });

  if (mutationError) return <Error error={mutationError} />;

  const onChangeOption = (value, action) => {
    setValues({
      ...form,
      [action.name]: value.value
    });
  };

  const onChangeCheckbox = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.checked
    });
  };

  const onSubmitHandler = e => {
    e.preventDefault();

    if (form.weekDay === null) {
      showNotification("Please select a day of the week");
      return;
    }

    if (form.topic === null) {
      showNotification("Please select a topic");
      return;
    }

    upsertMeeting({ variables: { ...form } }).then(response => {
      if (response.data.upsertMeeting !== null) {
        // toggleModal();
      } else {
        showNotification(
          "Cannot schedule more than 8 hours of team member capacity per day or create duplicate calendar entries."
        );
      }
    });
  };

  const showNotification = text => {
    store.addNotification({
      message: text,
      container: "top-right",
      type: "warning",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "bounceOut"],
      dismiss: { duration: 5000 },
      width: 294,
      content: <div className="notification-sim">{text}</div>
    });
  };

  const renderTopicSelector = () => {
    // let dailyStandUpOption = "";
    const topics = [
      { value: "SPRINTPLANNING", label: "Sprint Planning" },
      { value: "BACKLOGREFINEMENT", label: "Backlog Refinement" },
      { value: "SPRINTDEMO", label: "Sprint Review" },
      { value: "RETROSPECTIVE", label: "Sprint Retrospective" }
    ];

    // this option is only when creating a meeting, not available in update mode
    if (data?.id === undefined) {
      // dailyStandUpOption = <option value="DAILYSTANDUP">Daily Stand Up</option>
      topics.unshift({ value: "DAILYSTANDUP", label: "Daily Stand Up" });
    }

    return (
      <FormGroup>
        <Label id="label_topic">Topic</Label>
        <Select
          name="topic"
          aria-labelledby="label_topic"
          options={topics}
          onChange={onChangeOption}
          defaultValue={topics.find(d => d.value === form.topic)}
        ></Select>
      </FormGroup>
    );
  };

  const renderDurationLabel = () => {
    let label = "";

    if (form.topic !== null) {
      label = (
        <Label for="duration">
          Duration : {form.topic === "DAILYSTANDUP" ? "15 Minutes" : form.topic === "SPRINTPLANNING" ? "4 Hours" : form.topic === "SPRINTDEMO" ? "2 Hours" :  form.topic === "RETROSPECTIVE" ? "1.5 Hours" :  form.topic === "BACKLOGREFINEMENT" ? "1 Hours" : "" }
        </Label>
      );
    }

    return label;
  };

  const renderRecurringCheckbox = () => {
    let recurringCheckbox = "";

    if (form.topic === "DAILYSTANDUP") {
      recurringCheckbox = (
        <StyledFormGroup>
          <Label check for="recurringCheckbox"></Label>
          <Input
            name="recurring"
            onChange={onChangeCheckbox}
            id="recurringCheckbox"
            type="checkbox"
            defaultChecked={form.recurring}
          />
          <StyledDiv>Recurring</StyledDiv>
        </StyledFormGroup>
      );
    }

    return recurringCheckbox;
  };

  return (
    <StyledForm id="meeting-form" onSubmit={onSubmitHandler}>
      <WeekDaySelector
        controlName="weekDay"
        defaultValue={weekDay}
        onChange={onChangeOption}
      />

      {renderTopicSelector()}
      {renderDurationLabel()}
      {renderRecurringCheckbox()}

      <Button
        disabled={
          mutationLoading ||
          (data && Object.values(form).some(value => value === null))
        }
        className="last"
        color="primary"
        type="submit"
      >
        {mutationLoading ? (
          <Loading text={data ? "Updating" : "Saving"} />
        ) : data ? (
          "Update"
        ) : (
          "Save"
        )}
      </Button>
    </StyledForm>
  );
};

export default MeetingForm;
