import React from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import styled from "styled-components";
import people from "../static/images/people";

const StyledImg = styled.img`
  width: 45px;
  height: 50px;
  padding: 2px;
  border: 1px;
`;

const StyledDiv = styled.div`
  display: flex;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e4e5e6;
`;

const StyledP = styled.div`
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default class Notification extends React.Component {
  componentDidMount() {
    if (this.props.unReadMessages && this.props.unReadMessages.length > 0) {
      this.props.unReadMessages.forEach(message => {
        store.addNotification({
          content: <MyNotification message={message} />,
          container: "bottom-right",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          dismiss: {
            duration: 5000
          },
          width: 294
        });
      });
    }
  }

  render() {
    return "";
  }
}

function MyNotification({ message }) {
  return (
    <StyledDiv
      style={{
        display: "flex",
        backgroundColor: "#e4e5e6",
        borderRadius: 5
      }}
    >
      <StyledImg
        alt={message.messageFrom}
        src={
          people[`${message.messageFrom.replace(/\s/g, "").replace(/\./g, "")}`]
        }
      />
      <div>
        <h6>{message.messageFrom}</h6>
        <StyledP>{message.message}</StyledP>
      </div>
    </StyledDiv>
  );
}
