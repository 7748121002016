/* eslint-disable import/prefer-default-export */
import React from "react";
import ContentDiv from "../../../../components/styled/Reports/ContentDiv";

export const StoryAdded = props => {
  if (!props || !props.properties || props.properties.length < 1) {
    return <ContentDiv />;
  }
  return (
    <ContentDiv style={{ backgroundColor: "#ffe6e6" }}>
      User Story:&nbsp;&nbsp;
      <strong>
        {props.properties.find(i => i.itemKey === "storyName").itemValue}
      </strong>
      &nbsp; has been added to the sprint from the backlog.
    </ContentDiv>
  );
};
