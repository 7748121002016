import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import moment from "moment";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import EnumSelector from "../../../components/EnumSelector";
import { formatEnumValue } from "../../../utilities/utils";
import Loading from "../../../components/Loading";
import { INSTRUCTOR_CLASSES_QUERY } from "../../../graphql/queries";

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: end;

  .form-group {
    margin-bottom: 0;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-wrapper {
    /* display: inline-block; */
    padding: 0;
    border: 0;
  }
`;

const CREATE_CLASSROOM_MUTATION = gql`
  mutation CREATE_CLASSROOM_MUTATION(
    $className: String
    $startDate: DateTime!
    $endDate: DateTime!
    $primaryInstructorId: Int!
    $productNumber: Int!
    $status: GeneralStatus!
  ) {
    createClassroom(
      className: $className
      startDate: $startDate
      endDate: $endDate
      primaryInstructorId: $primaryInstructorId
      productNumber: $productNumber
      status: $status
    ) {
      id
    }
  }
`;

export const UPDATE_CLASSROOM = gql`
  mutation UPDATE_CLASSROOM(
    $id: Int!
    $className: String
    $startDate: DateTime!
    $endDate: DateTime!
    $status: GeneralStatus!
  ) {
    updateClassroom(
      id: $id
      className: $className
      startDate: $startDate
      endDate: $endDate
      status: $status
    ) {
      id
    }
  }
`;

const ClassroomForm = ({ toggle, primaryInstructorId, data }) => {
  const initialState = {
    id: data ? data.id : null,
    productNumber: 45,
    className: data ? data?.className : "",
    status: data ? data.status : "ACTIVE",
    primaryInstructorId,
    startDate: data ? data.startDate : "",
    endDate: data ? data.endDate : "",
    disableDatepicker: !data
  };

  const [form, setValues] = useState(initialState);
  const [disable, enable] = useState(false);

  const queriesToRefetch = [
    {
      query: INSTRUCTOR_CLASSES_QUERY,
      variables: { instructorId: primaryInstructorId }
    }
  ];

  const [createClassroom, { loading: createLoading }] = useMutation(
    CREATE_CLASSROOM_MUTATION,
    {
      refetchQueries: queriesToRefetch
    }
  );

  const [
    updateClassroom,
    { loading: updateLoading }
  ] = useMutation(UPDATE_CLASSROOM, { refetchQueries: queriesToRefetch });

  const setInitialValueForStartDate = () => {
    if (form.startDate !== "") {
      return moment(form.startDate).format("YYYY-MM-DD");
    }

    return "Select a Date";
  };

  const setInitialValueForEndDate = () => {
    if (form.endDate !== "") {
      return moment(form.endDate).format("YYYY-MM-DD");
    }
    if (form.startDate !== "") {
      return "Select a Date";
    }

    return "Select the Start Date";
  };

  const updateValue = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
    enable(false);
  };

  const onChangeStartDate = date => {
    setValues({
      ...form,
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date)
        .add(1, "days")
        .format("YYYY-MM-DD"),
      disableDatepicker: false
    });
  };

  const onChangeEndDate = date => {
    setValues({
      ...form,
      endDate: moment(date).format("YYYY-MM-DD")
    });
    enable(false);
  };

  const onChangeClassRunStatus = (value, action) => {
    setValues({
      ...form,
      [action.name]: value.value
    });
    enable(false);
  };

  const onSubmitHandler = e => {
    e.preventDefault();

    if (form.startDate === "" || form.endDate === "") {
      enable(!disable);
      return "";
    }

    // when we are going to create a new classroom
    if (data === null) {
      createClassroom({ variables: { ...form } })
        .then(() => {
          toggle();
        })
        .then(() => {
          setValues({ ...form, startDate: "", endDate: "" });
        });
    }

    // when we are going to update an existing classroom
    else {
      updateClassroom({ variables: { ...form } })
        .then(() => {
          toggle();
        })
        .then(() => {
          setValues({ ...form, startDate: "", endDate: "" });
        });
    }
  };

  const renderButtonText = () => {
    if (data === null) {
      if (createLoading) {
        return <Loading text="Creating" />;
      }

      return "Create";
    }

    if (updateLoading) {
      return <Loading text="Updating" />;
    }

    return "Update";
  };

  return (
    <StyledForm onSubmit={onSubmitHandler}>
      <p>
        Product Type: Sim Agile <br />
        Runs per student: 3
      </p>
      <FormGroup>
        <Label for="classInput">Class Name</Label>
        <Input
          type="text"
          required
          name="className"
          id="classInput"
          onChange={updateValue}
          value={form.className}
        />
      </FormGroup>

      <FormGroup>
        <Label for="startDate">Start Date</Label>
        <div className="customDatePickerWidth">
          <StyledDatePicker
            name="startDate"
            onChange={onChangeStartDate}
            value={setInitialValueForStartDate()}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label for="endDate">End Date</Label>
        <div className="customDatePickerWidth">
          <StyledDatePicker
            name="endDate"
            disabled={form.disableDatepicker}
            onChange={onChangeEndDate}
            minDate={parseISO(form.endDate)}
            placeholderText="Select a day"
            value={setInitialValueForEndDate()}
          />
        </div>
      </FormGroup>
      <EnumSelector
        enumType="GeneralStatus"
        title="Status"
        controlName="status"
        formatProvider={formatEnumValue}
        filterValues={["ACTIVE", "ARCHIVED", "REGISTRATION"]}
        defaultValue={form.status}
        onChange={onChangeClassRunStatus}
      />
      <Button
        disabled={createLoading || updateLoading || disable}
        className="last"
        color="primary"
        type="submit"
      >
        {renderButtonText()}
      </Button>
    </StyledForm>
  );
};

export default ClassroomForm;
