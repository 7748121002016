import React, { Component } from "react";
import { Card, CardBody, Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import CardHeader from "../../../components/styled/CardHeader";
import HistoryPanel from "./HistoryPanel";
import BurndownChart from "../../Reports/components/BurndownItems/BurndownChart";
import VelocityTrendChart from "../../Reports/components/VelocityTrendChart";
import CumulativeFlowDiagram from "../../Reports/components/CumulativeFlowDiagram";
import EndOfRunSummary from "../../Reports/components/EndRunSummary";
// import CapacityPlanningDetails from "../../Reports/components/CapacityPlanning";

const Bold = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;
class ReportContentPanel extends Component {
  state = {
    titleEl: document.title
  };

  afterPrint() {
    document.title = this.state.titleEl;
  }

  // eslint-disable-next-line class-methods-use-this
  beforePrint(newTitle) {
    document.title = newTitle;
  }

  render() {
    const {
      history,
      activeTab,
      selectedWeek,
      teamId,
      sprintNumber,
      sprintId
    } = this.props;
    if (!activeTab) return <span />;
    if (activeTab === "history") {
      this.fileName = `History-Week${selectedWeek}`;
      return <HistoryPanel history={history} selectedWeek={selectedWeek} />;
    }
    let title = "Burndown Chart";
    let chart = <BurndownChart teamId={teamId} sprintId={sprintId} />;
    if (activeTab === "burndown") {
      title = "Burndown Chart";
      this.fileName = `BurndownChart-Sprint${sprintNumber}`;
      chart = <BurndownChart teamId={teamId} sprintId={sprintId} />;
    }
    if (activeTab === "velocity") {
      title = "Velocity Trend";
      this.fileName = "VelocityTrend";
      chart = <VelocityTrendChart teamId={teamId} />;
    }
    // if (activeTab === "capacity") {
    //   title = "Capacity Planning";
    //   chart = <CapacityPlanningDetails teamId={teamId}/>;
    // }
    if (activeTab === "CFD") {
      title = "Cumulative Flow Diagram";
      this.fileName = "CummulativeFlowDiagram";
      chart = <CumulativeFlowDiagram teamId={teamId} />;
    }
    if (activeTab === "EOR") {
      title = "End of Run report";
      this.fileName = "EndOfRunSummary";
      chart = (
        <EndOfRunSummary teamId={teamId} currentUser={this.props.currentUser} />
      );
    }

    return (
      <Card>
        <CardHeader>
          <Bold>{title}</Bold>

          <ReactToPrint
            trigger={() => (
              <Button color="secondary">
                <i className="far fa-file-pdf" /> Print
              </Button>
            )}
            content={() => this.ChartRef}
            onBeforePrint={() => this.beforePrint(this.fileName)}
            onAfterPrint={() => this.afterPrint()}
          />
        </CardHeader>

        <CardBody>
          <PrintDiv
            id={`h${sprintNumber.toString()}`}
            ref={el => {
              this.ChartRef = el;
            }}
          >
            {chart}
          </PrintDiv>
        </CardBody>
      </Card>
    );
  }
}

export default ReportContentPanel;
