/* eslint-disable import/no-cycle */
import React, { Component, Fragment } from "react";
import {
  Row,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import { Query } from "@apollo/client/react/components";
import Loading, { Error } from "../../../components/Loading";
import { TEAM_QUERY, STORIES_QUERY } from "../../../graphql/queries";
import StoryModal from "../../ScrumBoard/components/StoryModal";

import ActiveStoriesTable from "./ActiveStoriesTable";
import CompletedStoriesTable from "./CompletedStoriesTable";

const options = [
  { value: 0, label: "All" },
  { value: 0.5, label: "Not Selected" },
  { value: 1, label: "Sprint 1" },
  { value: 2, label: "Sprint 2" },
  { value: 3, label: "Sprint 3" },
  { value: 4, label: "Sprint 4" }
];

class Backlog extends Component {
  state = {
    value: 0,
    activeTab: "all",
    storyModalIsOpen: false,
    currentStory: {}
  };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen
    }));
  };

  setStories = (stories, value) => {
    if (value === 0) {
      return stories;
    }
    if (value === 0.5) {
      const backlog = stories.filter(
        s => !s.currentSprint || s.currentSprint === null
      );
      return backlog;
    }
    const story = stories.filter(
      s =>
        s.currentSprint &&
        s.currentSprint !== null &&
        s.currentSprint.sprintNumber === value
    );
    return story;
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  toggleModal = story => {
    if (story && story !== null) {
      this.setState({ currentStory: story });
    }
    this.setState(prevState => {
      return {
        ...prevState,
        storyModalIsOpen: !prevState.storyModalIsOpen
      };
    });
  };

  render() {
    const { teamId, userId, projectContext } = this.props;
    return (
      <Query
        // fetchPolicy="network-only"
        query={TEAM_QUERY}
        variables={{
          teamId: projectContext.currentTeamId
        }}
        skip={!projectContext.currentTeamId}
      >
        {({ data, loading, error }) => {
          if (loading) return <Loading />;
          if (error) return <Error error={error} />;
          if (data && data.team) {
            return (
              <Query
                // fetchPolicy="network-only"
                query={STORIES_QUERY}
                variables={{ teamId: projectContext.currentTeamId }}
              >
                {({
                  data: storyData,
                  loading: storyLoading,
                  error: storyError,
                  refetch: storyRefetch
                }) => {
                  if (storyLoading) return <Loading />;
                  if (storyError) return <Error error={storyError} />;
                  return (
                    <Fragment>
                      <CardBody>
                        <Row>
                          <Col>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "all"
                                  })}
                                  onClick={() => {
                                    this.toggleTab("all");
                                  }}
                                >
                                  Backlog Items
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "completed"
                                  })}
                                  onClick={() => {
                                    this.toggleTab("completed");
                                  }}
                                >
                                  Accepted Stories
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                              <TabPane tabId="all">
                                <Row>
                                  <Col>
                                    <Card>
                                      <CardHeader>
                                        <Col md="3">
                                          <Select
                                            defaultValue={options[0]}
                                            options={options}
                                            onChange={selected => {
                                              this.setState({
                                                value: selected.value
                                              });
                                            }}
                                          />
                                        </Col>
                                      </CardHeader>
                                      <CardBody>
                                        <Col>
                                          <ActiveStoriesTable
                                            teamId={this.props.teamId}
                                            userId={userId}
                                            id={projectContext.currentTeamId}
                                            stories={this.setStories(
                                              storyData.stories.filter(
                                                s => s.status !== "COMPLETE"
                                              ),
                                              this.state.value
                                            )}
                                            toggle={this.toggleModal}
                                            sprintId={
                                              projectContext.currentSprintId
                                            }
                                            sprints={data.team.sprints}
                                            sprintNumber={
                                              data.team.currentSprintNumber
                                            }
                                            currentSprintId={
                                              data.team.currentSprintId
                                            }
                                            refetchStories={storyRefetch}
                                          />
                                        </Col>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="completed">
                                <Row>
                                  <Col>
                                    <CompletedStoriesTable
                                      teamId={teamId}
                                      userId={userId}
                                      id={data.team.id}
                                      toggle={this.toggleModal}
                                      stories={storyData.stories.filter(
                                        s => s.status === "COMPLETE"
                                      )}
                                      sprints={data.team.sprints}
                                      sprintNumber={
                                        data.team.currentSprintNumber
                                      }
                                      currentSprintId={
                                        data.team.currentSprintId
                                      }
                                    />
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                      </CardBody>
                      {this.state.storyModalIsOpen && (
                        <StoryModal
                          currentSprintId={data.team.currentSprintId}
                          currentUser={this.props.currentUser}
                          isOpen={this.state.storyModalIsOpen}
                          toggleModal={this.toggleModal}
                          currentStory={this.state.currentStory}
                          okToAssignWorkers={false}
                          okToUnassignWorkers={false}
                          refetch={storyRefetch}
                          skills={data.team.skills}
                        />
                      )}
                    </Fragment>
                  );
                }}
              </Query>
            );
          }
          return <span>no data yet</span>;
        }}
      </Query>
    );
  }
}

export default Backlog;
