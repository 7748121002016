import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import BootstrapTable from "react-bootstrap-table-next";
import Loading, { Error } from "../../../components/Loading";
import { MEMBER_LOAD_QUERY } from "../../../graphql/queries";
import { formatEnumValue } from "../../../utilities/utils";
import people from "../../../static/images/people";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const MemberLoadTable = ({ teamId, sprintId }) => {
  const { loading, error, data } = useQuery(MEMBER_LOAD_QUERY, {
    variables: { teamId, sprintId },
    fetchPolicy: "network-only"
  });

  if (error) return <Error error={error} />;
  if (loading) return <Loading />;

  const imageFormatter = (cellContent, row) => {
    const { name } = row;

    return (
      <img
        src={people[`${name.replace(/\s/g, "").replace(/\./g, "")}`]}
        alt={name}
        width="30px"
        id={`${name.replace(/\s/g, "").replace(/\./g, "")}-story-profile`}
      />
    );
  };

  const numberFormatter = (cellContent, row) => {
    const formattedNumber =
      Math.round((cellContent + Number.EPSILON) * 10) / 10;
    return formattedNumber;
  };

  const enumFormatter = cell => {
    return formatEnumValue(cell);
  };

  const availableHoursStyleFormat = cell => {
    return { background: parseFloat(cell) > 0 ? "#EEFFEE" : "#FFEEEE" };
  };

  const columns = [
    {
      dataField: "image",
      isDummyField: true,
      align: "right",
      text: "",
      headerStyle: { width: "10%" },
      formatter: imageFormatter,
      footer: ""
    },
    {
      dataField: "name",
      text: "Team Member",
      headerAlign: "center",
      sort: true,
      headerStyle: { width: "20%" },
      footer: "Total",
      footerAlign: "right"
    },
    {
      dataField: "sprintCapacity",
      text: "",
      headerFormatter: () => (
        <span>
          Sprint Capacity
          <br />
          (Hrs)
        </span>
      ),
      headerAlign: "center",
      align: "center",
      sort: false,
      headerStyle: { width: "15%" },
      footerAlign: "center",
      footer: () => numberFormatter(data.memberLoadReport.totalSprintCapacity)
    },
    {
      dataField: "allocatedCapacity",
      text: "",
      headerFormatter: () => (
        <span>
          Allocated
          <br />
          (Hrs)
        </span>
      ),
      formatter: numberFormatter,
      headerAlign: "center",
      align: "center",
      sort: false,
      headerStyle: { width: "15%" },
      footerAlign: "center",
      footer: () => numberFormatter(data.memberLoadReport.totalAllocated)
    },
    {
      dataField: "availableCapacity",
      text: "",
      headerFormatter: () => (
        <span>
          Available
          <br />
          (Hrs)
        </span>
      ),
      formatter: numberFormatter,
      headerAlign: "center",
      align: "center",
      sort: false,
      headerStyle: { width: "15%" },
      style: cell => availableHoursStyleFormat(cell),
      footerAlign: "center",
      footer: () => numberFormatter(data.memberLoadReport.totalAvailable)
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      align: "center",
      sort: false,
      formatter: enumFormatter,
      headerStyle: { width: "25%" }
    }
  ];
  if (
    !data ||
    !data.memberLoadReport ||
    !data.memberLoadReport.memberCapacity
  ) {
    return <span>No data yet</span>;
  }
  return (
    <Fragment>
      <BootstrapTable
        keyField="id"
        data={data.memberLoadReport.memberCapacity}
        columns={columns}
        condensed
        striped
        bootstrap4
      />
    </Fragment>
  );
};

export default MemberLoadTable;
