import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Query } from "@apollo/client/react/components";
import { TEAM_QUERY } from "../../../graphql/queries";
import Worker from "../../../components/Worker/Worker";
import WorkerModal from "../../../components/Worker/WorkerModal";
import Loading, { Error } from "../../../components/Loading";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
`;

const WorkerModalLink = styled.div`
  &:hover > div {
    background-color: #fafafa;
  }
`;

class TeamList extends Component {
  state = {
    modal: {
      isOpen: false,
      workerId: ""
    }
  };

  toggleModal = workerId => {
    this.setState(prevState => ({
      modal: {
        workerId: workerId || prevState.modal.workerId,
        isOpen: !prevState.modal.isOpen
      }
    }));
  };
  // filteredWorkers = teamWorkers => teamWorkers.filter(w => w.currentStory === null);

  render() {
    const { teamId } = this.props;
    return (
      <Query
        query={TEAM_QUERY}
        variables={{ teamId }}
        skip={!teamId || teamId === 0}
      >
        {({ data, loading, error }) => {
          if (loading) return <Loading />;
          if (error) return <Error error={error} />;
          // if (!data.team || data.team === null) {
          //   return <Redirect to="/teams" />;
          // }
          return (
            <Fragment>
              <Grid>
                {data.team.teamWorkers.map(worker => (
                  <WorkerModalLink
                    key={worker.id}
                    onClick={() => this.toggleModal(worker.id)}
                  >
                    <Worker workerData={worker} key={worker.id} />
                  </WorkerModalLink>
                ))}
              </Grid>
              {this.state.modal.isOpen && (
                <WorkerModal
                  workerId={this.state.modal.workerId}
                  modal={this.state.modal}
                  toggleModal={this.toggleModal}
                />
              )}
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default TeamList;
