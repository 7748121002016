import React from "react";
import ProductBacklog from "../../../static/images/helpModal/Backlog.png";
import BacklogStory from "../../../static/images/helpModal/BacklogStory.png";

const Backlog = () => (
  <div>
    <h3>Product Backlog</h3>
    <p>
      Stories, also called backlog items, are the work items for the product team. 
      A backlog item contains the description of the functionality desired in the end product. 
      Each backlog item represents a piece of functionality that delivers some business value. 
    </p>
    <img key="Backlog1" className="HelpModalimage" alt="Backlog screenshot" src={ProductBacklog} />
    <br/><br/>
    <p>
      The backlog items are ranked a default priority. Each story has a description, assigned story points, 
      a flag to indicate if this story is required for the minimal viable product. The product owner will 
      only accept stories that meet the agreed upon acceptance criteria. 
    </p>
    <p>You can assign backlog items to a Sprint on the Product Backlog page.</p>
    <p>
      Clicking on the backlog item name will open up a backlog detail window. Here you will find a story 
      description and acceptance criteria for the backlog item. Each backlog item also has story points 
      assigned to it.
    </p>
    <img key="Backlog2" className="HelpModalimage" alt="Backlog screenshot" src={BacklogStory} />
    <br/><br/>
    <p>
      You have the ability to filter the backlog and narrow the data that you want to see. 
      The Sprint filter allows you to filter the backlog by Sprint.
    </p>
    <p>
      The Accepted Stories tab displays the completed backlog items or stories that have been 
      accepted by the Product Owner. 
    </p>
  </div>
);

export default Backlog;
