import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Badge } from "reactstrap";
import styled from "styled-components";
import { getStoryStatusDisplayName } from "../../../utilities/utils";
import { UncontrolledTooltip } from "../../../components/styled/UncontrolledTooltip";
// import Loading, { Error } from "../../../components/Loading";
import AssignedWorkerContainer from "./AssignedWorkerContainer";
import AvailableWorkerContainer from "./AvailableWorkerContainer";

const StyledNarrowTd = styled.td`
   {
    width: 20%;
    text-align: right;
    padding-right: 10px;
    font-weight: bold;
    border-bottom: 1px solid LightGrey;
  }
`;

const StyledWideTd = styled.td`
   {
    width: 77%;
    text-align: left;
    border-bottom: 1px solid LightGrey;
  }
`;

const StyledModal = styled(Modal)`
  .row {
    margin-bottom: 15px;
  }
`;

const Stats = styled(Row)`
  .col > span {
    margin-right: 10px;
  }

  .badge {
    font-size: 1rem;
    margin-right: 0;
  }
`;

const ImageHoverModal = styled.div`
 .btn-secondary:hover img {
  display: block;
  }
  
  .btn-secondary:hover::after {
  opacity: 0;
  content: '';
  background-color: transparent;
  position: relative;
  }
  
   ul {
      margin-top: 30px;
  }
`;

class StoryModal extends Component {
  state = {
    developersPopoverOpen: false,
    testersPopoverOpen: false
  };

  togglePopOver = type => {
    this.setState(prevState => ({
      [type]: !prevState[type]
    }));
  };

  render() {
    const {
      currentSprintId,
      toggleModal,
      isOpen,
      currentStory,
      okToAssignWorkers,
      okToUnassignWorkers,
      refetch
    } = this.props;

    let developers = [];
    let testers = [];
    if (
      currentStory.storySkillStaffing &&
      currentStory.storySkillStaffing.length > 0
    ) {
      developers = currentStory.storySkillStaffing.find(
        req => req.skill.skillNumber === 1
      );
      if (developers == null || developers === undefined) {
        developers = [];
      }
      testers = currentStory.storySkillStaffing.find(
        req => req.skill.skillNumber === 2
      );
      if (testers == null || testers === undefined) {
        testers = [];
      }
    }
    let assignedDevelopers = [];
    let assignedTesters = [];
    if (currentStory.workersLink && currentStory.workersLink.length > 0) {
      assignedDevelopers = currentStory.workersLink.filter(
        item => item.teamWorker.skillNumber === 1
      );
      if (assignedDevelopers == null || assignedDevelopers === undefined) {
        assignedDevelopers = [];
      }
      assignedTesters = currentStory.workersLink.filter(
        item => item.teamWorker.skillNumber === 2
      );
      if (assignedTesters == null || assignedTesters === undefined) {
        assignedTesters = [];
      }
    }
    return (
      <StyledModal
        isOpen={isOpen}
        toggle={toggleModal}
        fade={false}
        centered
        size="lg"
      >
        <Fragment>
          <ModalHeader toggle={toggleModal}>
            {currentStory.storyName}
          </ModalHeader>
          <ModalBody>
            <h5>Stats</h5>
            <Stats>
              <Col>
                <span id={`story-${currentStory.storyNumber}`}>
                  <Badge color="info">#{currentStory.storyNumber}</Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`story-${currentStory.storyNumber}`}
                  >
                    Story Number
                  </UncontrolledTooltip>
                </span>
                <span id={`storyPoints-${currentStory.storyPoints}`}>
                  <Badge color="warning">{currentStory.storyPoints}</Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`storyPoints-${currentStory.storyPoints}`}
                  >
                    Story Points
                  </UncontrolledTooltip>
                </span>
                <span id={`businessPoints-${currentStory.businessPoints}`}>
                  <Badge color="primary">{currentStory.businessPoints}</Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`businessPoints-${currentStory.businessPoints}`}
                  >
                    Business Value
                  </UncontrolledTooltip>
                </span>
              </Col>
              <Col>
                <span id={`hoursRemaining-${currentStory.id}`}>
                  <Badge color="secondary">
                    {parseInt(
                      currentStory.storyPercentComplete *
                      currentStory.totalPlannedEffort *
                      0.01,
                      10 // eslint issue
                    )}
                  </Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`hoursRemaining-${currentStory.id}`}
                  >
                    Hours remaining
                  </UncontrolledTooltip>
                </span>
                <span>/</span>
                <span id={`effort-${currentStory.totalPlannedEffort}`}>
                  <Badge color="secondary">
                    {currentStory.totalPlannedEffort}
                  </Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`effort-${currentStory.totalPlannedEffort}`}
                  >
                    Original effort
                  </UncontrolledTooltip>
                </span>
              </Col>
            </Stats>
            <Row>
              <Col>
                <ImageHoverModal>
                  {/* <p>
                    <strong>
                      Team Members required: {developers.staffingRequired}{" "}
                    </strong>
                  </p> */}
                  <AssignedWorkerContainer
                    sprintId={currentSprintId}
                    assigned={assignedDevelopers}
                    staffingRequired={developers.staffingRequired}
                    type="developer"
                    skillNumber={1}
                    togglePopOver={this.togglePopOver}
                    storyId={currentStory.id}
                    status={currentStory.status}
                    refetch={refetch}
                    okToAssignWorkers={okToAssignWorkers}
                    okToUnassignWorkers={okToUnassignWorkers}
                  />
                </ImageHoverModal>
              </Col>
              {testers != null && testers !== undefined && testers.length > 0 && (
                <Col>
                  <p>
                    <strong>
                      Testers required: {testers.staffingRequired}
                    </strong>
                  </p>
                  <AssignedWorkerContainer
                    assigned={assignedTesters}
                    staffingRequired={testers.staffingRequired}
                    type="tester"
                    skillNumber={2}
                    togglePopOver={this.togglePopOver}
                    storyId={currentStory.id}
                    status={currentStory.status}
                    refetch={refetch}
                    okToAssignWorkers={okToAssignWorkers}
                  />
                </Col>
              )}
            </Row>

            <Row>
              <Col>
                <h4>Story Information</h4>
                <table>
                  <tbody>
                    <tr>
                      <StyledNarrowTd>Story Status:</StyledNarrowTd>
                      <StyledWideTd>
                        {getStoryStatusDisplayName(currentStory.status)}
                      </StyledWideTd>
                    </tr>
                    <tr>
                      <StyledNarrowTd>Story Points:</StyledNarrowTd>
                      <StyledWideTd>{currentStory.storyPoints}</StyledWideTd>
                    </tr>
                    {/* <!--hide team members required--> */}
                    {/* <tr>
                      <StyledNarrowTd>#Team Members required:</StyledNarrowTd> */}
                      {/* // TODO add this back in for a check "testers.staffingRequired +" 
                      not trying to remove the idea of testers
                      just removing the requirement to have testers on the project */}
                      {/* <StyledWideTd>{developers.staffingRequired}</StyledWideTd>
                    </tr> */}
                    {/* <!--Hidden planned hours--> */}
                    {/* <tr>
                      <StyledNarrowTd>Planned Effort (hrs):</StyledNarrowTd>

                      <StyledWideTd>
                        {currentStory.totalPlannedEffort}
                      </StyledWideTd>
                    </tr> */}
                  </tbody>
                </table>
                <br />
                <h5>Story Description</h5>
                <div>{ReactHtmlParser(currentStory.storyDescription)}</div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button outline color="primary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
          {okToAssignWorkers && (
            <Fragment>
              <AvailableWorkerContainer
                popoverOpen={this.state.developersPopoverOpen}
                assigned={assignedDevelopers}
                target="developerPopover"
                type="Developer"
                skillNumber={1}
                refetch={refetch}
                togglePopOver={() =>
                  this.togglePopOver("developersPopoverOpen")
                }
                storyId={currentStory.id}
              />
              {assignedTesters && assignedTesters.length > 0 && (
                <AvailableWorkerContainer
                  popoverOpen={this.state.testersPopoverOpen}
                  assigned={assignedTesters}
                  target="testerPopover"
                  type="Tester"
                  skillNumber={2}
                  refetch={refetch}
                  togglePopOver={() => this.togglePopOver("testersPopoverOpen")}
                  storyId={currentStory.id}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      </StyledModal>
    );
  }
}

export default StoryModal;
