import React from "react";
import styled from "styled-components";
import { CardBody } from "reactstrap";
import Story from "./Story";
import StyledStoryContainer from "../../../components/styled/StyledStoryContainer";
import StoryContainerHeader from "./StoryContainerHeader";

const StyledStoryList = styled.div`
  padding: 8;
`;

const StoryContainerWithDnD = props => {
  const { title, refetch, stories } = props;
  return (
    <StyledStoryContainer>
      <StoryContainerHeader title={title} stories={stories} />
      <CardBody>
        <StyledStoryList>
          {stories.map((story, index) => (
            <Story
              story={story}
              index={index}
              key={story.id}
              refetch={refetch}
            />
          ))}
        </StyledStoryList>
      </CardBody>
    </StyledStoryContainer>
  );
};
export default StoryContainerWithDnD;
