/* eslint-disable import/no-cycle */
/* eslint  react/prop-types: 0 */

import React, { useState, useContext } from "react";
import { Button, Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useMutation } from "@apollo/client";

// import { TEAM_QUERY } from "../../../graphql/queries";
import { UPDATE_STORYTOSPRINTLINK_MUTATION } from "../../../graphql/mutations";
import { ProjectContext } from "GlobalState";
// import { SPRINT_CAPACITY_QUERY } from "../../Reports/components/CapacityPlanning";
import { MEMBER_LOAD_QUERY } from "../../../graphql/queries";

const SprintAction = props => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  var sprintId = props.sprintId;
  const [state] = useContext(ProjectContext);
  var teamId = state.currentTeamId;

  const queriesToRefetch = [
    {
      query: MEMBER_LOAD_QUERY,
      variables: { teamId, sprintId }
    }
  ];

  const [updateStoryToSprintLink] = useMutation(
    UPDATE_STORYTOSPRINTLINK_MUTATION, { refetchQueries: queriesToRefetch }
  );

  // const actionClicked = async (e, act, sid) => {
  //   setAction(act);
  //   setSprintId(sid);
  //   await updateStoryToSprintLink;
  //   await props.refetch;
  // };

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <span>
      <Button
        disabled={props.disabled}
        color="primary"
        outline
        id={`Popover-${props.id}`}
        onClick={toggle}
      >
        <i className="fa fa-ellipsis-h" />
      </Button>
      <Popover
        placement={props.placement}
        isOpen={popoverOpen}
        target={`Popover-${props.id}`}
        toggle={toggle}
      >
        <PopoverBody>
          <Dropdown isOpen toggle={toggle}>
            <DropdownToggle caret>Select Story</DropdownToggle>
            <DropdownMenu>
              {
                props.data.filter(s => s.status !== "COMPLETE").map(sprint => (
                  <DropdownItem
                    disabled={sprint.sprintNumber < props.sprintNumber}
                    key={sprint.id}
                    value={sprint.id}
                    onClick={e => {
                      e.preventDefault();
                      updateStoryToSprintLink({
                        variables: {
                          action: "ADD",
                          storyId: props.id,
                          sprintId: sprint.id
                        }
                      }).then(() => {
                        props.refetch();
                      });
                    }}
                  >
                    Sprint {sprint.sprintNumber}
                  </DropdownItem>
                ))
              }
              {
                (props.row.status === "NEXT_UP") || (props.row.status === "IN_PROGRESS") ||
                  (props.row.status === "BACKLOG") ? (
                  <DropdownItem
                    key="disconnect"
                    value="disconnect"
                    onClick={e => {
                      e.preventDefault();
                      updateStoryToSprintLink({
                        variables: {
                          action: "REMOVE",
                          storyId: props.id,
                          sprintId: props.sprintId
                        }
                      }).then(() => {
                        props.refetch();
                      });
                    }}
                  >
                    {" "}
                   Not Selected
                  </DropdownItem>
                ) :
                  (
                    ""
                  )
              }
            </DropdownMenu>
          </Dropdown>
        </PopoverBody>
      </Popover>
    </span>
  );
};

export default SprintAction;
