import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import auth from "../Auth";

const StyledCallback = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backgroundcolor: white;
`;

class Callback extends Component {
  async componentDidMount() {
    await auth.handleAuthentication();
    this.props.history.replace("/Welcome");
  }

  render() {
    return (
      <StyledCallback>
        <span />
      </StyledCallback>
    );
  }
}

export default withRouter(Callback);
