import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ConferenceForm from "./forms/ConferenceForm";
import MeetingForm from "./forms/MeetingForm";
import IndvRewardForm from "./forms/IndvRewardForm";
import TeamRewardForm from "./forms/TeamRewardForm";
import TrainingForm from "./forms/TrainingForm";

function interactionTypeSwitch(type) {
  switch (type) {
    case "1-on-1 Session":
      return "CONFERENCE";
    case "Scrum Event":
      return "MEETING";
    case "Individual Reward":
      return "INDVREWARD";
    case "Team Reward":
      return "TEAMREWARD";
    case "Training":
      return "TRAINING";
    default:
      return "";
  }
}

const InteractionModal = ({
  teamId,
  sprintId,
  toggle,
  userId,
  currentWeekNumber,
  weekNumber,
  disabled,
  interactionModal: { isOpen, type, day, interData = null }
}) => {
  const interactionType = interactionTypeSwitch(type);
  return (
    <div>
      {disabled ? null : (
        <Modal size="md" isOpen={isOpen} toggle={toggle} fade={false} centered>
          <ModalHeader toggle={toggle}>
            {interData ? "Update" : "Add"} {type}
          </ModalHeader>
          <ModalBody>
            {type === "1-on-1 Session" && (
              <ConferenceForm
                disabled={disabled}
                toggleModal={toggle}
                weekDay={day.toUpperCase()}
                type={interactionType.toUpperCase()}
                weekNumber={weekNumber}
                teamId={teamId}
                sprintId={sprintId}
                data={interData}
                userId={userId}
              />
            )}
            {type === "Scrum Event" && (
              <MeetingForm
                toggleModal={toggle}
                weekDay={day.toUpperCase()}
                type={interactionType.toUpperCase()}
                weekNumber={weekNumber}
                teamId={teamId}
                currentWeekNumber={currentWeekNumber}
                sprintId={sprintId}
                data={interData}
              />
            )}
            {type === "Individual Reward" && (
              <IndvRewardForm
                toggleModal={toggle}
                weekDay={day.toUpperCase()}
                type={interactionType.toUpperCase()}
                weekNumber={weekNumber}
                teamId={teamId}
                sprintId={sprintId}
                data={interData}
                userId={userId}
              />
            )}
            {type === "Team Reward" && (
              <TeamRewardForm
                toggleModal={toggle}
                weekDay={day.toUpperCase()}
                type={interactionType.toUpperCase()}
                weekNumber={weekNumber}
                teamId={teamId}
                sprintId={sprintId}
                data={interData}
              />
            )}
            {type === "Training" && (
              <TrainingForm
                toggleModal={toggle}
                weekDay={day.toUpperCase()}
                type={interactionType.toUpperCase()}
                weekNumber={weekNumber}
                teamId={teamId}
                sprintId={sprintId}
                data={interData}
                userId={userId}
              />
            )}
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default InteractionModal;
