import React, { Component } from "react";
import styled from "styled-components";
import { Card, CardBody, Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import { HistoryWeek } from "../../Reports/components/HistoryItems/HistoryWeek";
import HistoryInfo from "../../Reports/components/HistoryInfo";
import CardHeader from "../../../components/styled/CardHeader";

const Bold = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;
export default class HistoryPanel extends Component {
  state = {
    historyCardOpen: false,
    titleEl: document.title
  };

  toggleHistoryCard = () => {
    this.setState(prevState => ({
      historyCardOpen: !prevState.historyCardOpen
    }));
  };

  afterPrint() {
    document.title = this.state.titleEl;
  }
  beforePrint(newTitle) {
    document.title = newTitle;
  }

  render() {
    const { selectedWeek, history } = this.props;
    this.fileName = "History ReportWeek " + selectedWeek;
    return (
      <Card>
        <CardHeader>
          <Bold>Team History Timeline</Bold>
          <ReactToPrint
            trigger={() => (
              <Button color="secondary">
                <i className="far fa-file-pdf" /> Print
              </Button>
            )}
            content={() => this.HistoryRef}
            onBeforePrint={() => this.beforePrint(this.fileName)}
            onAfterPrint={() => this.afterPrint()}
          />
        </CardHeader>
        <CardBody>
          <HistoryInfo cardOpen={this.state.historyCardOpen} toggleCard={this.toggleHistoryCard} />

          <PrintDiv
            id={`h${selectedWeek.toString()}`}
            ref={el => {
              this.HistoryRef = el;
            }}
          >
            <HistoryWeek key={selectedWeek} weekNumber={selectedWeek} componentList={history} />
          </PrintDiv>
        </CardBody>
      </Card>
    );
  }
}
