import React from "react";

const Footer = () => (
  <footer className="app-footer">
    <span className="float-left">
      <a href="mailto:support@simulationpl.com">Contact Support - support@simulationpl.com</a>
    </span>
    <span className="float-right">
      <span>SimAgile&nbsp;&nbsp;</span>
      {/* &copy; {new Date().getFullYear()} Fissure. */}
      &copy; Simulation Powered Learning {new Date().getFullYear()}.
    </span>
  </footer>
);

export default Footer;
