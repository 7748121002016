import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import ReactNotification from "react-notifications-component";
import { ProjectContext } from "../GlobalState";
import "../static/styles/sp-starter-styles.css";

const routes = {
  "/": "Login",
  "/backlog": "Product Backlog",
  "/planning": "Planning",
  "/scrumboard": "Scrum Board",
  "/calendar": "Calendar",
  "/teams": "Runs",
  "/welcome": "Welcome",
  "/run-sprint": "Run Sprint",
  "/reports": "Reports",
  "/reports/history": "Reports - History",
  "/reports/memberLoad": "Reports - Member Load",
  "/reports/sprintBurndown": "Reports - Sprint Burndown",
  "/reports/velocityTrend": "Reports - Velocity Trend",
  "/reports/cumulativeFlow": "Reports - Cumulative Flow",
  "/reports/endOfRun": "Reports - End Of Run",
  "/management": "Student Management",
  "/management/students": "Student Management",
  "/management/classrooms": "Classroom Management"
};

const StyledHeader = styled.header`
  padding: 0.75rem 1rem;
  background-color: white;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

const DisplayHeader = props => {
  const [state] = useContext(ProjectContext);
  return (
    <StyledHeader>
      <ReactNotification
        types={[
          {
            htmlClasses: ["notification-sim"],
            name: "sim"
          }
        ]}
      />
      <h2 className="page-Header">{routes[props.location.pathname]}</h2>
      <div className="runWeekHeader">
        <span>
          <b>User Name:</b> {props.name}
        </span>
        <br />
        <span>
          <b>Team Id: </b> {state.currentTeamId}{" "}
        </span>
        <br />
        {state.currentTeamStatus === "COMPLETED" ? (
          <span>
            <b>Status: </b> <b>Complete</b>
          </span>
        ) : (
          <div>
            <span>
              <b>Current Sprint:</b> {state.currentSprintNumber}
            </span>
            <br />
            <span>
              <b>Week:</b> {state.currentWeekNumber}
              &nbsp; &nbsp;
              <b>Day: </b>
              {state.currentRunDay}
            </span>
          </div>
        )}
        {state.teamStudentId > 0 &&
          state.teamStudentId !== props.currentUser.id && (
            <p style={{ color: "red" }}>
              Student Run: {state.teamStudentFirst}&nbsp;
              {state.teamStudentLast} <br />
            </p>
          )}
      </div>
    </StyledHeader>
  );
};

export default withRouter(DisplayHeader);
