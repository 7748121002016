import React, { useState, Fragment } from "react";
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import CardHeader from "../../../components/styled/CardHeader";
import ClassroomModal from "./ClassroomModal";
import { INSTRUCTOR_CLASSES_QUERY } from "../../../graphql/queries";

const DELETE_CLASSROOM = gql`
  mutation DELETE_CLASSROOM($id: Int!) {
    deleteClassroom(id: $id)
  }
`;

const ClassroomManagement = props => {
  const { classrooms, instructorId } = props;
  const [state, setState] = useState({
    activeClassDetailTab: "classroom",
    classroomDropdownOpen: false,
    statusDropdownOpen: false,
    createClassroomModalOpen: false,
    index: 0,
    modalData: null
  });

  const [confirmationModal, setConfirmationModal] = useState({
    id: null,
    visible: false
  });

  const toggleConfirmationModal = (id = null) => {
    setConfirmationModal({ id, visible: !confirmationModal.visible });
  };

  const queriesToRefetch = [
    { query: INSTRUCTOR_CLASSES_QUERY, variables: { instructorId } }
  ];

  const [deleteClassroom] = useMutation(DELETE_CLASSROOM, {
    refetchQueries: queriesToRefetch
  });

  const onClickEdit = id => {
    const classroom = classrooms.find(c => c.id === id);
    const d = {
      id,
      className: classroom.className,
      startDate: classroom.startDate,
      endDate: classroom.endDate,
      status: classroom.status
    };
    toggleModal(d);
  };

  const onClickRemove = () => {
    deleteClassroom({ variables: { id: confirmationModal.id } }).then(() => {
      toggleConfirmationModal();
    });
  };

  const actionsFormatter = (cellContent, row) => {
    return (
      <span>
        <Button
          type="button"
          color="primary"
          outline
          onClick={() => onClickEdit(row.id)}
        >
          <i className="fa fa-edit" />
        </Button>
        <Button
          // disabled={row.students.length > 0}
          type="button"
          color="primary"
          outline
          onClick={() => toggleConfirmationModal(row.id)}
        >
          <i className="fa fa-trash-alt" />
        </Button>
      </span>
    );
  };

  const columns = [
    {
      dataField: "className",
      text: "Classroom Name",
      sort: true,
      align: "left",
      headerAlign: "center",
      headerStyle: { width: "20%" }
    },
    {
      dataField: "startDate",
      text: "Start Date",
      formatter: cell => moment(cell).format("YYYY-MM-DD"),
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "16%" }
    },
    {
      dataField: "endDate",
      text: "End Date",
      formatter: cell => moment(cell).format("YYYY-MM-DD"),
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "16%" }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "16%" }
    },
    // {
    //   dataField: "students",
    //   text: "Students",
    //   formatter: cell => cell.length,
    //   sort: true,
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: { width: "16%" }
    // },
    {
      dataField: "id",
      text: "Actions",
      formatter: actionsFormatter,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "16%" }
    }
  ];

  const toggleModal = (mData = null) => {
    setState({
      ...state,
      modalData: mData,
      createClassroomModalOpen: !state.createClassroomModalOpen
    });
  };
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <b>Classroom Management </b>
          <Button color="primary" onClick={() => toggleModal()}>
            Create a classroom
          </Button>
        </CardHeader>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={classrooms}
            columns={columns}
            condensed
            striped
            bootstrap4
          />
        </CardBody>
      </Card>
      <ClassroomModal
        data={state.modalData}
        toggle={() => toggleModal()}
        isOpen={state.createClassroomModalOpen}
        primaryInstructorId={props.instructorId}
      />

      <Modal
        isOpen={confirmationModal.visible}
        toggle={() => toggleConfirmationModal()}
      >
        <ModalHeader toggle={() => toggleConfirmationModal()}>
          Remove Classroom
        </ModalHeader>
        <ModalBody>Are you sure you want to remove the classroom?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleConfirmationModal()}>
            Cancel
          </Button>
          <Button color="secondary" onClick={() => onClickRemove()}>
            Yes
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ClassroomManagement;
