import React from "react";
import styled from "styled-components";

import Interaction from "./Interaction";

const Layout = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    background-color: #fff;
    border: 1px solid #c2cfd6;
    box-shadow: 0 1px 1px rgba(9, 45, 66, 0.15);
    cursor: pointer !important;
    margin-bottom: 10px;
    position: relative;
    padding: 10px;

    &:hover {
      background-color: #fafafa;
    }
  }
`;

const InteractionsList = ({
  interactions,
  sprintId,
  weekNumber,
  toggleModal,
  disabled,
  teamId
}) => (
  <Layout>
    {interactions.map(interaction => (
      <Interaction
        disabled={disabled}
        key={interaction.id}
        data={interaction}
        sprintId={sprintId}
        weekNumber={weekNumber}
        toggleModal={toggleModal}
        teamId={teamId}
      />
    ))}
  </Layout>
);

export default InteractionsList;
