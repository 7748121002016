import React from "react";
import RunsImage from "../../../static/images/helpModal/Runs.png";

const Runs = () => (
  <div>
    <h3>Runs</h3>
    <p>
      You can view and review your past runs using the Runs page.
    </p>

    <img key="Runs1" className="HelpModalimage" alt="Runs screenshot" src={RunsImage} />
    <br /><br />
  </div>
);

export default Runs;
