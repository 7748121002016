import React, { Fragment, useContext, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { Error } from "../Loading";
import { TEAM_QUERY } from "../../graphql/queries";
import ChatMessagesView from "./ChatMessagesView";
import { ProjectContext } from "../../GlobalState";

const StyledModal = styled(Modal)`
  .modal-body {
    height: 50%;
    align-items: center !important;
    max-height: 76vh;
    overflow-y: scroll;
  }
`;

const ChatModal = props => {
  const [state, dispatch] = useContext(ProjectContext);

  useEffect(() => {
    // Anything in here is fired on component mount.
    const payload = {
      option: false
    };
    dispatch({
      type: "HIGHLIGHT_CHAT",
      payload
    });
    setTimeout(function() {
      const demo = document.getElementById("demo");
      demo.scrollTo({ left: 0, top: 9999, behavior: "smooth" });
    }, 1000);
    return () => {
      // Anything in here is fired on component unmount.
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error } = useQuery(TEAM_QUERY, {
    variables: { teamId: state.currentTeamId },
    skip: !state.currentTeamId || state.currentTeamId === 0
  });

  if (error) return <Error error={error} />;

  return (
    <StyledModal
      isOpen
      fade={false}
      centered
      toggle={props.toggleModal}
      size="m"
    >
      <ModalHeader toggle={props.toggleModal}>Team Chat</ModalHeader>
      <ModalBody className="modal-body-message" id="demo">
        <Fragment>
          <ChatMessagesView />
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={props.toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

export default ChatModal;
