import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: ${props =>
    props.columns ? props.columns : "initial"};

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export default Grid;
