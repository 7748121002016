import React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import Loading from "../../../components/Loading";

const LeftStyledButton = styled(Button)`
  margin: 20px 10px 20px 10px;
  float: left;
`;

const RunButton = props => {
  const getButtonLabel = () => {
    let label = "Run Sprint";
    if (
      !props.runWeekStatusObj ||
      !props.runWeekStatusObj ||
      props.runWeekStatusObj.returnStatus === "OFF"
    ) {
      label = "Run Sprint";
    } else if (
      props.runWeekStatusObj.returnStatus === "COND" ||
      props.runWeekStatusObj.returnStatus === "PS"
    ) {
      label = "Continue";
    } else {
      label = "Continue running";
    }

    return label;
  };

  return (
    <LeftStyledButton
      disabled={props.loading || props.disabled}
      color="primary"
      onClick={event => props.handleClick(event)}
    >
      <>
        {props.loading && <Loading />}
        {getButtonLabel()}
      </>
    </LeftStyledButton>
  );
};

export default RunButton;
