import React from "react";

/**
 * 0 worker
 *1 training
 */
export const IndvTraining = props => (
  <div className="content">
    <h5>
      <span role="img" aria-label="party">
        🎓
      </span>
      {props.properties[0].itemValue}&nbsp;
      <small>
        attended the <strong>{props.properties[1].itemValue} </strong>all day today.
      </small>
    </h5>
  </div>
);
