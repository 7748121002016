import React from "react";
import { CardHeader, Badge } from "reactstrap";
import { UncontrolledTooltip } from "../../../components/styled/UncontrolledTooltip";

const StoryContainerHeader = props => {
  const badges = [
    {
      key: "storyCount",
      name: "Story count",
      color: "secondary",
      count: props.stories.length
    },
    {
      key: "plannedEffort",
      name: "Total Planned Effort",
      color: "info",
      count: props.stories.reduce(
        (total, current) => total + current.totalPlannedEffort,
        0
      )
    },
    {
      key: "storyPoints",
      name: "Total Story Points",
      color: "warning",
      count: props.stories.reduce(
        (total, current) => total + current.storyPoints,
        0
      )
    },
    {
      key: "businessPoints",
      name: "Total Business Value",
      color: "primary",
      count: props.stories.reduce(
        (total, current) => total + current.businessPoints,
        0
      )
    }
  ];
  return (
    <CardHeader>
      <b>{props.title}</b>
      <div>
        {badges.map(badge => (
          <span key={badge.key} id={badge.key}>
            <Badge color={badge.color}>{badge.count}</Badge>
            <UncontrolledTooltip placement="top" target={badge.key}>
              {badge.name}
            </UncontrolledTooltip>
          </span>
        ))}
      </div>
    </CardHeader>
  );
};

export default StoryContainerHeader;
