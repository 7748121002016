import React, { Fragment, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import { store } from "react-notifications-component";
import Loading, { Error } from "../../../../components/Loading";
import WorkerSelector from "../../../../components/Worker/WorkerSelector";
import {
  WEEKDAY_INTERACTIONS_QUERY,
  MEMBER_LOAD_QUERY
} from "../../../../graphql/queries";
import "react-notifications-component/dist/theme.css";

const CREATE_TRAINING_MUTATION = gql`
  mutation CREATE_TRAINING_MUTATION(
    $teamId: Int!
    $sprintId: Int!
    $weekNumber: Int!
    $trainingId: Int!
    $teamWorkerId: Int!
  ) {
    createTraining(
      teamId: $teamId
      sprintId: $sprintId
      weekNumber: $weekNumber
      trainingId: $trainingId
      teamWorkerId: $teamWorkerId
    ) {
      id
    }
  }
`;

const TRAININGS_QUERY = gql`
  query TRAININGS_QUERY($teamId: Int!) {
    trainings(teamId: $teamId) {
      id
      trainingName
      requiresTeam
      length
      startDay
    }
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 280px 200px;
  grid-gap: 10px;

  > .last {
    grid-column: 1 / -1;
  }
`;

const TrainingForm = ({
  toggleModal,
  teamId,
  weekDay,
  type,
  sprintId,
  weekNumber,
  data,
  userId
}) => {
  const [form, setValues] = useState({
    teamId,
    sprintId,
    weekNumber,
    weekDay,
    type,
    trainingId: data ? data.trainingDetail.trainingId : 0,
    teamWorkerId: data ? data.trainingDetail.teamWorkerId : 0,
    requiresTeam: data ? data.trainingDetail.requiresTeam : false
  });

  const { data: tData, loading: tLoading, error: tError } = useQuery(
    TRAININGS_QUERY,
    {
      variables: { teamId }
    }
  );

  const queriesToRefetch = [
    {
      query: WEEKDAY_INTERACTIONS_QUERY,
      variables: { teamId, sprintId, weekNumber }
    },
    {
      query: MEMBER_LOAD_QUERY,
      variables: { teamId, sprintId }
    }
  ];

  const [
    createTraining,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_TRAINING_MUTATION, {
    refetchQueries: queriesToRefetch
  });

  const [hide, setHide] = useState(
    data && data.trainingDetail.training.requiresTeam
  );

  if (tLoading) return "Loading...";
  if (tError) return <Error error={tError} />;
  if (mutationError) return <Error error={mutationError} />;

  const { trainings } = tData;

  const getTrainingOptions = () => {
    const options = trainings.map(training => {
      return {
        label: training.trainingName,
        value: training.id,
        requiresTeam: training.requiresTeam
      };
    });
    return options;
  };

  const onChangeWorker = value => {
    setValues({
      ...form,
      teamWorkerId: value.value
    });
  };

  const onChangeTraining = value => {
    setHide(value.requiresTeam);
    setValues({
      ...form,
      teamWorkerId: value.requiresTeam ? 0 : form.teamWorkerId,
      trainingId: value.value,
      requiresTeam: value.requiresTeam
    });
  };

  const showNotification = text => {
    store.addNotification({
      message: text,
      container: "top-right",
      type: "warning",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "bounceOut"],
      dismiss: { duration: 5000 },
      width: 294,
      content: <div className="notification-sim">{text}</div>
    });
  };

  const onSubmitHandler = e => {
    e.preventDefault();

    // validations
    if (form.trainingId === 0) {
      showNotification("Please select an option of training");
      return;
    }

    if (form.teamWorkerId === 0 && !form.requiresTeam) {
      showNotification("Please select a team member");
      return;
    }

    form.teamWorkerId = parseInt(form.teamWorkerId);
    form.trainingId = parseInt(form.trainingId);

    // we don't care about the value of teamWorkerId when user creates a training for the entire team
    if (form.requiresTeam && isNaN(form.teamWorkerId)) {
      form.teamWorkerId = 0;
    }

    createTraining({ variables: { ...form } }).then(response => {
      if (response.data.createTraining !== null) {
        toggleModal();
      } else {
        showNotification(
          "Cannot schedule more than 8 hours of team member capacity per day or create duplicate calendar entries."
        );
      }
    });
  };

  const renderTrainingSelector = () => {
    return (
      <FormGroup>
        <Label id="trainingLabel">Training</Label>
        <Select
          className="my-className"
          name="trainingSelector"
          aria-labelledby="trainingLabel"
          options={getTrainingOptions()}
          onChange={onChangeTraining}
          required
        ></Select>
      </FormGroup>
    );
  };

  const renderWorkerSelector = () => {
    if (!hide) {
      return (
        <WorkerSelector
          controlName="teamWorkerId"
          teamId={teamId}
          defaultValue={form.teamWorkerId}
          onChange={onChangeWorker}
        />
      );
    }
  };

  return (
    <Fragment>
      <h6>
        <small>
          Note: to change the training class, delete this training and add a new
          one.
        </small>
      </h6>

      <StyledForm id="training-form" onSubmit={onSubmitHandler}>
        {renderTrainingSelector()}
        {renderWorkerSelector()}

        <Button
          disabled={
            mutationLoading ||
            (data && Object.values(form).some(value => value === null))
          }
          className="last"
          color="primary"
          type="submit"
        >
          {mutationLoading ? (
            <Loading text={data ? "Updating" : "Saving"} />
          ) : data ? (
            "Update"
          ) : (
            "Save"
          )}
        </Button>
      </StyledForm>
    </Fragment>
  );
};

export default TrainingForm;
