import React from "react";

/*
0 reward
*/
export const TeamReward = props => (
  <p>
    The team was rewarded with: <strong>{props.properties[0].itemValue}</strong>
    <br />
    <br />
  </p>
);
