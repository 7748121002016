/* eslint-disable import/prefer-default-export */
import React from "react";
import ContentDiv from "../../../../components/styled/Reports/ContentDiv";

/*
1 = Story name
*/
export const StoryComplete = props => {
  if (!props || !props.properties || props.properties.length < 1) {
    return <ContentDiv />;
  }
  const storyName = props.properties.find(t => t.itemKey === "storyName")
    .itemValue;
  return (
    <ContentDiv style={{ backgroundColor: "#dde4ee" }}>
      <i className="fa fa-thumbs-up" /> User Story: <strong>{storyName}</strong>{" "}
      has been accepted.
    </ContentDiv>
  );
};
