import { Nav as ReactstrapNav } from "reactstrap";
import styled from "styled-components";

const Nav = styled(ReactstrapNav)`
  &.nav-pills .nav-link.active,
  &.nav-pills .navbar .active.dropdown-toggle,
  .navbar .nav-pills .active.dropdown-toggle,
  .show > .nav-pills .nav-link,
  .show > .nav-pills .navbar .dropdown-toggle,
  .navbar .show > .nav-pills .dropdown-toggle {
    background-color: var(--primary-color);
  }
`;

export default Nav;
