import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "reactstrap";
import { ME_QUERY } from "../../../graphql/queries";

import { INITIALIZE_TEAM_MUTATION } from "../../../graphql/mutations";
import Loading from "../../../components/Loading";

const InitializeTeam = ({
  userId,

  classStudentRunId,
  updateUserStatus,

  refreshTheTeam
}) => {
  const updateUser = updateUserStatus.toString();
  const [initializeTeam, { loading: initializeLoading }] = useMutation(
    INITIALIZE_TEAM_MUTATION,
    {
      variables: {
        userId,
        classStudentRunId,
        updateUser
      },
      refetchQueries: [
        {
          query: ME_QUERY
        }
      ],
      onCompleted: async data => {
        if (data && data.team) {
          refreshTheTeam(data.team.id);
        }
      }
    }
  );

  const initTeam = async e => {
    e.preventDefault();
    const upd = updateUserStatus.toString();
    await initializeTeam(userId, classStudentRunId, upd);
  };

  return (
    <div>
      {initializeLoading ? <Loading /> : <span />}
      <Button color="primary" type="button" onClick={e => initTeam(e)}>
        Start Run
      </Button>
    </div>
  );
};

export default InitializeTeam;
