import React, { useState, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { CardBody, Badge, CardFooter } from "reactstrap";
import { ProjectContext } from "GlobalState";
import { UncontrolledTooltip } from "../../../components/styled/UncontrolledTooltip";
import StyledStoryComponent from "../../../components/styled/StyledStoryComponent";
import StoryModal from "./StoryModal";
import { getStoryStatusDisplayName } from "../../../utilities/utils";
import StoryFooter from "./StoryFooter";

const DraggableStory = props => {
  const [state] = useContext(ProjectContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const renderRequiredIcon = story => {
    if (!story.requiredStory) return null;

    return (
      <div className="isRequired" id={`isRequired-${story.id}`}>
        <Badge color="danger">
          <i className="fa fa-exclamation" />
        </Badge>
        <UncontrolledTooltip placement="top" target={`isRequired-${story.id}`}>
          Required for MVP
        </UncontrolledTooltip>
      </div>
    );
  };

  const { story, refetch, index, currentSprintId } = props;
  return (
    <>
      <Draggable draggableId={story.storyNumber.toString()} index={index}>
        {provided => (
          <StyledStoryComponent
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={() => setModalIsOpen(!modalIsOpen)}
          >
            {renderRequiredIcon(story)}
            <CardBody>
              {story.storyName}
              <br />
              <strong>Status:</strong> {getStoryStatusDisplayName(story.status)}
            </CardBody>
            <CardFooter>
              <StoryFooter
                storyId={story.id}
                index={index}
                teamId={state.currentTeamId}
              />
            </CardFooter>
          </StyledStoryComponent>
        )}
      </Draggable>
      {modalIsOpen && (
        <StoryModal
          currentSprintId={currentSprintId}
          toggleModal={toggleModal}
          isOpen={modalIsOpen}
          currentStory={story}
          okToAssignWorkers
          okToUnassignWorkers
          refetch={refetch}
        />
      )}
    </>
  );
};

export default DraggableStory;
