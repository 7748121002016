import React, { useState, useEffect } from "react";
import { Card, CardBody, Progress } from "reactstrap";
import CardHeader from "../../../components/styled/CardHeader";
import { initialCap } from "../../../utilities/utils";

const ProgressCard = props => {
  const [progress, setProgress] = useState("0");

  useEffect(() => {
    if (props.currentWeekDay === "MONDAY") {
      if (props.runWeekStatus.returnStatus !== "OFF") {
        setProgress("10");
      } else {
        setProgress("0");
      }
    }
    if (props.currentWeekDay === "TUESDAY") {
      setProgress("30");
    }
    if (props.currentWeekDay === "WEDNESDAY") {
      setProgress("50");
    }
    if (props.currentWeekDay === "THURSDAY") {
      setProgress("70");
    }
    if (props.currentWeekDay === "FRIDAY") {
      setProgress("90");
    }
    if (props.runWeekStatus.returnStatus === "EOW") {
      setProgress("100");
    }
  }, [props.runWeekStatus.returnStatus, props.currentWeekDay]);

  return (
    <Card>
      <CardHeader>
        <b>Overall Progress: Today is: {initialCap(props.currentWeekDay)}</b>
      </CardHeader>
      <CardBody>
        <Progress value={progress} />
      </CardBody>
    </Card>
  );
};

export default ProgressCard;
