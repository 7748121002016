import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const CompletedStoriesTable = props => {
  const { SearchBar, ClearSearchButton } = Search;

  const formatRequiredStories = cell => {
    if (cell) {
      return "Yes";
    }
    return "No";
  };

  const currentSprintFormatter = cell => {
    if (cell === null) {
      return "Not Selected";
    }
    return <span>Sprint {cell.sprintNumber}</span>;
  };

  const storyNameToggleFormat = (cell, row) => (
    <Button
      onClick={e => {
        e.preventDefault();
        props.toggle(row);
      }}
      color="link"
    >
      {cell}
    </Button>
  );

  const columns = [
    {
      dataField: "businessPriority",
      text: "Order",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "10%" }
    },
    {
      dataField: "storyName",
      formatter: storyNameToggleFormat,
      text: "Story Name",
      sort: true,
      headerStyle: { width: "45%" }
    },
    {
      dataField: "requiredStory",
      formatter: formatRequiredStories,
      text: "Required for MVP",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "15%" }
    },
    {
      dataField: "storyPoints",
      text: "Story Points",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "15%" }
    },
    {
      dataField: "currentSprint",
      formatter: currentSprintFormatter,
      text: "Sprint",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "15%" }
    }
  ];
  return (
    <ToolkitProvider
      id="tkp-completed"
      keyField="id"
      data={props.stories}
      columns={columns}
      tableId={99}
      search
    >
      {tableProps => {
        const tProps = { ...tableProps };
        tProps.searchProps.tableId = "completed";

        return (
          <Fragment>
            <Container fluid>
              <Row>
                <Col style={{ paddingRight: 0, textAlign: "right" }}>
                  <SearchBar
                    key="sb-completed"
                    {...tProps.searchProps}
                    className="searchBar"
                    id="sb-completed"
                  />
                  <ClearSearchButton
                    {...tProps.searchProps}
                    className="clearSearchButton"
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <BootstrapTable
                    id="completed-table"
                    {...tableProps.baseProps}
                    condensed
                    striped
                    bootstrap4
                  />
                </Col>
              </Row>
            </Container>
          </Fragment>
        );
      }}
    </ToolkitProvider>
  );
};

export default CompletedStoriesTable;
