import React, { useContext } from "react";

import { Redirect } from "react-router-dom";
import { isNewUser } from "utilities/utils";

import { ProjectContext } from "GlobalState";

// this component helps determine the component to show on login
const Planning = ({ currentUser }) => {
  const [state] = useContext(ProjectContext);
  if (!state.loading) {
    if (isNewUser(currentUser)) {
      return <Redirect to="/welcome" />;
    }

    if (
      !state ||
      !state.currentTeamId ||
      state.currentTeamId === null ||
      state.currentTeamId === 0
    ) {
      return <Redirect to="/teams" />;
    }
    return <Redirect to="/backlog" />;
  }
  return <span>Loading</span>;
};

export default Planning;
