import React, { useState, Fragment, useContext } from "react";
import { Row, Col, Card, CardHeader } from "reactstrap";
import { ProjectContext } from "GlobalState";

import ScrollCardBody from "../../components/ScrollCardBody";
import InteractionTabs from "./components/InteractionTabs";
import InteractionModal from "./components/InteractionModal";
import Interactions from "./components/Interactions";

const Calendar = props => {
  const [state] = useContext(ProjectContext);
  const [interactionModal, toggleModal] = useState({
    isOpen: false,
    day: "",
    type: ""
  });

  const [interactionModal1, toggleModal1] = useState({
    isOpen: false,
    day: "",
    type: ""
  });

  const handleToggleModal = ({ type, day, interData = null }) => {
    toggleModal({
      isOpen: !interactionModal.isOpen,
      day,
      type,
      interData
    });
  };

  const handleToggleModal1 = ({ type, day, interData = null }) => {
    toggleModal1({
      isOpen: !interactionModal1.isOpen,
      day,
      type,
      interData
    });
  };

  const renderWeekNumber = (sprint, week) => {
    return (sprint - 1) * 2 + week;
  };

  return (
    <Fragment>
      <span>
        <b>Week {renderWeekNumber(state.currentSprintNumber, 1)}:</b>
      </span>
      <Interactions
        toggleModal={handleToggleModal}
        weekNumber={renderWeekNumber(state.currentSprintNumber, 1)}
        disabled={
          renderWeekNumber(state.currentSprintNumber, 1) <
          state.currentWeekNumber
        }
      />
      <InteractionModal
        disabled={
          renderWeekNumber(state.currentSprintNumber, 1) <
          state.currentWeekNumber
        }
        toggle={() =>
          toggleModal({
            ...interactionModal,
            isOpen: !interactionModal.isOpen
          })
        }
        interactionModal={interactionModal}
        currentWeekNumber={state.currentWeekNumber}
        sprintId={state.currentSprintId}
        teamId={state.currentTeamId}
        weekNumber={renderWeekNumber(state.currentSprintNumber, 1)}
        userId={props.currentUser.id}
      />
      <span>
        <b>Week {renderWeekNumber(state.currentSprintNumber, 2)}:</b>
      </span>
      <Interactions
        toggleModal={handleToggleModal1}
        weekNumber={renderWeekNumber(state.currentSprintNumber, 2)}
        disabled={
          renderWeekNumber(state.currentSprintNumber, 2) <
          state.currentWeekNumber
        }
      />
      <InteractionModal
        disabled={
          renderWeekNumber(state.currentSprintNumber, 2) <
          state.currentWeekNumber
        }
        toggle={() =>
          toggleModal1({
            ...interactionModal1,
            isOpen: !interactionModal1.isOpen
          })
        }
        interactionModal={interactionModal1}
        sprintId={state.currentSprintId}
        teamId={state.currentTeamId}
        weekNumber={renderWeekNumber(state.currentSprintNumber, 2)}
        currentWeekNumber={state.currentWeekNumber}
        userId={props.currentUser.id}
      />
      <Row>
        <Col lg="4">
          <Card>
            <CardHeader>
              <b>Calendar Info</b>
            </CardHeader>
            <ScrollCardBody>
              <p>
                Your interactions with your team and stakeholders for the
                current week are scheduled for execution via the interaction
                calendar. Decide if you want to implement your planned
                interactions for the week or make changes based on the status of
                your team. These are opportunities to coach, train and mentor
                the agile team members. Make sure for every Sprint you schedule
                the following:
              </p>
              <ol>
                <li>Sprint Planning</li>
                <li>Backlog Refinement</li>
                <li>Sprint Review</li>
                <li>Retrospective</li>
                <li>Daily Stand Up</li>
              </ol>

              <p>
                If you miss scheduling these, it might impact your team
                performance.
              </p>
            </ScrollCardBody>
          </Card>
        </Col>
        <Col>
          <InteractionTabs sprintId={state.currentSprintId} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Calendar;
