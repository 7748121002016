import React from "react";
import moment from "moment";
import { Chart } from "react-google-charts";
import { Row, Col } from "reactstrap";
import splLogo from "../../../../static/images/message/spl-logo.jpg";

const BurndownChart = ({ date, chartData, sprintLabel, user }) => {
  const chartOptions = {
    height: 530,
    legend: { position: "bottom" },
    colors: ["#32D378", "#5F8AD5"],
    backgroundColor: "#FFFFFF",
    lineWidth: 4,
    pointsVisible: false,
    chartArea: {
      width: "90%",
      height: "80%",
      top: "10",
      left: "80",
      right: "10"
    },
    hAxis: {
      title: "Sprint Days",
      titleTextStyle: {
        italic: false,
        fontSize: 12,
        color: "grey"
      },
      gridlines: {
        interval: 1,
        count: 10
      },
      textStyle: {
        fontSize: 12,
        color: "black"
      }
    },
    vAxis: {
      title: "Story Points",
      titleTextStyle: {
        italic: false,
        fontSize: 12,
        color: "grey"
      },
      textStyle: {
        fontSize: 12,
        color: "g,rey"
      },
      gridlines: {
        interval: 1,
        count: 10
      }
      // viewWindow: {
      //   max: maxValue,
      //   min: 0
      // }
    }
    // series: {
    //   1: { curveType: "function" }
    // }
  };
  const lineChartHeader = {
    fontSize: "0.8rem",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    marginLeft: "12%"
  };

  const renderDate = dateString => {
    if (dateString === null || dateString === "") {
      return <span />;
    }
    if (Date.parse(dateString) < 0) {
      return <span />;
    }
    const date = new Date(dateString);
    return <span>{moment(date).format("DD-MMM-YYYY")}</span>;
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
            <span>{user}</span>
            <br />
            <span>{sprintLabel}</span>
            <br />
            <span>{(renderDate(date))}</span>
          </div>
        </Col>
        <Col>
          <h2 style={lineChartHeader}>Sprint Burndown Chart</h2>
        </Col>
        <Col className="text-center text-md-right">
          <div style={{ margin: "10px" }}>
            <img src={splLogo} style={{ width: "20%" }} alt="spl logo" />
          </div>
        </Col>
      </Row>

      <Chart
        chartType="LineChart"
        loader={<div>Loading Chart...</div>}
        data={chartData}
        options={chartOptions}
      />
    </React.Fragment>
  );
};

export default BurndownChart;
