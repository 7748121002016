import React from "react";
import EndOfRunImage from "../../../../static/images/helpModal/EndOfRunReport.png";

const EndOfRun = () => (
  <div>
    <h3>End Of Run Report</h3>
    <p>
      At the end of a run, you have the ability to view a snapshot of your run. 
      You can view how quickly  the team was able to complete the minimal viable p
      roduct stories, what backlog items were completed in what Sprint. 
    </p>

    <img key="EndOfRun1" className="HelpModalimage" alt="End of Run Report screenshot" src={EndOfRunImage} />
    <br /><br />
  </div>
);

export default EndOfRun;
