import React from "react";
import ReportsImage from "../../../../static/images/helpModal/ScrumBoard.png";

const Reports = () => (
  <div>
    <h3>Reports</h3>
    <p>
      Reports provide you the ability to analyze and track the progress of your
      simulation run. Use these reports to make decisions to ensure that the
      product team is making progress. To access the reports, expand the Reports
      + menu on the left navigation panel.
    </p>
    <p style={{ textAlign: "center" }}>
      <img
        key="reports1"
        className="HelpModalimage"
        alt="Report Screenshot"
        src={ReportsImage}
      />
    </p>
    <br />
    <br />
  </div>
);

export default Reports;
