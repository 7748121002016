import React, { useContext, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { useQuery } from "@apollo/client";
import { INSTRUCTOR_CLASSES_QUERY } from "graphql/queries";
import Loading, { Error } from "components/Loading";
import { ProjectContext } from "GlobalState";
import StudentsTable from "./components/StudentsTable";

const Students = ({ id }) => {
  const [state, dispatch] = useContext(ProjectContext);

  // const { data, loading, error } = useQuery(GET_CLASSROOMS, {
  //   variables: { userId: id }
  // });

  const { loading, error, data: cdata } = useQuery(INSTRUCTOR_CLASSES_QUERY, {
    variables: { instructorId: id },
    onCompleted: async data => {
      if (
        data &&
        data.instructors.length > 0 &&
        data.instructors[0].classroom &&
        data.instructors[0].classroom.id
      ) {
        const payload = {
          index: 0
        };
        await dispatch({
          type: "SET_INSTRUCTOR_CLASS_INDEX",
          payload
        });
      }
    }
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  if (
    !cdata ||
    !cdata.instructors ||
    cdata.instructors.length < 1 ||
    state.instructorSelectedClassIndex < 0
  ) {
    return <span> No classrooms found </span>;
  }
  return (
    <Fragment>
      <Row>
        <Col>
          <StudentsTable
            instructorData={
              cdata.instructors[state.instructorSelectedClassIndex]
            }
            instructors={cdata.instructors}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Students;
