/* eslint-disable import/prefer-default-export */
import React from "react";

export const StorySummary = props => {
  if (!props || !props.properties || props.properties.length < 3) {
    return <div className="content"></div>;
  }
  const storyName = props.properties.find(p => p.itemKey === "storyName")
    .itemValue;
  const storyId = props.properties.find(p => p.itemKey === "storyId").itemValue;
  const required = props.properties.find(p => p.itemKey === "required")
    .itemValue;
  return (
    <h6>
      {required === "True" ? <span>*</span> : <span />} &nbsp;
      {storyId} {storyName}
    </h6>
  );
};
