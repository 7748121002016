import React from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import ClassroomForm from "./ClassroomForm";

const ClassroomModal = ({ toggle, isOpen, primaryInstructorId, data }) => {
  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} fade={false} centered>
      <ModalHeader toggle={toggle}>
        {data ? "Update" : "Create"} a Classroom
      </ModalHeader>
      <ModalBody>
        <ClassroomForm
          toggle={toggle}
          primaryInstructorId={primaryInstructorId}
          data={data}
        ></ClassroomForm>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClassroomModal;
