import React, { useContext } from "react";
import Select from "react-select";
import { ProjectContext } from "../GlobalState";

const SprintSelector = ({ controlName, defaultValue = "1" }) => {
  const [state, dispatch] = useContext(ProjectContext);
  return (
    <Select
      name={controlName}
      value={state.sprintOptions.find(s => s.value === defaultValue)}
      options={state.sprintOptions}
      onChange={option => {
        const payload = {
          option
        };
        dispatch({
          type: "UPDATE_SELECTED_SPRINT",
          payload
        });
      }}
    />
  );
};

export default SprintSelector;
