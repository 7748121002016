import React, { useContext } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { WEEKDAY_INTERACTIONS_QUERY } from "../../../graphql/queries";
import InteractionMenu from "./InteractionMenu";
import InteractionsList from "./InteractionsList";
import Loading, { Error } from "../../../components/Loading";
import { ProjectContext } from "../../../GlobalState";

const days = [
  {
    title: "Monday"
  },
  {
    title: "Tuesday"
  },
  {
    title: "Wednesday"
  },
  {
    title: "Thursday"
  },
  {
    title: "Friday"
  }
];

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
`;

const Interactions = props => {
  const [state] = useContext(ProjectContext);

  const { toggleModal, weekNumber, disabled } = props;

  const { loading, error, data } = useQuery(WEEKDAY_INTERACTIONS_QUERY, {
    variables: {
      teamId: state.currentTeamId,
      sprintId: state.currentSprintId,
      weekNumber
    },
    skip: !state
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  const { interactions } = data;
  return (
    <Row id={weekNumber}>
      {days.map(day => (
        <Col key={`day-${day.title}`}>
          <Card>
            <StyledCardHeader>
              <strong>{day.title}</strong>
              {disabled ? null : (
                <InteractionMenu
                  toggleModal={type => toggleModal({ type, day: day.title })}
                  disabled={disabled}
                />
              )}
            </StyledCardHeader>
            <CardBody>
              <InteractionsList
                teamId={state.currentTeamId}
                disabled={disabled}
                interactions={
                  interactions
                    ? interactions.filter(
                        item =>
                          item.weekDay === day.title.toUpperCase() &&
                          item.weekNumber === weekNumber
                      )
                    : ""
                }
                sprintId={state.currentSprintId}
                weekNumber={weekNumber}
                toggleModal={({ type, interData }) =>
                  toggleModal({
                    type,
                    day: day.title,
                    interData
                  })
                }
              />
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default Interactions;
