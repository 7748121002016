import gql from "graphql-tag";

export const INITIALIZE_TEAM_MUTATION = gql`
  mutation INITIALIZE_TEAM_MUTATION(
    $userId: Int!
    $classStudentRunId: Int!
    $updateUser: String
  ) {
    initializeTeam(
      userId: $userId
      classStudentRunId: $classStudentRunId
      updateUser: $updateUser
    ) {
      id
      currentStatus
      currentSprintId
      currentSprintNumber
      currentWeekNumber
      currentWeekDay
      currentRunDay
    }
  }
`;

export const DELETE_TEAM_MUTATION = gql`
  mutation deleteTeam($teamId: Int!, $classStudentRunId: Int!) {
    deleteTeam(teamId: $teamId, classStudentRunId: $classStudentRunId) {
      id
    }
  }
`;

export const UPDATE_USER_WELCOMESTATUS_MUTATION = gql`
  mutation UPDATE_USER_WELCOMESTATUS_MUTATION(
    $id: Int!
    $welcomeStatus: String
  ) {
    updateUser(id: $id, welcomeStatus: $welcomeStatus) {
      id
      welcomeStatus
    }
  }
`;

export const UPDATE_MESSAGE_MUTATION = gql`
  mutation UPDATE_MESSAGE_MUTATION($id: ID!) {
    updateMessage(id: $id, isRead: true) {
      id
    }
  }
`;

export const DELETE_INTERACTION_MUTATION = gql`
  mutation DELETE_INTERACTION_MUTATION($interactionId: Int!) {
    deleteInteraction(interactionId: $interactionId)
  }
`;

export const UPDATE_STORY_SPRINT = gql`
  mutation UPDATE_STORY_SPRINT(
    $teamId: ID!
    $id: ID!
    $sprintId: ID!
    $status: StoryStatusEnum
    $disconnectFromSprint: Boolean
  ) {
    updateStory(
      teamId: $teamId
      id: $id
      sprintId: $sprintId
      status: $status
      disconnectFromSprint: $disconnectFromSprint
    ) {
      id
    }
  }
`;

export const ASSIGN_WORKERTOSTORY_MUTATION = gql`
  mutation ASSIGN_WORKERTOSTORY_MUTATION($storyId: Int!, $workerId: Int!) {
    assignWorkerToStory(storyId: $storyId, workerId: $workerId) {
      workersLink {
        teamWorker {
          id
        }
      }
    }
  }
`;

export const REMOVE_WORKERFROMSTORY_MUTATION = gql`
  mutation REMOVE_WORKERFROMSTORY_MUTATION($storyId: Int!, $workerId: Int!) {
    removeWorkerFromStory(storyId: $storyId, workerId: $workerId)
  }
`;

export const UPDATE_STORYTOSPRINTLINK_MUTATION = gql`
  mutation UPDATE_STORYTOSPRINTLINK_MUTATION(
    $storyId: Int!
    $sprintId: Int!
    $action: String
  ) {
    updateStoryToSprintLink(
      storyId: $storyId
      sprintId: $sprintId
      action: $action
    ) {
      id
    }
  }
`;

export const CHANGE_STORY_PRIORITY = gql`
  mutation CHANGE_STORY_PRIORITY(
    $sprintId: Int!
    $sourceStoryId: Int!
    $destinationStoryId: Int!
  ) {
    changeStoryPriority(
      sprintId: $sprintId
      sourceStoryId: $sourceStoryId
      destinationStoryId: $destinationStoryId
    ) {
      id
      storyNumber
      storyName
      storyDescription
      storyPoints
      status
      sprintPriority
      requiredStory
      storyPercentComplete
      businessPoints
      totalPlannedEffort
      currentSprint {
        id
        sprintNumber
      }
      workersLink {
        teamWorker {
          id
          name
          skillNumber
        }
      }
    }
  }
`;

export const POST_RUNWEEK_UPDATES = gql`
  mutation POST_RUNWEEK_UPDATES($id: Int!, $status: String) {
    updateTeam(teamId: $id, status: $status) {
      id
    }
  }
`;
export const RUN_WEEK_MUTATION = gql`
  mutation RUN_WEEK_MUTATION($teamId: Int!, $conditionId: Int!) {
    runWeek(teamId: $teamId, conditionId: $conditionId) {
      team {
        id
        currentWeekDay
        currentWeekNumber
        currentRunDay
        currentSprintId
        currentSprintNumber
        currentStatus
      }
      returnStatus
      returnMessage
      conditionResponseList
      playScriptList
      condition {
        id
        conditionId
        hasFired
        silent
        condTitle
        condMessage
        condAnnouncement
        conditionEnabled
        responsePrompt1
        responsePrompt2
        responsePrompt3
        activeEntity
        dayFired
      }
      interimHistory {
        id
        weekNumber
        weekDay
        itemComponent
        itemProperties {
          id
          itemKey
          itemValue
        }
      }
    }
  }
`;

export const PROCESS_CONDITION_RESPONSE = gql`
  mutation PROCESS_CONDITION_RESPONSE(
    $teamId: Int!
    $conditionId: Int!
    $conditionResponse: Int!
  ) {
    processConditionResponse(
      teamId: $teamId
      conditionId: $conditionId
      conditionResponse: $conditionResponse
    ) {
      team {
        id
        currentWeekDay
      }
      returnStatus
      returnMessage
      conditionResponseList
      playScriptList
      condition {
        id
        conditionId
        hasFired
        silent
        condTitle
        condMessage
        condAnnouncement
        conditionEnabled
        responsePrompt1
        responsePrompt2
        responsePrompt3
        activeEntity
        dayFired
      }
      interimHistory {
        id
        weekNumber
        weekDay
        itemComponent
        itemProperties {
          id
          itemKey
          itemValue
        }
      }
    }
  }
`;
