// assistant
import Assistant from "./Assistant.png";
import ProjectAssistant from "./ProjectAssistant.png";

// non-team, company players
import BDSmith from "./BDSmith.png";
import Clem from "./Clem.png";
import DougKeeper from "./DougKeeper.png";
import FarleyGnu from "./FarleyGnu.png";
import Farley from "./Farley.png";
import MarkJarchow from "./MarkJarchow.png";
import HankHacker from "./HankHacker.png";
import JamieBurns from "./JamieBurns.png";
import KittyFrost from "./KittyFrost.png";
import MaryProbst from "./MaryProbst.png";
import OMSmith from "./OMSmith.png";
import Team from "./Team.png";
import MikeRussell from "./MikeRussell.png";
import SailBoat from "./SailBoat.png";
import LouisStyles from "./LouisStyles.jpg";
// team workers
import BarbaraElo from "./BarbaraElo.png";
import FredSaville from "./FredSaville.png";
import JumaGadach from "./JumaGadach.png";
import KamalAmin from "./KamalAmin.png";
import KatinaMalke from "./KatinaMalke.png";
import MariaTidwell from "./MariaTidwell.png";
import NickZimmerman from "./NickZimmerman.png";
import RusselReit from "./RusselReit.png";
import SladiPetrov from "./SladiPetrov.png";
import YeeLee from "./YeeLee.png";

export default {
  Assistant,
  ProjectAssistant,
  BDSmith,
  Clem,
  DougKeeper,
  FarleyGnu,
  Farley,
  MarkJarchow,
  HankHacker,
  JamieBurns,
  KittyFrost,
  LouisStyles,
  MaryProbst,
  OMSmith,
  Team,
  BarbaraElo,
  FredSaville,
  JumaGadach,
  MikeRussell,
  KamalAmin,
  KatinaMalke,
  MariaTidwell,
  NickZimmerman,
  RusselReit,
  SladiPetrov,
  YeeLee,
  SailBoat
};
