/* eslint-disable import/prefer-default-export */
import React from "react";

const styles = {
  textStyle: {
    fontWeight: "normal",
    color: "red",
    fontSize: 14,
    fontFamily: "Arial"
  }
};
export const MissedConference = props => {
  const eventName = "1-on-1 Session";
  const worker = props.properties.find(p => p.itemKey === "worker").itemValue;

  const reason = props.properties.find(p => p.itemKey === "reason").itemValue;
  const outcome = props.properties.find(p => p.itemKey === "outcome").itemValue;
  const topic = props.properties.find(p => p.itemKey === "topic").itemValue;

  return (
    <div style={{ display: "flex", justifyContent: "left" }}>
      <span style={styles.textStyle}>
        {worker} was {reason} and missed the {topic} {eventName}. The event{" "}
        {outcome}
        <br />
        <br />
      </span>
    </div>
  );
};
