/* eslint-disable no-restricted-syntax */
import React from "react";
import {
  ReportHeader,
  Absent,
  Weekday,
  Condition,
  SectionHeader,
  MissedRequiredMeetings,
  PlayScript,
  // SectionTitle,
  //   StoryStatus,
  //   Team,
  // WeeklyResourceHours,
  //   StoryHours,
  //   StoryAssignments,
  //   NoWork,c
  Meeting,
  Conference,
  MissedInteraction,
  MissedConference,
  MissedMeeting,

  //   EndReport,
  RequiredStorySummary,
  TeamComplete,
  StoryAdded,
  StoryComplete,
  StoriesSummary,
  StorySummary,
  SprintComplete,
  IndvTraining,
  TeamTraining,
  IndvReward,
  TeamReward,
  //   PlayScript,
  //   VolOvertime,
  Reassign
  //   Footnote
} from "../components/HistoryItems";

const parseHistoryToComponentList = props => {
  const children = [];
  if (!props || !props.componentList) {
    return children;
  }
  let i = 1;
  for (const comp of props.componentList) {
    if (
      comp.itemComponent === "REPORTHDR" &&
      props.parentProcess === "HISTORY"
    ) {
      children.push(
        <ReportHeader
          key={`rptHead-${i.toString()}`}
          weekNumber={comp.weekNumber}
          properties={comp.itemProperties}
        />
      );
    } else if (
      comp.itemComponent === "WEEKDAY" &&
      props.parentProcess === "HISTORY"
    ) {
      children.push(
        <Weekday key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "ABSENT") {
      children.push(
        <Absent key={`Absent${(i += 1)}`} properties={comp.itemProperties} />
      );
      //
    } else if (comp.itemComponent === "REQUIREDMTGMISSING") {
      children.push(
        <MissedRequiredMeetings
          key={`MissedRequiredMeetings${(i += 1)}`}
          properties={comp.itemProperties}
        />
      );
    } else if (comp.itemComponent === "REQUIREDSTORYSUMMARY") {
      children.push(
        <RequiredStorySummary
          key={`RequiredStorySummary${(i += 1)}`}
          properties={comp.itemProperties}
        />
      );
    } else if (comp.itemComponent === "PROJCOMPLETE") {
      children.push(
        <TeamComplete key={(i += 1)} properties={comp.itemProperties} />
      );
      //   } else if (comp.itemComponent=== "endreport") {
      //     children.push(<EndReport key={i += 1} properties={comp.Properties} />);
      //   } else if (comp.itemComponent=== "footnote") {
      //     children.push(<Footnote key={i += 1} properties={comp.Properties} />);
    } else if (
      comp.itemComponent === "PLAYSCRIPT" &&
      props.parentProcess === "HISTORY"
    ) {
      children.push(
        <PlayScript key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (
      comp.itemComponent === "CONDITION" &&
      props.parentProcess === "HISTORY"
    ) {
      children.push(
        <Condition key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "CONFERENCE") {
      children.push(
        <Conference key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "MEETING") {
      children.push(
        <Meeting key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "INDVREWARD") {
      children.push(
        <IndvReward key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "TEAMREWARD") {
      children.push(
        <TeamReward key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "INDVTRAINING") {
      children.push(
        <IndvTraining key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "TEAMTRAINING") {
      children.push(
        <TeamTraining key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "MISSEDTRAINING") {
      children.push(
        <MissedInteraction key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "MISSEDCONF") {
      children.push(
        <MissedConference key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "MISSEDMTG") {
      children.push(
        <MissedMeeting key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "REASSIGN") {
      children.push(
        <Reassign key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "STORYADDED") {
      children.push(
        <StoryAdded key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (
      comp.itemComponent === "WEEKLYSUMMARY" &&
      props.parentProcess === "HISTORY"
    ) {
      children.push(<SectionHeader key={(i += 1)} headerText="End of Week" />);
    } else if (comp.itemComponent === "STORYCOMPLETE") {
      children.push(
        <StoryComplete key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "STORIESSUMMARY") {
      children.push(
        <StoriesSummary key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "STORYSUMMARY") {
      children.push(
        <StorySummary key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (comp.itemComponent === "SPRINTCOMPLETE") {
      children.push(
        <SprintComplete key={(i += 1)} properties={comp.itemProperties} />
      );
    } else if (props.parent === "HISTORY") {
      console.log("missing component: ", comp.itemComponent);
    }
  }
  return children;
};

export default parseHistoryToComponentList;
