import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  NavLink,
  Collapse,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import HistoryInfo from "./components/HistoryInfo";
import ChartInfo from "./components/ChartInfo";
import CardHeader from "../../components/styled/CardHeader";
import Nav from "../../components/styled/Nav";

import BurndownChart from "./components/BurndownItems/BurndownChart";
import VelocityTrendChart from "./components/VelocityTrendChart";
import CumulativeFlowDiagram from "./components/CumulativeFlowDiagram";
import { HistoryWeek } from "./components/HistoryItems/HistoryWeek";
// unused import CapacityPlanningDetails from "./components/CapacityPlanning";

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;
const StyledCollapse = styled(Collapse)`
  max-height: 231px;
  overflow-y: auto;
`;
const StyledListGroupItem = styled(ListGroupItem)`
  padding: 0.45rem 1.25rem !important &.active {
    background-color: #c1c1c1 !important;
    border-color: #c1c1c1 !important;
  }
  .list-group-item.active
    .list-group-item.active:hover
    .list-group-item.active:focus {
    z-index: 2;
    color: #ffffff;
    background-color: #ffffff;
    border-color: #2196f3;
  }
`;

class ReportTabs extends Component {
  state = {
    activeReport: "history",
    activeWeek: this.props.activeWeek,
    activeSprint: this.props.activeSprint,
    activeSprintId: "",
    collapse: false,
    HistoryCardOpen: false,
    ChartCardOpen: false,
    activeTab: 1,
    titleEl: document.title
  };

  getWeeks = weekNumber => {
    // at this point, weekNumber is the current week which is in planning.
    // we only have reports for prior weeks so subtract 1.
    const weeks = [];
    for (let i = 1; i <= weekNumber - 1; i += 1) {
      weeks.push({ id: i, name: `Week ${i}` });
    }
    return weeks;
  };

  getSprints = sprintNumber => {
    // at this point, weekNumber is the current week which is in planning.
    // we only have reports for prior weeks so subtract 1.
    const sprints = [];
    for (let i = 1; i <= sprintNumber - 1; i += 1) {
      sprints.push({ id: i, name: `Sprint ${i}` });
    }
    return sprints;
  };

  setActiveWeek(id) {
    this.setState({
      activeWeek: id,
      activeReport: "history",
      activeSprint: 0
    });
  }

  setActiveSprint(sprintNumber, id) {
    this.setState({
      activeReport: "burndown",
      activeSprint: sprintNumber,
      activeSprintId: id,
      activeWeek: 0,
      activeTab: sprintNumber
    });
  }

  setActiveReport(report) {
    this.setState({
      activeReport: report
    });
  }

  toggleHistoryCard = () => {
    this.setState(prevState => ({
      HistoryCardOpen: !prevState.HistoryCardOpen
    }));
  };

  toggleChartCard = () => {
    this.setState(prevState => ({
      ChartCardOpen: !prevState.ChartCardOpen
    }));
  };

  toggleNavLink = activeReport => {
    this.setState({ activeReport });
  };

  sprintToggleEvent = activeTeam => {
    this.closeCollapse();
    this.toggleNavLink("burndown");
    this.toggle();

    this.setState({
      activeSprintId: activeTeam.currentSprintId,
      activeWeek: activeTeam.currentWeekNumber,
      activeSprint: this.props.activeSprint
    });
  };

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  afterPrint() {
    this.document.title = this.state.titleEl;
  }

  beforePrint(newTitle) {
    this.document.title = newTitle;
  }

  closeCollapse() {
    this.setState({ collapse: false });
  }

  renderSprintListItems(sprints) {
    const returnArray = [];
    for (let i = 0; i < sprints.length; i += 1) {
      const sprint = sprints[i];
      returnArray.push(
        <StyledListGroupItem
          key={sprint.id}
          className={
            sprint.sprintNumber === this.state.activeTab ? "active" : ""
          }
          tag="a"
          href="#"
          action
          onClick={() => this.setActiveSprint(sprint.sprintNumber, sprint.id)}
        >
          Sprint: {sprint.sprintNumber}
        </StyledListGroupItem>
      );
    }

    return returnArray;
  }

  render() {
    const { activeTeam } = this.props; // this is the project context state
    return (
      <Fragment>
        <Row>
          <Col lg="3">
            <Nav vertical pills>
              <NavItem>
                <Card>
                  <CardHeader className="p-0">
                    <NavLink
                      // active={this.state.activeReport === "history"}
                      onClick={() => {
                        this.toggleNavLink("history");
                        this.toggle();
                        this.closeCollapse();
                        this.setState({
                          activeWeek: this.props.activeWeek,
                          activeSprint: 0
                        });
                      }}
                      href="#"
                    >
                      History
                    </NavLink>
                  </CardHeader>

                  <div>
                    <StyledCollapse isOpen={!this.state.collapse}>
                      <CardBody className="p-0">
                        <ListGroup>
                          {this.getWeeks(activeTeam.currentWeekNumber)
                            .map(({ id, name }) => (
                              <ListGroupItem
                                key={id}
                                className={
                                  this.state.activeWeek === id ? "active" : ""
                                }
                                // active={this.state.activeWeek}
                                tag="a"
                                href="#"
                                action
                                onClick={() => this.setActiveWeek(id)}
                              >
                                {name}
                              </ListGroupItem>
                            ))
                            .reverse()}
                        </ListGroup>
                      </CardBody>
                    </StyledCollapse>
                  </div>
                </Card>
              </NavItem>
              {/* Lori    */}
              <NavItem>
                <Card>
                  <CardHeader className="p-0">
                    <NavLink
                      // active={this.state.activeReport === "burndown"}
                      onClick={() => this.sprintToggleEvent(activeTeam)}
                      href="#"
                    >
                      Burndown Charts
                    </NavLink>
                  </CardHeader>
                  {/* <CardBody className="p-0">
                    <div>
                      <StyledCollapse isOpen={this.state.collapse}>
                        <ListGroup>
                          {activeTeam &&
                            activeTeam.sprints &&
                            this.renderSprintListItems(activeTeam.sprints)}
                        </ListGroup>
                      </StyledCollapse>
                    </div>
                  </CardBody> */}
                </Card>
              </NavItem>

              <NavItem>
                <Card>
                  <CardHeader className="p-0">
                    <NavLink
                      // active={this.state.activeReport === "burndown"}
                      onClick={() => this.setActiveReport("velocity")}
                      href="#"
                    >
                      Other Reports
                    </NavLink>
                  </CardHeader>
                  <CardBody className="p-0">
                    <ListGroup>
                      <StyledListGroupItem
                        key="vt"
                        className={
                          this.state.activeRerport === "velocity"
                            ? "active"
                            : ""
                        }
                        tag="a"
                        href="#"
                        action
                        onClick={() => this.setActiveReport("velocity")}
                      >
                        Velocity Trend
                      </StyledListGroupItem>
                      <StyledListGroupItem
                        key="cfc"
                        className={
                          this.state.activeReport === "CFD" ? "active" : ""
                        }
                        tag="a"
                        href="#"
                        action
                        onClick={() => this.setActiveReport("CFD")}
                      >
                        Cumulative Flow Diagram
                      </StyledListGroupItem>
                      {/* <StyledListGroupItem
                        key="cp"
                        tag="a"
                        href="#"
                        action
                        onClick={() => this.setActiveReport("capacity")}
                      >
                        Capacity Planning
                        </StyledListGroupItem> */}
                    </ListGroup>
                  </CardBody>
                </Card>
              </NavItem>
            </Nav>
          </Col>
          <Col lg="9">
            {this.state.activeReport === "history" && (
              <Fragment>
                <HistoryInfo
                  cardOpen={this.state.HistoryCardOpen}
                  toggleCard={this.toggleHistoryCard}
                />
                <Card>
                  <CardHeader>
                    <b>Team History Timeline</b>
                    <ReactToPrint
                      trigger={() => (
                        <Button color="secondary">
                          <i className="far fa-file-pdf" /> Print
                        </Button>
                      )}
                      content={() => this.HistoryRef}
                      onBeforePrint={() =>
                        this.beforePrint(
                          `History ReportWeek ${this.state.activeWeek}`
                        )
                      }
                      onAfterPrint={() => this.afterPrint()}
                    />
                  </CardHeader>

                  <CardBody>
                    <PrintDiv
                      id={`h${activeTeam.currentWeekNumber.toString()}`}
                      ref={el => {
                        this.HistoryRef = el;
                      }}
                    >
                      {this.props.history.length === 0 ? (
                        <p>No History available yet</p>
                      ) : (
                        <HistoryWeek
                          key={this.state.activeWeek}
                          weekNumber={this.state.activeWeek}
                          componentList={this.props.history
                            .filter(h => h.weekNumber === this.state.activeWeek)
                            .sort((a, b) => a.id - b.id)}
                        />
                      )}
                    </PrintDiv>
                  </CardBody>
                </Card>
              </Fragment>
            )}
            {this.state.activeReport === "burndown" && (
              <Fragment>
                <ChartInfo
                  cardOpen={this.state.ChartCardOpen}
                  toggleCard={this.toggleChartCard}
                />
                <Card>
                  <CardHeader>
                    <b>Sprint Burndown</b>
                    <ReactToPrint
                      trigger={() => (
                        <Button color="secondary">
                          <i className="far fa-file-pdf" /> Print
                        </Button>
                      )}
                      content={() => this.ChartRef}
                      onBeforePrint={() =>
                        this.beforePrint(
                          `Burndown Chart Sprint ${this.state.activeSprint}`
                        )
                      }
                      onAfterPrint={() => this.afterPrint()}
                    />
                  </CardHeader>

                  <CardBody>
                    <PrintDiv
                      id={`h${activeTeam.currentSprintNumber.toString()}`}
                      ref={el => {
                        this.ChartRef = el;
                      }}
                    >
                      {/* {activeTeam.sprints.length === 0 ? (
                        <p>No Chart available yet</p>
                      ) : ( */}
                      <BurndownChart
                        teamId={activeTeam.currentTeamId}
                        sprintId={this.state.activeSprintId}
                      />
                      {/* )} */}
                    </PrintDiv>
                  </CardBody>
                </Card>
              </Fragment>
            )}
            {this.state.activeReport === "velocity" && (
              <Card>
                <CardHeader>
                  <b>Velocity Trend</b>
                  <ReactToPrint
                    trigger={() => (
                      <Button color="secondary">
                        <i className="far fa-file-pdf" /> Print
                      </Button>
                    )}
                    content={() => this.ChartRef}
                    onBeforePrint={() => this.beforePrint("Velocity Trend")}
                    onAfterPrint={() => this.afterPrint()}
                  />
                </CardHeader>

                <CardBody>
                  <PrintDiv
                    id={`h${activeTeam.currentSprintNumber.toString()}`}
                    ref={el => {
                      this.ChartRef = el;
                    }}
                  >
                    {/* {activeTeam.sprints.length === 0 ? (
                      <p>No Chart available yet</p>
                    ) : ( */}
                    <VelocityTrendChart teamId={activeTeam.currentTeamId} />
                    {/* )} */}
                  </PrintDiv>
                </CardBody>
              </Card>
            )}
            {this.state.activeReport === "CFD" && (
              <Card>
                <CardHeader>
                  <b>Cumulative Flow Diagram</b>
                  <ReactToPrint
                    trigger={() => (
                      <Button color="secondary">
                        <i className="far fa-file-pdf" /> Print
                      </Button>
                    )}
                    content={() => this.ChartRef}
                    onBeforePrint={() =>
                      this.beforePrint("Cummulative Flow Diagram")
                    }
                    onAfterPrint={() => this.afterPrint()}
                  />
                </CardHeader>

                <CardBody>
                  <PrintDiv
                    id={`h${activeTeam.currentSprintNumber.toString()}`}
                    ref={el => {
                      this.ChartRef = el;
                    }}
                  >
                    <CumulativeFlowDiagram teamId={activeTeam.currentTeamId} />
                  </PrintDiv>
                </CardBody>
              </Card>
            )}
            {/* {this.state.activeReport === "capacity" && (
              <Card>
                <CardHeader>
                  <b>Capacity Planning</b>
                  <ReactToPrint
                    trigger={() => (
                      <Button color="secondary">
                        <i className="far fa-file-pdf" /> Print
                      </Button>
                    )}
                    content={() => this.ChartRef}
                  />
                </CardHeader>

                <CardBody>
                  <PrintDiv
                    id={`h${activeTeam.currentSprintNumber.toString()}`}
                    ref={el => {
                      this.ChartRef = el;
                    }}
                  >
                    <CapacityPlanningDetails teamId={activeTeam.id} />
                  </PrintDiv>
                </CardBody>
              </Card>
            )} */}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default ReportTabs;
