import React from "react";
import { getDisplayName } from "../../../../utilities/utils";

/*
 * 0 = meeting topic
 */
// eslint-disable-next-line import/prefer-default-export
export const Meeting = props => {
  const topic = props.properties.find(s => s.itemKey === "topic").itemValue;
  return (
    <div className="content">
      <p>
        <span role="img" aria-label="handshake">
          🤝{" "}
        </span>{" "}
        A <strong>{getDisplayName(topic)}</strong> scrum event was held.
      </p>
    </div>
  );
};
