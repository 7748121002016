/* eslint-disable import/prefer-default-export */
import React from "react";
import parseHistoryToComponentList from "../../functions/parseHistoryToComponentList";

export const HistoryWeek = props => {
  const newProps = { ...props };

  newProps.parentProcess = "HISTORY";
  return (
    <div
      key={`history${props.weekNumber.toString()}`}
      id={`history${props.weekNumber.toString()}`}
    >
      {" "}
      {parseHistoryToComponentList(newProps)}
    </div>
  );
};
