import React from "react";
import SettingsImage from "../../../static/images/helpModal/Settings.png";

const Settings = () => (
  <div>
    <h3>Settings</h3>
    <p>
      You can select your instructor and assign yourself to a class using the Settings page. 
      As part of the registration and welcome screens, you should already have selected your 
      instructor and class. If you made an error during the registration process or assigned 
      yourself to another class or instructor, you can reassign yourself on this page.
    </p>

    <img key="Settings1" className="HelpModalimage" alt="Settings screenshot" src={SettingsImage} />
    <br /><br />
  </div>
);

export default Settings;
