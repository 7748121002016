import React, { useReducer } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSquare,
  faCheckSquare,
  faEdit
} from "@fortawesome/free-regular-svg-icons";
import {
  faLifeRing,
  faUsers,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import UserDataInjector from "components/UserDataInjector";
import auth from "./Auth";
import { ProjectContext, initialState, reducer } from "./GlobalState";

library.add(faSquare, faCheckSquare, faLifeRing, faUsers, faEdit, faTrash);

const App = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <div className="app">
      <ProjectContext.Provider value={[state, dispatch]}>
        <UserDataInjector auth={auth} location={props.location} />
      </ProjectContext.Provider>
    </div>
  );
};

export default App;
