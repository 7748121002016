import React from "react";
import SprintBurndownImage from "../../../../static/images/helpModal/SprintBurndown.png";

const BurndownChart = () => (
  <div>
    <h3>Burndown Chart</h3>
    <p>
      The Burndown chart represents the rate at which the work is being
      completed and how much remains to be done in terms of story points. It
      shows how quickly the team is burning through the backlog items for the
      Sprint and if the team is on schedule to deliver within the desired
      schedule. Only accepted stories are counted towards the progress.
    </p>

    <img
      key="SprintBurndown1"
      className="HelpModalimage"
      alt="Sprint Burndown Chart screenshot"
      src={SprintBurndownImage}
    />
    <br />
    <br />
  </div>
);

export default BurndownChart;
