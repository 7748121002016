import React from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { CardBody } from "reactstrap";
import DraggableStory from "./DraggableStory";

import StyledStoryContainer from "../../../components/styled/StyledStoryContainer";
import StoryContainerHeader from "./StoryContainerHeader";

const StyledStoryList = styled.div`
  padding: 8;
`;

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? "2px dashed #eff0f1" : "2px dashed transparent",
  padding: "8px"
});

// export default class StoryContainerWithDnD extends React.Component {
const StoryContainerWithDnD = props => {
  const { projectContext } = props;

  return (
    <StyledStoryContainer>
      <StoryContainerHeader title={props.title} stories={props.stories} />
      <CardBody>
        <Droppable droppableId={props.listId}>
          {(provided, snapshot) => (
            <StyledStoryList
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {props.stories.map((story, index) => (
                <DraggableStory
                  story={story}
                  index={index}
                  key={story.id}
                  projectContext={projectContext}
                  refetch={props.refetch}
                />
              ))}
              {provided.placeholder}
            </StyledStoryList>
          )}
        </Droppable>
      </CardBody>
    </StyledStoryContainer>
  );
};

export default StoryContainerWithDnD;
