import React from "react";
import RunSprintImage from "../../../static/images/helpModal/RunSprint.png";

const RunSprint = () => (
  <div>
    <h3>Run Sprint</h3>
    <p>
      This is where you execute a Sprint. As you run the Sprint, you are presented with a number 
      of scenarios that simulate real-life agile team challenges. How you respond to these 
      scenarios will determine the outcome of the simulation. Your decisions will impact the 
      team morale, productivity and energy.
    </p>
    <p>
      The Team Progress will display the daily team activities. If you navigate away from the 
      Run Sprint page or if you log out, the simulation will retain the state of your run. 
      When you log back in, you can pick up where you left off.
    </p>
    <img key="RunSprint1" className="HelpModalimage" alt="Run Sprint screenshot" src={RunSprintImage} />
    <br/><br/>
  </div>
);

export default RunSprint;
