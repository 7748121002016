import React from "react";
import VelocityTrendImage from "../../../../static/images/helpModal/VelocityTrend.png";

const VelocityTrend = () => (
  <div>
    <h3>Velocity Trend Report</h3>
    <p>
      This report provides a view into the trend for velocity over time. 
      Only completed backlog items are counted towards the sprint velocity. 
    </p>
    <img key="VelocityTrend1" className="HelpModalimage" alt="Velocity Trend Chart screenshot" src={VelocityTrendImage} />
    <br /><br />
  </div>
);

export default VelocityTrend;
