import React from "react";
// TODO this should contain hardcoded data.
// these values are kept in the database
const TeamRewards = () => (
  <div className="box-content">
    <div className="text">
      <table className="table table-hover" style={{ maxWidth: "500px" }}>
        <thead>
          <tr>
            <th>Recognition</th>
            <th className="text-center">
              Cost to project <br /> (per team member)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-RowHeading">
            <td>Company box seats</td>
            <td className="text-right">$1000</td>
          </tr>

          <tr>
            <td>Happy hour party</td>
            <td className="text-right">$50</td>
          </tr>
          <tr>
            <td>$50 gift cards</td>
            <td className="text-right">$50</td>
          </tr>
          <tr>
            <td>Team family picnic </td>
            <td className="text-right">$35</td>
          </tr>
          <tr>
            <td>Company logo item</td>
            <td className="text-right">$25</td>
          </tr>
          <tr>
            <td>Pizza lunch</td>
            <td className="text-right">$20</td>
          </tr>
          {/* <tr>
            <td>Afternoon surprise treats</td>
            <td className="text-right">$15</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  </div>
);

export default TeamRewards;
