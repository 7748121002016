import React, { useEffect, useState, useContext } from "react";
import gql from "graphql-tag";
import styled from "styled-components";

import { useQuery, useMutation } from "@apollo/client";
import { store } from "react-notifications-component";
import { GET_UNREAD_CHAT_MESSAGES } from "../../graphql/queries";
import "react-notifications-component/dist/theme.css";
import ChatMessage from "./ChatMessage";
import ChatModal from "./ChatModal";
import { ProjectContext } from "../../GlobalState";

export const MARK_MESSAGE_AS_READ_MUTATION = gql`
  mutation MARK_MESSAGE_AS_READ_MUTATION($messageId: Int!) {
    markMessageAsRead(messageId: $messageId)
  }
`;

const Notifications = styled.div`
   {
    background: white;
    padding: 10px 0px 0px 12px;
    font-weight: bold;
    border-bottom: 1px solid #a4b7c1;
  }
`;

const chatNotifications = [];
// TODO fix the unread messages query = problem with the week number
const ChatMessageNotification = props => {
  const [state, dispatch] = useContext(ProjectContext);
  console.log(state);
  const { loading, error, data } = useQuery(GET_UNREAD_CHAT_MESSAGES, {
    variables: {
      teamId: state.currentTeamId,
      releaseWeek: state.currentWeekNumber
    }
  });
  const [markMessageAsReadMutation] = useMutation(
    MARK_MESSAGE_AS_READ_MUTATION
  );

  const [whichModal, setWhichModal] = useState("");

  const markMessageAsRead = id => {
    const notification = chatNotifications.find(
      value => value.notificationId === id
    );
    if (notification !== undefined) {
      markMessageAsReadMutation({
        variables: { messageId: notification.messageId }
      });
    }
  };

  const showNotification = message => {
    const payload = {
      option: true
    };
    dispatch({
      type: "HIGHLIGHT_CHAT",
      payload
    });

    const notificationId = store.addNotification({
      id: `chatMessage${message.id}`,
      container: "bottom-right",
      type: "default",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "bounceOut"],
      dismiss: {
        duration: 10000000,
        showIcon: true
      },
      width: 300,
      content: (
        <div>
          <Notifications>
            <span>Notifications</span>
            <span className="fas fa-times notification-close"></span>
          </Notifications>
          <div
            onClick={() => {
              setWhichModal("chatModal");
              for (const m of data.unReadMessages) {
                m.isRead = true;
              }
            }}
          >
            <ChatMessage key={message.id} width={230} message={message} />
          </div>
        </div>
      ),
      onRemoval: markMessageAsRead
    });

    chatNotifications.push({
      notificationId,
      messageId: message.id
    });
  };
  useEffect(() => {
    if (!loading && !error && data) {
      if (data.unReadMessages.length > 0) {
        const lastMessage = data.unReadMessages[data.unReadMessages.length - 1];
        if (lastMessage.isRead === false) showNotification(lastMessage);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div>
      {state.hasActiveTeam && whichModal === "chatModal" && (
        <ChatModal
          toggleModal={() => setWhichModal("")}
          currentUser={props.currentUser}
        />
      )}
    </div>
  );
};

export default ChatMessageNotification;
