/* eslint-disable import/prefer-default-export */
import React from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import Worker from "../../../../components/Worker/Worker";

export const PlayScript = props => {
  const tempWorkerData = {
    name: "Assistant"
  };

  return (
    <Card>
      <CardBody>
        {" "}
        <div className="resource-Profile">
          <Worker imageOnly workerData={tempWorkerData} />
        </div>{" "}
        <div className="resource-Body">
          <p>Assistant</p>
        </div>
        <div className="report-Item_Content">
          <p>{props.properties[0].itemValue}</p>
        </div>
      </CardBody>
      <CardFooter>
        <span />
      </CardFooter>
    </Card>
  );
};
