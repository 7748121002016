import styled from "styled-components";

const StyledStoryComponent = styled.div`
  background-color: #fff;
  border: 1px solid #c2cfd6;
  box-shadow: 0 1px 1px rgba(9, 45, 66, 0.15);
  cursor: pointer !important;
  margin-bottom: 10px;
  position: relative;

  &:hover {
    background-color: #fafafa;
  }

  &.dragDisabled {
    box-shadow: none;
    background-color: #f0f3f5;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .isRequired {
    position: absolute;
    top: -6px;
    right: 5px;
    width: 12px;
    display: block;
  }

  .card-body {
    padding: 10px;
  }

  .card-footer {
    padding: 5px 10px;
    display: flex;
    align-items: center;

    > div {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: auto;
      }
    }
  }
`;

export default StyledStoryComponent;
