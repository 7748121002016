import React from "react";
import { Progress } from "reactstrap";

type Props = {
  effectiveness: Number
};

export class EffectivenessImage extends React.Component {
  props: Props;

  render() {
    const effectiveness = parseInt(this.props.effectiveness, 10); // Out of 100%

    let progressbarStyle;
    if (effectiveness >= 75) {
      progressbarStyle = "info";
    } else if (effectiveness < 75 && effectiveness >= 45) {
      progressbarStyle = "success";
    } else {
      progressbarStyle = "danger";
    }

    return (
      <div>
        <Progress style={progressbarStyle} tag={effectiveness} value={`${effectiveness}%`} />
      </div>
    );
  }
}
