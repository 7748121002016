import React, { useRef } from "react";
import styled from "styled-components";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const StyledBootstrapTable = styled(BootstrapTable)`
  .react-bs-table-bordered {
    border-radius: 0;
    border-bottom: 0;
  }
  .react-bs-container-header,
  .react-bs-container-body {
    min-width: 1000px;
  }
  .table{
    width: 50%;
  }
  .table-bordered thead th,
  .table-bordered thead td {
    font-size: 0.75rem;
    vertical-align: top !important;
  }
  .table-bordered thead th {
		white-space: normal;
	}
  td {
    vertical-align: middle;
  }
  th .filter {
    padding: 0.175rem 0.35rem;
    font-size: 0.75rem;
  }
  th.sort-column .fa {
    margin-top: 0 !important;
  }
  .btn {
    padding: 0.275rem 0.75rem;
    line-height: initial;
    font-weight: 600;
  }
  .page-item.active .page-link,
  .pagination-datatables li.active .page-link,
  .pagination li.active .page-link,
  .page-item.active .pagination-datatables li a,
  .pagination-datatables li .page-item.active a,
  .pagination-datatables li.active a,
  .page-item.active .pagination li a,
  .pagination li .page-item.active a,
  .pagination li.active a {
    background-color: #5378AD;
    border-color: #5378AD;
  }
  .page-link,
  .pagination-datatables li a,
  .pagination li a {
    color: black;
    &:hover {
      black;
    }
  }
`;

const SprintWiseTable = props => {
  const tableRef = useRef();
  return (
    <StyledBootstrapTable
      ref={tableRef}
      data={props.sprintwisePerformance}
      hover
      condensed
      version="4"
      striped
    >
      <TableHeaderColumn
        isKey
        dataField="sprint"
        dataAlign="center"
        width="20%"
      >
        Sprint
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="completedStories"
        dataAlign="center"
        width="40%"
      >
        Number of stories completed
      </TableHeaderColumn>
      <TableHeaderColumn dataField="velocity" dataAlign="center" width="30%">
        Velocity *(Story Points)*
      </TableHeaderColumn>
    </StyledBootstrapTable>
  );
};

export default SprintWiseTable;
