import React, { useMemo, useContext, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { Col, Row, Button, Card, CardHeader, CardBody } from "reactstrap";
import ReactToPrint from "react-to-print";
import { getCurrentDate, useAsyncState } from "../../utilities/utils";
import Loading, { Error } from "../../components/Loading";
import { GET_BURNDOWN_QUERY } from "../../graphql/queries";
import SprintSelector from "../../components/SprintSelector";
import BurndownChart from "./components/BurndownItems/BurndownChart";
import { ProjectContext } from "../../GlobalState";
import UserHelper from "../../UserHelper";

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;

const SprintBurndown = ({ currentUser }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const today = new Date();
  const date = `${today.getFullYear()}-${today.getMonth() +
    1}-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

  useEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      const payload = {
        option: {}
      };
      dispatch({
        type: "UPDATE_SELECTED_SPRINT_IN_DROPDOWN",
        payload
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [elementRef, setElementRef] = useAsyncState(null);
  const sprintId = state.selectedDropdownValueInSprint.value
    ? state.selectedSprintOption.value
    : String(state.currentSprintId);

  const { loading, error, data } = useQuery(GET_BURNDOWN_QUERY, {
    variables: {
      teamId: state.currentTeamId,
      sprintId: parseInt(sprintId, 10)
    },
    skip:
      !state.currentTeamId ||
      state.currentTeamId === 0 ||
      !state.selectedSprintOption,
    fetchPolicy: "network-only"
  });

  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }
    const cd = [];
    const sprints = data.burndownReport.sprintDays;
    const labels = ["Day", "planned", "actual"];
    cd.push(labels);

    sprints.forEach((_, index) => {
      const sprintData = [
        index,
        data.burndownReport.pointsDataset[0].data[index],
        data.burndownReport.pointsDataset[1].data[index]
      ];
      cd.push(sprintData);
    });
    return cd;
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  // eslint-disable-next-line no-unused-vars
  let documentTitle = null;

  const afterPrint = () => {
    documentTitle = document.title;
  };

  const beforePrint = newTitle => {
    documentTitle = newTitle;
  };
  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col md="3">
              <SprintSelector
                controlName="sprintNumber"
                defaultValue={sprintId}
              />
            </Col>
            <Col>
              <span className="float-sm-right">
                <ReactToPrint
                  trigger={() => (
                    <Button color="secondary">
                      <i className="far fa-file-pdf" /> Print
                    </Button>
                  )}
                  content={() => elementRef}
                  onBeforePrint={() =>
                    beforePrint(
                      `Burndown Chart Sprint ${state.selectedSprintOption}`
                    )
                  }
                  onAfterPrint={() => afterPrint()}
                  documentTitle={`${("Burndown Chart", "_")}${
                    state.selectedSprintOption.label
                  }_${UserHelper.firstName(
                    currentUser,
                    state
                  )}${UserHelper.lastName(
                    currentUser,
                    state
                  )}_${date}_${time}_${UserHelper.teamId(currentUser, state)}`}
                />
              </span>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PrintDiv
            id={`h${state.selectedSprintOption.label}`}
            ref={el => {
              setElementRef(el);
            }}
          >
            {state && state.currentTeamId > 0 && state.currentWeekNumber > 1 ? (
              <BurndownChart
                chartData={chartData}
                user={`${UserHelper.firstName(
                  currentUser,
                  state
                )} ${UserHelper.lastName(currentUser, state)}`}
                sprintLabel={state.selectedSprintOption.label}
                date={getCurrentDate()}
              />
            ) : (
              <span>No data yet.</span>
            )}
          </PrintDiv>
        </CardBody>
      </Card>
    </div>
  );
};

export default SprintBurndown;
