/* eslint-disable import/prefer-default-export */
import React from "react";

const styles = {
  textStyle: {
    fontWeight: "normal",
    color: "red",
    fontSize: 14,
    fontFamily: "Arial"
  }
};
export const MissedInteraction = props => {
  const eventName = "scrum event";
  const worker = props.properties.find(p => p.itemKey === "worker").itemValue;

  const reason = props.properties.find(p => p.itemKey === "reason").itemValue;

  return (
    <div style={{ display: "flex", justifyContent: "left" }}>
      <span style={styles.textStyle}>
        {worker} {reason} {eventName}
        <br />
        <br />
      </span>
    </div>
  );
};
