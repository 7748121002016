import gql from "graphql-tag";

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    currentTeamId: Int
    currentTeamStatus: TeamStatusEnum
    currentSprintId: Int
    currentSprintNumber: Int
    currentWeekNumber: Int
    runSequenceNumber: Int
    hasActiveTeam: Boolean
    autoRun: Boolean
    autoRunRandom: Boolean
    returnMessage: String
    conditionId: Int
    conditionResponse: String
    returnStatus: ReturnStatus
    conditionResponseList: [String]
    reassignResponseList: [String]
    playScriptList: [String]
    currentWeekDay: Weekday
    condTitle: String
    conditionNumber: Int
    condAnnouncement: String
    condMessage: String
    silent: Boolean
  }
`;

export const resolvers = {};
