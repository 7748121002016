import React from "react";
import styled from "styled-components";
import { Row, Col, Card, CardBody, CardText } from "reactstrap";
import ReactPlayer from "react-player/lazy";
import people from "../../static/images/people";

const DivName = styled.div`
   {
    font-size: 8pt;
    font-weight: bold;
  }
`;

const ChatMessage = props => {
  const { message } = props;

  const resolveMedia = (imageLink, audioLink, videoLink) => {
    if (imageLink) {
      return (
        <img
          width="180px"
          src={require(`../../static/images/message/${imageLink}.png`)}
          alt="chat message graphic"
        />
      );
    }
    if (audioLink) {
      return (
        <ReactPlayer
          width={props.width ? `${props.width}px` : "320px"}
          height="50px"
          url={require(`../../static/audio/AudioMessage/${audioLink}.mp3`)}
          controls
        />
      );
    }
    if (videoLink) {
      return (
        <ReactPlayer
          width={props.width ? `${props.width}px` : "320px"}
          height="180px"
          url={require(`../../static/video/message/${videoLink}`)}
          controls
        />
      );
    }
  };

  return (
    <Card key={message.id} style={{ border: "none" }}>
      <CardBody>
        <Row>
          <Col xs="1" style={{ width: "30px" }}>
            <img
              src={
                people[
                  `${message.messageFrom.replace(/\s/g, "").replace(/\./g, "")}`
                ]
              }
              width="30"
              alt={message.messageFrom}
              id={`${message.messageFrom
                .replace(/\s/g, "")
                .replace(/\./g, "")}-story-profile`}
            />
          </Col>
          <Col xs="11">
            <DivName>{message.messageFrom}</DivName>
            <CardText>{message.messageContent}</CardText>
            {resolveMedia(
              message.imageLink,
              message.audioLink,
              message.videoLink
            )}
            {message.isRead}
          </Col>
        </Row>
        <Row>
          <Col></Col>
          <Col style={{ textAlign: "right" }}></Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ChatMessage;
