/* eslint-disable no-param-reassign */
/**
 * Vanilla JS version on jQuery's nextUntil()
 * https://github.com/cferdinandi/nextUntil
 */
import { useState } from "react";

export const isNewUser = currentUser => {
  if (
    currentUser.acceptedLicense &&
    currentUser.viewedOrientation &&
    currentUser.viewedHelp &&
    currentUser.assignedToClassroom &&
    currentUser.startedFirstProject
  ) {
    return false;
  }
  return true;
};
export const nextUntil = (elem, selector, filter) => {
  // matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }
  // Setup siblings array
  const siblings = [];
  // Get the next sibling element
  elem = elem.nextElementSibling;
  // As long as a sibling exists
  while (elem) {
    // If we've reached our match, bail
    if (elem.matches(selector)) break;

    // If filtering by a selector, check if the sibling matches
    if (filter && !elem.matches(filter)) {
      elem = elem.nextElementSibling;
    }
    // Otherwise, push it to the siblings array
    siblings.push(elem);
    // Get the next sibling element
    elem = elem.nextElementSibling;
  }
  return siblings;
};

export const logGqlError = error => {
  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors.length
  ) {
    console.error(error.networkError.result);
    error.networkError.result.errors.map(err =>
      console.error(
        err.message.replace("GraphQL error: ", "🙅‍♂️ GraphQL error: ")
      )
    );
  } else {
    console.error(
      error.message.replace("GraphQL error: ", "🙅‍♂️ GraphQL error: ")
    );
  }
};

export const timeSplice = (string, start, delCount, newSubStr) =>
  string.slice(1, start) + newSubStr + string.slice(start + Math.abs(delCount));

export const formatStartTime = startTime => {
  return timeSplice(startTime, 3, 0, ":");
};

export const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // eslint-disable-line no-useless-escape
    .replace(/\-\-+/g, "-") // eslint-disable-line no-useless-escape
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text

export const deleteStoreQuery = async () => {
  try {
    // Apollo 1.x
    // let rootQuery = this.props.client.store.getState().apollo.data.ROOT_QUERY;
    const rootQuery = this.props.client.store.cache.data.data.ROOT_QUERY;
    Object.keys(rootQuery).forEach(query => {
      delete rootQuery[query];
    });
  } catch (error) {
    console.error(`deleteStoreQuery: ${error}`);
  }
};

export const getDisplayName = code => {
  switch (code) {
    case "SPRINTPLANNING":
      return "Sprint Planning";
    case "BACKLOGREFINEMENT":
      return "Backlog Refinement";
    case "SPRINTDEMO":
      return "Sprint Review";
    case "RETROSPECTIVE":
      return "Sprint Retrospective";
    case "DAILYSTANDUP":
      return "Daily Stand Up";
    default:
      return code;
  }
};

export const getStoryStatusDisplayName = code => {
  switch (code) {
    case "NEXT_UP":
      return "Next Up";
    case "WIP":
      return "Work In Progress";
    case "WORKING":
    case "IN_PROGRESS":
      return "Working";
    case "BACKLOG":
      return "Backlog";
    case "COMPLETE":
    case "COMPLETED":
      return "Complete";
    default:
      return code;
  }
};

export const initialCap = txt =>
  txt
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

export const checkHasActiveTeam = meData => {
  if (
    meData.currentClassroom &&
    meData.currentClassroom.classStudentRuns &&
    meData.currentClassroom.classStudentRuns.length > 0
  ) {
    // see if one of these records is active

    const findActive = meData.currentClassroom.classStudentRuns.filter(
      rec => rec.status === "ACTIVE"
    );
    if (findActive && findActive.length > 0) {
      return true;
    }
  }
  return false;
};

export const checkHasCompletedTeam = meData => {
  if (
    meData.currentClassroom &&
    meData.currentClassroom.classStudentRuns &&
    meData.currentClassroom.classStudentRuns.length > 0
  ) {
    // see if one of these records is active

    const findCompleted = meData.currentClassroom.classStudentRuns.filter(
      rec => rec.status === "COMPLETED"
    );
    if (findCompleted && findCompleted.length > 0) {
      return true;
    }
  }
  return false;
};

export const formatIndividualReward = reward => {
  switch (reward) {
    case "GIFT_CARD_500":
      return "$500 gift card";
    case "GIFT_CARD_250":
      return "$250 gift card";
    case "GIFT_CARD_100":
      return "$100 gift card";
    case "VIP_PARKING":
      return "VIP Parking";
    case "GIFT_CARD_RESTAURANT":
      return "Restaurant gift card";
    case "GIFT_CARD_COFFEE":
      return "Coffee gift card";
    case "COMPANY_LOGO_ITEM":
      return "Company logo item";
    default:
      return reward;
  }
};

export const formatTeamReward = reward => {
  switch (reward) {
    case "COMPANY_BOX_SEATS":
      return "Company box seats";
    case "TEAM_FAMILY_PICNIC":
      return "Team family picnic";
    case "GIFT_CARD_50":
      return "$50 gift cards";
    case "AFTERNOON_SURPRISE_TREATS":
      return "Afternoon surprise treats";
    case "HAPPY_HOUR_PARTY":
      return "Happy hour party";
    case "COMPANY_LOGO_ITEM":
      return "Company logo item";
    case "PIZZA_LUNCH":
      return "Pizza lunch";
    default:
      return reward;
  }
};

export const formatConferenceTopic = topic => {
  switch (topic) {
    case "ATTENDANCE":
      return "Attendance";
    case "QUALITY":
      return "Quality";
    case "PRODUCTIVITY":
      return "Productivity";
    case "TEAMWORK":
      return "Teamwork";
    default:
      return topic;
  }
};

export const formatConferenceDuration = duration => {
  switch (duration) {
    case "ONE":
      return "1 hr";
    case "TWO":
      return "2 hrs";
    case "THREE":
      return "3 hrs";
    case "FOUR":
      return "4 hrs";
    default:
      return duration;
  }
};

export const formatMeetingDuration = duration => {
  switch (duration) {
    case "QUARTER":
      return "15 mins";
    case "HALF":
      return "30 mins";
    case "ONE":
      return "1 hr";
    case "ONEANDHALF":
      return "1.5 hr";
    case "TWO":
      return "2 hrs";
    case "TWOANDHALF":
      return "2.5 hrs";
    case "THREE":
      return "3 hrs";
    case "THREEANDHALF":
      return "3.5 hrs";
    case "FOUR":
      return "4 hrs";
    default:
      return "0";
  }
};

export const formatFeedback = duration => {
  switch (duration) {
    case "PRAISE":
      return "Praise";
    case "COACH":
      return "Coach";
    case "COUNSEL":
      return "Counsel";
    case "WARN":
      return "Warn";
    default:
      return duration;
  }
};

export const formatEnumValue = value => {
  if (value === undefined || value === null) return "";

  let enumValues = [];

  // assuming that words in enum entry is formed by _ character
  if (value.indexOf("_")) {
    const words = value.split("_");
    enumValues = enumValues.concat(words);
  } else {
    enumValues.push(value);
  }

  // convert every word to title case
  const titleCaseWords = enumValues.map(v => {
    return toTitleCase(v);
  });

  const formattedString = titleCaseWords.join(" ");
  return formattedString;
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getCurrentDate = (separator = "-") => {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
};

export function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = x =>
    new Promise(resolve => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}
