import React, { useContext } from "react";
import { Row, Col, Container } from "reactstrap";
import styled from "styled-components";
import moment from "moment";

import StoryStatusTable from "./StoryStatusTable";
import SprintWiseTable from "./SprintWiseTable";
import splLogo from "../../../static/images/message/spl-logo.jpg";
import { ProjectContext } from "../../../GlobalState";
import UserHelper from "../../../UserHelper";

const StyledContentRow = styled(Row)`
  display: flex;
`;

const StyledLabel = styled.div`
  font-weight: bold;
  justify-content: left;
  flex-direction: column;
`;
const StyledContent = styled.div`
  justify-content: left;
  flex-direction: column;
`;

const ColorLineSeparator = styled.hr`
  color: var(--primary-color);
  background-color: var(--primary-color);
  height: 5;
  margin-bottom: 30px;
`;

const EndRunSummary = props => {
  const [state] = useContext(ProjectContext);

  if (state.currentWeekNumber < 2) {
    return "No data yet";
  }
  const { team } = props.teamData;
  const sprints =
    props &&
    props.teamData &&
    props.teamData.team &&
    props.teamData.team.sprints &&
    props.teamData.team.sprints.length > 0
      ? props.teamData.team.sprints.sort(
          (a, b) => a.sprintNumber - b.sprintNumber
        )
      : [];

      const renderDate = dateString => {
        if (dateString === null || dateString === "") {
          return <span />;
        }
        if (Date.parse(dateString) < 0) {
          return <span />;
        }
        const date = new Date(dateString);
        return <span>{moment(date).format("DD-MMM-YYYY, hh:mm A")}</span>;
      };

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm="10">
            <h5>
              <img src={splLogo} style={{ width: "12%" }} alt="logo" />
              &nbsp;&nbsp;End of Run Report
            </h5>
          </Col>
        </Row>
        <ColorLineSeparator />
        {state.currentTeamStatus === "COMPLETE" && (
          <StyledContentRow>
            <Col xs="10" sm="10">
              You have completed all four sprints. Well done!
            </Col>
          </StyledContentRow>
        )}
        <br />
        <StyledContentRow>
          <Col xs="5" sm="5">
            <StyledLabel> Student: </StyledLabel>
          </Col>
          <Col xs="5" sm="5">
            <StyledContent>
              {UserHelper.firstName(props.currentUser, state)} {UserHelper.lastName(props.currentUser, state)}
            </StyledContent>
          </Col>
        </StyledContentRow>
        <StyledContentRow>
          <Col xs="5" sm="5">
            <StyledLabel> Execution #: </StyledLabel>
          </Col>
          <Col xs="5" sm="5">
            {state.currentTeamId}
          </Col>
        </StyledContentRow>
        <StyledContentRow>
          <Col xs="5" sm="5">
            <StyledLabel> Started: </StyledLabel>
          </Col>
          <Col xs="5" sm="5">
            {
              team.startDate ? (renderDate(team.startDate)) :
              (
               <span />
             )
            // moment(team.startDate).format("YYYY-MM-DD HH:mm:ss")
            }
          </Col>
        </StyledContentRow>
        <StyledContentRow>
          <Col xs="5" sm="5">
            <StyledLabel> Finished: </StyledLabel>
          </Col>
          <Col xs="5" sm="5">
            {state.currentTeamStatus === "COMPLETED" &&
             team.endDate ? (renderDate(team.endDate)) :
             (
              <span />
            )
              // moment(team.endDate).format("YYYY-MM-DD HH:mm:ss")
            }
          </Col>
        </StyledContentRow>
        <StyledContentRow>
          <Col xs="5" sm="5">
            <StyledLabel> Planned MVP stories: </StyledLabel>
          </Col>
          <Col xs="5" sm="5">
            {props.stories.filter(s => s.requiredStory === true).length}
          </Col>
        </StyledContentRow>
        <StyledContentRow>
          <Col xs="5" sm="5">
            <StyledLabel> Completed MVP stories: </StyledLabel>
          </Col>
          <Col xs="5" sm="5">
            {
              props.stories
                .filter(s => s.requiredStory === true)
                .filter(c => c.status.toUpperCase() === "COMPLETE").length
            }
          </Col>
        </StyledContentRow>
        <StyledContentRow>
          <Col xs="5" sm="5">
            <StyledLabel> Average team velocity *(Story Points)*: </StyledLabel>
          </Col>
          <Col xs="5" sm="5">
            {props.averageTeamVelocity}
          </Col>
        </StyledContentRow>
        <br />
        <StyledContentRow style={{ pageBreakAfter: "always" }}>
          <Col xs="10">
            <h5>Performance by Sprint:</h5>

            <SprintWiseTable
              sprintwisePerformance={props.perSprintVelocities}
            />
          </Col>
        </StyledContentRow>
        <>
          <br />
          <br />
          <StyledContentRow>
            <Col xs="10" sm="10">
              <h5>Completed Stories By Sprint</h5>
            </Col>
          </StyledContentRow>
          <br />
          <StyledContentRow style={{ pageBreakAfter: "always" }}>
            <Col xs="10" sm="10">
              {sprints && sprints.length > 0 ? (
                <StoryStatusTable
                  sprintNumber={1}
                  stories={props.teamData.team.sprints[0].stories}
                />
              ) : (
                <span />
              )}
            </Col>
          </StyledContentRow>
          <br />
          <StyledContentRow style={{ pageBreakAfter: "always" }}>
            <Col xs="10" sm="10">
              {sprints && sprints.length > 1 ? (
                <StoryStatusTable
                  sprintNumber={2}
                  stories={props.teamData.team.sprints[1].stories}
                />
              ) : (
                <span />
              )}
            </Col>
          </StyledContentRow>
          <br />
          <StyledContentRow style={{ pageBreakAfter: "always" }}>
            <Col xs="10" sm="10">
              {sprints && sprints.length > 2 ? (
                <StoryStatusTable
                  sprintNumber={3}
                  stories={props.teamData.team.sprints[2].stories}
                />
              ) : (
                <span />
              )}
            </Col>
          </StyledContentRow>

          <br />
          <StyledContentRow style={{ pageBreakAfter: "always" }}>
            <Col xs="10" sm="10">
              {sprints && sprints.length > 3 ? (
                <StoryStatusTable
                  sprintNumber={4}
                  stories={props.teamData.team.sprints[3].stories}
                />
              ) : (
                <span />
              )}
            </Col>
          </StyledContentRow>
          <br />

          <StyledContentRow>
            <Col xs="10" sm="10">
              <StoryStatusTable
                sprintNumber={0}
                stories={props.stories.filter(
                  s => s.status.toUpperCase() !== "COMPLETE"
                )}
              />
            </Col>
          </StyledContentRow>
          <br />
        </>
      </Container>
    </>
  );
};

export default EndRunSummary;
