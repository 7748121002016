import { Table as ReactstrapTable } from "reactstrap";
import styled from "styled-components";

const Table = styled(ReactstrapTable)`
  &.table th,
  &.table td {
    vertical-align: middle;
  }

  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: var(--gray-color);
  }
`;

export default Table;
