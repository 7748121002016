import React from "react";
import StyledDiv from "./StyledDiv";

const DefinitionOfDone = () => (
  <StyledDiv>
    <h3>Definition of Done</h3>
    <p>
      The product team needs to have a common understanding of what “Done”
      means. The Definition of Done applies to every increment that is
      releasable. Here is a starting Definition of Done for the team:
    </p>
    <ul>
      <li>Acceptance criteria are met</li>
      <li>Functional tests passed</li>
      <li>No major defects are open</li>
      <li>Code has been peer-reviewed</li>
      <li>Documentation is complete </li>
      <li>User acceptance complete</li>
      <li>All code and configuration is checked into GitHub</li>
    </ul>
  </StyledDiv>
);

export default DefinitionOfDone;
