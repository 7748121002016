import React, { Component } from "react";
import styled from "styled-components";

const RatingItem = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  grid-gap: 0 10px;
  line-height: 1.2;
  margin-bottom: 10px;
  font-size: 0.7rem;
`;

const Rating = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  border: 1px solid var(--black-color);

  > div {
    border-right: 1px solid var(--black-color);
    display: block;
    height: 25px;

    &.fill {
      background-color: var(--primary-color);
    }

    &:last-child {
      border-right: 0;
    }
  }
`;

class PerformanceItem extends Component {
  render() {
    const { value } = this.props;
    return (
      <RatingItem>
        <span>{this.props.start}</span>
        <Rating>
          <div className={value >= 1 ? "fill" : ""} />
          <div className={value >= 2 ? "fill" : ""} />
          <div className={value >= 3 ? "fill" : ""} />
          <div className={value >= 4 ? "fill" : ""} />
          <div className={value === 5 ? "fill" : ""} />
        </Rating>
        <span>{this.props.end}</span>
      </RatingItem>
    );
  }
}

export default PerformanceItem;
