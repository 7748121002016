import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Badge } from "reactstrap";
import styled from "styled-components";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import Loading, { Error } from "../../components/Loading";

import { DetailsList } from "./components/DetailsList";

const STUDENT_USER_QUERY = gql`
  query STUDENT_USER_QUERY($id: ID) {
    user(where: { id: $id }) {
      id
      firstName
      lastName
      email
      welcomeStatus
      acceptedLicense
      viewedHelp
      viewedOrientation
      assignedToClassroom
      startedFirstProject
    }
  }
`;

const StudentHeading = styled.h3`
  .btn {
    margin-left: 1rem;
  }
`;

const StudentOverview = props => (
  <Query query={STUDENT_USER_QUERY} variables={{ id: props.match.params.id }}>
    {({ data, loading, error }) => {
      if (loading) return <Loading />;
      if (error) return <Error error={error} />;
      const { user } = data;
      return (
        <div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <StudentHeading>
                    {user.firstName !== undefined
                      ? `${user.firstName} ${user.lastName}`
                      : ""}
                    <Link
                      className="btn btn-sm btn-outline-secondary"
                      to="/management/students"
                      href="/management/students"
                    >
                      Go back
                    </Link>
                  </StudentHeading>
                  <DetailsList>
                    <li>
                      <h4>User ID:</h4>
                      <p>{user.id}</p>
                    </li>
                    <li>
                      <h4>Status:</h4>
                      <p>
                        <Badge color="light">{user.welcomeStatus}</Badge>
                      </p>
                    </li>
                  </DetailsList>
                  <p>
                    Toggle between teams on the right to review info and details
                    about each team belonging to this student.
                  </p>
                  {/* <Button color="info" disabled>
          <i className="fa fa-envelope" /> Email Student
        </Button>{" "}
        <Button color="info" disabled>
          <i className="fa fa-file-pdf-o" /> Export Summary to PDF
        </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Row>
                <Col sm="4">
                  <h4>Runs</h4>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col />
          </Row>
        </div>
      );
    }}
  </Query>
);

export default StudentOverview;
