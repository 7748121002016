// projcomplete

import React from "react";

/*
0 day
*/
export const TeamComplete = props => (
  <h6 style={{ marginTop: "20px", marginBottom: "20px" }}>The run is complete. The team has completed four sprints.</h6>
);
