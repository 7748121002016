import React from "react";
import ScrumBoardImage from "../../../static/images/helpModal/ScrumBoard.png";

const ScrumBoard = () => (
  <div>
    <h3>Scrum Board</h3>
    <p>
      The Scrum Board is a visual representation of the work in a Sprint. The Scrum Board also sometimes called 
      the storyboard allows the work to be highly visible and the team can identify where the stories 
      are in their development lifecycle. 
    </p>
    <img key="ScrumBoard1" className="HelpModalimage" alt="Scrum Board screenshot" src={ScrumBoardImage} />
    <br/><br/>
    <p>The Scrum Board has three columns:</p>
    <p><b>Sprint Backlog</b></p>
    <p>
      The backlog items in this column have been assigned to the sprint, but no work has begun. 
      You can assign team members to the backlog item. If you don’t assign team members to the backlog items, 
      the simulation engine will make the assignment for you when you run the sprint. You also have the 
      ability to change the order of the sprint backlog items by simply dragging the items. Items on top 
      will be worked on first by the product team.  
    </p>
    <p><b>In Progress</b></p>
    <p>
      The backlog items in this column are the work in progress items. The simulation engine will 
      move the backlog items to In Progress when work begins on that item.
    </p>
    <p><b>Done</b></p>
    <p>
      When the acceptance criteria for  backlog item has been met, the backlog item is moved to the Done column. 
      Backlog items that have been accepted and moved to the Done column count towards the sprint velocity.
    </p>
    <p>
      You can use the Team Member filter to view the backlog items assigned to a particular team member.
    </p>
  </div>
);

export default ScrumBoard;
