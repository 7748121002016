class UserHelper {
  static firstName = (currentUser, state) => {
    return state.teamStudentId === 0
      ? currentUser.firstName
      : state.teamStudentFirst;
  };

  static lastName = (currentUser, state) => {
    return state.teamStudentId === 0
      ? currentUser.lastName
      : state.teamStudentLast;
  };

  static teamId = (currentUser, state) => {
    return state.teamStudentId === 0
      ? currentUser.currentTeam.id
      : state.currentTeamId;
  };
}
export default UserHelper;
