/* eslint-disable import/prefer-default-export */
import React from "react";
import SectionHeader from "../../../../components/styled/Reports/SectionHeader";

export const SprintComplete = props => {
  const sprintNumber = props.properties.find(p => p.itemKey === "sprintNumber")
    .itemValue;

  return (
    <SectionHeader>
      <h5>
        <i className="fa fa-thumbs-up" /> Sprint: {sprintNumber} is now
        complete.
      </h5>
    </SectionHeader>
  );
};
