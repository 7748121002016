import React from "react";
import { Card, CardHeader, CardBody, Table } from "reactstrap";
import styled from "styled-components";

import PerformanceItem from "components/Worker/components/PerformanceItem";

const WorkerProfile = ({ worker }) => {
  return (
    <Card>
      <CardHeader>Profile for {worker.workerName}</CardHeader>
      <CardBody>
        <DataBlock>
          <h5>Observations</h5>
          {worker.observations}
        </DataBlock>
        <Layout>
          <DataBlock>
            <h5>Performance Review</h5>
            <PerformanceItem
              start="Completes tasks late"
              end="Completes tasks early"
              value={worker.performance_1}
            />
            <PerformanceItem
              start="Low quality work"
              end="High quality work"
              value={worker.performance_2}
            />
            <PerformanceItem
              start="Needs direction"
              end="Works well without direction"
              value={worker.performance_3}
            />
            <PerformanceItem
              start="Likes to work in teams"
              end="Likes to work alone"
              value={worker.performance_4}
            />
            <PerformanceItem
              start="Weak communication"
              end="Strong communication"
              value={worker.performance_5}
            />
            <PerformanceItem
              start="Open to challenges"
              end="Stays within comfort zone"
              value={worker.performance_6}
            />
            <PerformanceItem
              start="Abstract thinker"
              end="Detailed thinker"
              value={worker.performance_7}
            />
          </DataBlock>
          <div>
            <DataBlock>
              <h5>Education</h5>
              <StyledTable>
                <tbody>
                  <tr>
                    <td colSpan="2">{worker.education}</td>
                  </tr>
                </tbody>
              </StyledTable>
            </DataBlock>
            <DataBlock>
              <h5>Experience</h5>
              <StyledTable>
                <tbody>
                  <tr>
                    <td colSpan="2">{worker.experience}</td>
                  </tr>
                </tbody>
              </StyledTable>
            </DataBlock>
          </div>
        </Layout>
        <DataBlock>
          <h5>Objectives</h5>
          <p>{worker.objectives}</p>
        </DataBlock>
      </CardBody>
    </Card>
  );
};

export default WorkerProfile;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTable = styled(Table)`
  font-size: 0.7rem;

  &.table td {
    padding: 0.5rem;
  }
`;

const DataBlock = styled.div`
  padding: 10px;
`;
