import React from "react";
import { Card, Label, Input, Collapse } from "reactstrap";
import styled from "styled-components";
import CardHeader from "../../../components/styled/CardHeader";
import ScrollCardBody from "../../../components/ScrollCardBody";

const StyledCardHeader = styled(CardHeader)`{
    display: flex;
    justify-content: space-between;
  
    .switch {
      margin-left: auto;
      margin-bottom: 0;
    }
  
    .switch .switch-input:not(:checked) + .switch-label {
      background-color: #a4b7c1;
      border-color: #869fac;
    }
    .switch .switch-input:not(:checked) + .switch-label + .switch-handle {
      border-color: #869fac;
    }
  }
}

`;
const HistoryInfo = props => (
  <Card className="Card-info">
    <StyledCardHeader>
      <span style={{ marginRight: "5px" }}>
        <b>History Info</b>
      </span>
      <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
          onClick={() => props.toggleCard()}
          defaultChecked={props.cardInfoOpen}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
    </StyledCardHeader>
    <Collapse isOpen={props.cardOpen}>
      <ScrollCardBody>
        <p>
          The history window provides a documented history of the decisions and
          results for each executed week for the product team. Select a specific
          week via the menu. Weekly reports are organized by the days of the
          week and include a weekly summary of current project and team member
          performance at the end of the report.
        </p>
      </ScrollCardBody>
    </Collapse>
  </Card>
);

export default HistoryInfo;
