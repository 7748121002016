import React, { useContext, useState } from "react";
// import { useHistory } from "react-router-dom";

import { Col, Row, Card, CardBody, CardHeader, Button } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search
} from "react-bootstrap-table2-toolkit";
import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import moment from "moment";
import Select from "react-select";
import { TEAM_QUERY } from "../../../graphql/queries";
import Loading, { Error } from "../../../components/Loading";
import { formatEnumValue } from "../../../utilities/utils";
import { ProjectContext } from "../../../GlobalState";

import instructorGuide from "../../../static/pdf/SimAgile_Instructor_Guide.pdf";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const StyledTableHeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > .col:last-child {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .searchInput {
    width: 20rem;
  }
  .search {
    width: 100%
    margin-top: 0.5rem;
  }
  dl {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
  dl .col {
    margin-bottom: 0;
  }
`;

export const GET_STUDENT_RUNS = gql`
  query GET_STUDENT_RUNS($classroomId: Int!) {
    classStudentRuns: classStudentRunByClassroomId(classroomId: $classroomId) {
      id
      runSequenceNumber
      status
      student {
        id
        firstName
        lastName
        email
        welcomeStatus
        acceptedLicense
        viewedHelp
        viewedOrientation
        assignedToClassroom
        startedFirstProject
      }
      team {
        id
        currentStatus
        currentSprintNumber
        currentWeekNumber
      }
    }
  }
`;

const StudentsTable = ({ instructorData, instructors }) => {
  const activeClassRoom = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const element of instructors) {
    activeClassRoom.push({
      label: element.classroom.className,
      value: element.classroom.id
    });
  }

  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(ProjectContext);
  // const history = useHistory();
  const [clickedRun, setClickedRun] = useState(0);

  const [getTeamData, { loading: teamLoading, teamError }] = useLazyQuery(
    TEAM_QUERY,
    {
      onCompleted: async data => {
        if (data && data.team) {
          const payload = {
            currentTeamId: data.team.id,
            currentTeamStatus: data.team.currentStatus,
            currentSprintId: data.team.currentSprintId,
            currentSprintNumber: data.team.currentSprintNumber,
            currentWeekNumber: data.team.currentWeekNumber,
            currentWeekDay: data.team.currentWeekDay,
            currentRunDay: data.team.currentRunDay,
            runSequenceNumber: clickedRun.runSeqenceNumber,
            hasActiveTeam: true,
            studentId: clickedRun.student.id,
            studentFirst: clickedRun.student.firstName,
            studentLast: clickedRun.student.lastName,
            sprints: data.team.sprints
          };

          await dispatch({
            type: "UPDATE_LOCAL_TEAM",
            payload
          });
        }
      }
    }
  );

  const { loading, error, data } = useQuery(GET_STUDENT_RUNS, {
    variables: { classroomId: instructorData.classroom.id },
    skip: state.instructorSelectedClassIndex < 0
  });

  if (loading || teamLoading) return <Loading />;
  if (error) return <Error error={error} />;
  if (teamError) return <Error error={teamError} />;
  let students = [];
  let filteredStudents = [];
  const { classStudentRuns } = data;
  if (classStudentRuns && classStudentRuns.length > 0) {
    students = classStudentRuns.map(team => team.student);
    filteredStudents = students.filter(
      (val, id, array) => array.indexOf(val) === id
    );
  }

  if (!classStudentRuns || classStudentRuns === null) {
    return <h2>No Students </h2>;
  }

  const addTeamLink = (cell, row) => {
    if (cell === undefined || cell === null) {
      return "";
    }

    if (
      row.team?.currentStatus === "ACTIVE" ||
      row.team?.currentStatus === "COMPLETED"
    ) {
      return (
        <Button
          color="link"
          onClick={async () => {
            getTeamData({ variables: { teamId: row.team.id } });
            setClickedRun(row);
          }}
        >
          Review
        </Button>
      );
    }
    return cell;
  };

  const hideZerosSOfSprintNumber = (cell, row) => {
    if (cell === undefined || cell === null) {
      return "";
    }

    if (row.status === "ACTIVE" || row.status === "COMPLETED") {
      return cell;
    }
    return "";
  };

  const hideZerosSOfWeekNumber = (cell, row) => {
    if (cell === undefined || cell === null) {
      return "";
    }
    if (row.status === "ACTIVE" || row.status === "COMPLETED") {
      return cell;
    }
    return "";
  };

  const { ExportCSVButton } = CSVExport;
  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: "runSequenceNumber",
      text: "Run Number",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "10%" }
    },
    {
      dataField: "student.firstName",
      text: "First Name",
      sort: true,
      headerStyle: { width: "10%" }
    },
    {
      dataField: "student.lastName",
      text: "Last Name",
      sort: true,
      headerStyle: { width: "10%" }
    },
    {
      dataField: "student.email",
      text: "Email",
      sort: true,
      headerStyle: { width: "20%" }
    },
    {
      dataField: "student.welcomeStatus",
      text: "Status",
      sort: true,
      formatter: cell => formatEnumValue(cell),
      headerStyle: { width: "10%" }
    },
    {
      dataField: "status",
      text: "Team Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: cell => formatEnumValue(cell),
      headerStyle: { width: "10%" }
    },
    {
      dataField: "team.id",
      text: "Run Review",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "10%" },
      formatter: addTeamLink
    },
    {
      dataField: "team.currentWeekNumber",
      text: "Current Week #",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "10%" },
      formatter: hideZerosSOfWeekNumber
    },
    {
      dataField: "team.currentSprintNumber",
      text: "Current Sprint #",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "10%" },
      formatter: hideZerosSOfSprintNumber
    }
  ];

  const renderHeaderToolbar = tableProps => (
    <StyledTableHeaderRow>
      <Col sm="12" lg="6">
        <dl className="row">
          <dt className="col col-md-4">Instructor: </dt>
          <dd className="col col-md-8">
            {`${instructorData.user.firstName} ${instructorData.user.lastName}`}
          </dd>
          <dt className="col col-md-4">Status: </dt>
          <dd className="col col-md-8">{instructorData.classroom.status}</dd>
          <dt className="col col-md-4">Current Students: </dt>
          <dd className="col col-md-8">{filteredStudents.length}</dd>
          <dt className="col col-md-4">Start Date: </dt>
          <dd className="col col-md-8">
            {moment(instructorData.classroom.startDate).format("MMM-DD YYYY")}
          </dd>
          <dt className="col col-md-4">End Date: </dt>
          <dd className="col col-md-8">
            {moment(instructorData.classroom.endDate).format("MMM-DD YYYY")}
          </dd>
          <p>
            <span />
          </p>
        </dl>
      </Col>
      <Col sm="12" lg="6">
        <div>
          <ExportCSVButton {...tableProps.csvProps}>
            <i className="fa fa-download" /> Export To CSV
          </ExportCSVButton>
        </div>
        <div className="search">
          <SearchBar className="searchInput" {...tableProps.searchProps} />
          <ClearSearchButton {...tableProps.searchProps} />
        </div>
      </Col>
    </StyledTableHeaderRow>
  );

  const onChangeOption = (value, action) => {
    const index = activeClassRoom.findIndex(x => x.value === value.value);
    const payload = {
      index
    };
    dispatch({
      type: "SET_INSTRUCTOR_CLASS_INDEX",
      payload
    });
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col style={{ maxWidth: "20%" }}>
            <b>Select Classroom: </b>
            <Select
              name="activeClassRooms"
              defaultValue={activeClassRoom.find(
                s =>
                  s.value ===
                  (state.instructorSelectedClassIndex > 0
                    ? activeClassRoom[state.instructorSelectedClassIndex].value
                    : activeClassRoom[0].value)
              )}
              options={activeClassRoom}
              onChange={onChangeOption}
            />
          </Col>
          {/* <Col className="active-classroom">
            <b>Active Classroom: </b>
            <span style={{ marginLeft: "5px" }}>
              {instructorData.classroom.className}
            </span>
          </Col> */}
          <Col className="instructor-guide">
            <div style={{ textAlign: "right" }}>
              <b>Instructor Guide</b>
              <Button id="instructorGuide" color="link">
                <a href={instructorGuide} download="Instructor Guide">
                  <i className="fa fa-download" />
                </a>
              </Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ToolkitProvider
          keyField="id"
          data={classStudentRuns}
          columns={columns}
          exportCSV
          search
        >
          {tableProps => (
            <div>
              {renderHeaderToolbar(tableProps)}
              <BootstrapTable
                {...tableProps.baseProps}
                pagination={paginationFactory({ sizePerPage: 30 })}
                condensed
                striped
                bootstrap4
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default StudentsTable;
