import React, { useContext, useState } from "react";
import { CardBody, Badge, CardFooter } from "reactstrap";
import { ProjectContext } from "GlobalState";
import { UncontrolledTooltip } from "../../../components/styled/UncontrolledTooltip";
import StyledStoryComponent from "../../../components/styled/StyledStoryComponent";
import StoryFooter from "./StoryFooter";
import StoryModal from "./StoryModal";
import { getStoryStatusDisplayName } from "../../../utilities/utils";

const Story = props => {
  const [state] = useContext(ProjectContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const { refetch, story, index } = props;

  if (story === undefined || story === null) {
    return <span>No story</span>;
  }
  return (
    <>
      <StyledStoryComponent onClick={() => setModalIsOpen(!modalIsOpen)}>
        {story.requiredStory ? (
          <div className="isRequired" id={`isRequired-${story.id}`}>
            <Badge color="danger">
              <i className="fa fa-exclamation" />
            </Badge>
            <UncontrolledTooltip
              placement="top"
              target={`isRequired-${story.id}`}
            >
              Required for MVP
            </UncontrolledTooltip>
          </div>
        ) : (
          ""
        )}
        <CardBody>
          {story.storyName}
          <br />
          <strong>Status:</strong> {getStoryStatusDisplayName(story.status)}
        </CardBody>
        <CardFooter>
          <StoryFooter
            storyId={story.id}
            index={index}
            teamId={state.currentTeamId}
          />
        </CardFooter>
      </StyledStoryComponent>

      {modalIsOpen && (
        <StoryModal
          currentSprintId={state.currentSprintId}
          projectContext={state}
          toggleModal={toggleModal}
          isOpen={modalIsOpen}
          currentStory={story}
          okToAssignWorkers
          refetch={refetch}
        />
      )}
    </>
  );
};

export default Story;
