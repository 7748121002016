import React from "react";
import Select from "react-select";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { FormGroup, Label } from "reactstrap";
import { Error } from "./Loading";

const EnumSelector = ({
  enumType,
  title,
  controlName = `enum_${enumType}`,
  formatProvider,
  defaultValue,
  onChange,
  filterValues = []
}) => {
  const ENUM_QUERY = gql`
  query {
    __type(name: "${enumType}") {
      name
      enumValues {
        name
      }
    }
  }
`;

  const { data: enumData, loading: loadingR, error: errorR } = useQuery(
    ENUM_QUERY
  );

  let items = [];
  if (!loadingR && !errorR && enumData) items = enumData.__type.enumValues;

  if (loadingR) return "Loading...";
  if (errorR) return <Error error={errorR} />;

  const getEnumOptions = () => {
    const options = items.map(item => {
      if (formatProvider !== null || formatProvider !== undefined) {
        return { value: item.name, label: formatProvider(item.name) };
      }

      return { value: item.name, label: item.name };
    });

    if (filterValues.length > 0) {
      const filterdOptions = options.filter(
        o => filterValues.find(v => v === o.value) !== undefined
      );
      return filterdOptions;
    }

    return options;
  };

  return (
    <FormGroup>
      <Label id={`label_${controlName}`}>{title}</Label>
      <Select
        name={controlName}
        aria-labelledby={`label_${controlName}`}
        options={getEnumOptions()}
        onChange={onChange}
        defaultValue={getEnumOptions().find(d => d.value === defaultValue)}
      ></Select>
    </FormGroup>
  );
};

export default EnumSelector;
