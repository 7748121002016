import React from "react";
import CalendarImage from "../../../static/images/helpModal/Calendar.png";
import CalendarError from "../../../static/images/helpModal/CalendarError.png";

const Calendar = () => (
  <div>
    <h3>Calendar</h3>
    <p>
      You can add, edit or delete multiple interactions on the Calendar page.
    </p>
    <img
      key="Calendar1"
      className="HelpModalimage"
      alt="Calendar screenshot"
      src={CalendarImage}
    />
    <br />
    <br />
    <p>
      <b>1-on-1 Session</b>
    </p>
    <p>
      You have the ability to schedule 1-on-1 sessions with individual team
      members. You can specify a topic for discussion like attendance, quality,
      productivity, and teamwork and the feedback that you intend to provide
      during this session. The objective of the feedback could be to praise,
      coach, counsel or warn a team member.
    </p>
    <p>
      <b>Scrum Event</b>
    </p>
    <p>
      You can set up the Sprint cadence by scheduling Scrum events like Sprint
      Planning, Backlog Refinement, Sprint Review (also called as demo) and
      Sprint Retrospective. A recurring Daily Stand Up or Daily Scrum can also
      be scheduled on the Calendar page.
    </p>
    <p>
      <b>Individual Reward</b>
    </p>
    <p>
      Individual rewards can motivate team members to perform better, increase
      their morale and productivity. Use them wisely.
    </p>
    <p>
      <b>Team Reward</b>
    </p>
    <p>
      Team rewards help build the team spirit. You have the ability to impact
      team morale and productivity through team based rewards.
    </p>
    <p>
      <b>Training</b>
    </p>
    <p>
      Team members can be sent to a number of training sessions to acquire or
      build on skills like story mapping, engineering practices and
      communications. The Agile Bootcamp is the only training that is team
      based, and the entire team can attend this one-day training.
    </p>
    <p>
      When scheduling events, training, etc. please note that you cannot
      schedule more than 8 hrs of team member capacity for a day. An error
      message will alert you, if you run into this scenario.
    </p>
    <img
      key="Calendar2"
      className="HelpModalimage"
      alt="Calendar screenshot"
      src={CalendarError}
    />
    <br />
    <br />
  </div>
);

export default Calendar;
