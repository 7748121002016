import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { TEAM_QUERY } from "../graphql/queries";
import Loading, { Error } from "./Loading";
import WorkerModal from "./Worker/WorkerModal";
import Worker from "./Worker/Worker";

const Grid = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
`;

const WorkerModalLink = styled.div`
  &:hover > div {
    background-color: #fafafa;
  }
`;

const TeamModal = props => {
  const { data: teamData, loading: loadingT, error: errorT } = useQuery(
    TEAM_QUERY,
    {
      variables: { teamId: props.teamId }
    }
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [workerId, setworkerId] = useState("");

  if (loadingT) return <Loading />;
  if (errorT) return <Error error={errorT} />;

  const toggleModal = wId => {
    setworkerId(wId);
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <Row>
      <Col>
        <Modal
          size="lg"
          isOpen
          fade={false}
          centered
          toggle={props.toggleModal}
        >
          <ModalHeader toggle={props.toggleModal}>Team Members</ModalHeader>
          <ModalBody>
            <Grid>
              {teamData.team.teamWorkers.map(worker => (
                <WorkerModalLink
                  key={worker.id}
                  onClick={() => toggleModal(worker.id)}
                >
                  <Worker
                    workerData={worker}
                    key={worker.id}
                    skills={teamData.team.skills}
                  />
                </WorkerModalLink>
              ))}
            </Grid>
            {modalIsOpen && (
              <WorkerModal
                teamId={props.teamId}
                workerId={workerId}
                isOpen={modalIsOpen}
                toggleModal={toggleModal}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button outline color="primary" onClick={props.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Col>
    </Row>
  );
};

export default TeamModal;
