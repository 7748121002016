/* eslint-disable no-nested-ternary */
import React from "react";

function sentenceCase(str) {
  return str
    .replace(/[a-z]/i, function(letter) {
      return letter.toUpperCase();
    })
    .trim();
}

/*
 * 0 = worker
 * 1 = topic
 * 2 = duration
 * 3 = feedback
 */
// TODO remove this eslint issue
// eslint-disable-next-line import/prefer-default-export
export const Conference = props => {
  const eventName = "1-on-1 session";

  // worker, topic, duration, feedback
  const worker = props.properties.find(s => s.itemKey === "worker").itemValue;
  const topic = props.properties.find(s => s.itemKey === "topic").itemValue;
  const duration = props.properties.find(s => s.itemKey === "duration")
    .itemValue;

  const timeFrame =
    duration === "ONE" ? " 1 hr" : duration === "TWO" ? "2 hr" : " 3 hr";

  return (
    <div className="content" style={{ marginBottom: "10px" }}>
      <span role="img" aria-label="party">
        🤝
      </span>
      {worker} attended a {timeFrame} {eventName} to discuss{" "}
      {sentenceCase(topic.toLowerCase())}.
    </div>
  );
};
