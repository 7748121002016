import React from "react";
import StyledDiv from "./StyledDiv";

const WelcomeOverview = () => (
  <StyledDiv>
    <h3>Welcome</h3>
    <p>
      I’m Michael Russel, the Vice President of Human Resources at Uniworld.
    </p>
    <p>
      I would like to personally welcome you to the Benefits and Admiration
      product team. As we embark on integrating our recent acquisition of
      Whistle While You Work, we have a need to immediately and dramatically
      update our employee benefits and payroll experience. O.M. Smith, our CEO
      has asked me to be the product manager of this workstream.
    </p>
    <p>
      It is imperative that our team delivers a minimal viable product as soon
      as we can. O. M. Smith has a personal interest in this work, and it is now
      one of our merger integration objectives. I understand we have had recent
      success using an agile and Scrum development methodology and I would urge
      you to be nimble and adjust to the evolving priorities.
    </p>
    <p>
      I’ve been working with our Product Owner, Farley Gnu, to finalize the
      product vision and you will find that information on the team
      collaboration space. It will provide more details on the objectives and
      product roadmap. Please familiarize yourself with the current product
      backlog.
    </p>
    <p>
      I have asked the various functional resource managers to make available to
      you a core team of personnel that will form this product team. They have
      assured me that these individuals have the necessary skillset across the
      entire tech stack.
    </p>
    <p>Again, welcome to the team, and good luck.</p>
    <p>Cheers and see you soon,</p>
    <p>Michael</p>
  </StyledDiv>
);

export default WelcomeOverview;
