import { CardHeader as ReactstrapCardHeader } from "reactstrap";
import styled from "styled-components";

const CardHeader = styled(ReactstrapCardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pull-right {
    margin-left: auto;
  }

  .btn {
    margin-top: 0;
  }

  .switch {
    margin-bottom: 0;
  }
`;

export default CardHeader;
