import React from "react";
import StyledDiv from "./StyledDiv";

const TeamWorkingAgreement = () => (
  <StyledDiv>
    <h3>Team Working Agreement</h3>
    <p>
      The team has drafted and agreed on the following team working agreement.
      The goal of the team working agreement is to come together and define the
      expectations of one another. The purpose is not to dictate how the work
      gets done, but rather emphasize team behaviors that will keep everyone
      productive, positive, and accountable.
    </p>
    <ol>
      <li>
        Show respect to all team members – value everyone’s opinion, be
        inclusive
      </li>
      <li>Define and always adhere to the Definition of Done</li>
      <li>
        All incoming work needs to flow through the backlog refinement process
      </li>
      <li>Attend all Scrum ceremonies, if you cannot attend, send an update</li>
      <li>Stay focused during meetings and ceremonies, no cell phone policy</li>
      <li>Update scrum board daily</li>
      <li>Team agrees to review the working agreement at periodic intervals</li>
    </ol>
  </StyledDiv>
);

export default TeamWorkingAgreement;
