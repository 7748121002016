import React, { useContext } from "react";
import { Card, Label, Input, Collapse } from "reactstrap";
import styled from "styled-components";
import CardHeader from "../../../components/styled/CardHeader";
import ScrollCardBody from "../../../components/ScrollCardBody";
import { ProjectContext } from "../../../GlobalState";
import MemberLoadTable from "../../Reports/components/MemberLoadTable";

const StyledCardHeader = styled(CardHeader)`{
    display: flex;
    justify-content: space-between;
  
    .switch {
      margin-left: auto;
      margin-bottom: 0;
    }
  
    .switch .switch-input:not(:checked) + .switch-label {
      background-color: #a4b7c1;
      border-color: #869fac;
    }
    .switch .switch-input:not(:checked) + .switch-label + .switch-handle {
      border-color: #869fac;
    }
  }
}
`;


const ScrumboardInfoCard = (props) => {
  const [state] = useContext(ProjectContext);

  return (
    <Card className="Card-info TableHight" style={{ marginBottom: '0px' }}>
      <StyledCardHeader>
        <span style={{ marginRight: "5px" }}>
          <b>Member Load Info</b>
        </span>
        <Label className="switch switch-default switch-primary">
          <Input
            type="checkbox"
            className="switch-input"
            onClick={() => props.toggleScrumboardInfoCard()}
            defaultChecked={props.cardInfoOpen}
          />
          <span className="switch-label" />
          <span className="switch-handle" />
        </Label>
      </StyledCardHeader>
      <Collapse isOpen={props.cardInfoOpen}>
        <ScrollCardBody>
          <div>
            <MemberLoadTable
              teamId={state.currentTeamId}
              sprintId={parseInt(state.selectedSprintOption.value, 10)}
            />
          </div>
        </ScrollCardBody>
      </Collapse>
    </Card>
  );
};

export default ScrumboardInfoCard;
