import React, { Fragment } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
// import AutoRun from "./components/AutoRun";

import Classroom from "../Teams/components/Classroom";

const Settings = props => {
  // const { isAdmin } = props.currentUser;  // use when autorun is enabled again

  return (
    <Fragment>
      <Row>
        <Col lg="7">
          <Card>
            <CardHeader>Classroom Information</CardHeader>
            <CardBody>
              <Classroom currentUser={props.currentUser} />
            </CardBody>
          </Card>
        </Col>
        {/* {isAdmin && (
          <Col lg="5">
            <Card>
              <CardHeader>Auto Run</CardHeader>
              <CardBody>
                <AutoRun />
              </CardBody>
            </Card>
          </Col>
        )} */}
      </Row>
    </Fragment>
  );
};

export default Settings;
