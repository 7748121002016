/* eslint-disable import/prefer-default-export */
import React from "react";

const styles = {
  textStyle: {
    fontWeight: "normal",
    color: "red",
    fontSize: 14,
    fontFamily: "Arial"
  }
};
export const MissedRequiredMeetings = props => {
  const topic = props.properties.find(p => p.itemKey === "topic").itemValue;
  return (
    <div style={{ display: "flex", justifyContent: "left" }}>
      <span style={styles.textStyle}>
        <span role="img" aria-label="frown">
          🙁{" "}
        </span>{" "}
        Required <strong>{topic}</strong> meeting was not held during this
        sprint.
        <br />
        <br />
      </span>
    </div>
  );
};
