import styled from "styled-components";
import StyledStoryComponent from "./StyledStoryComponent";

const StyledInteractionComponent = styled(StyledStoryComponent)`
  .card-footer .btn {
    padding: 0 0.25rem;
    border: 0;
  }
`;

export default StyledInteractionComponent;
