import React, { Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import styled from "styled-components";
import CardHeader from "../../../components/styled/CardHeader";
import parseHistoryToComponentList from "../../Reports/functions/parseHistoryToComponentList";

const CurrentDayDiv = styled.div`
  color: maroon;
  font-weight: bold;
  text-decoration: underline overline;
`;

const OtherDayDiv = styled.div`
  color: black;
  font-weight: bold;
`;

// props are history, parent process and weekiscomplete
// if weekiscomplete - don't try to show data

const DailyHistory = props => {
  const getNewProps = weekDay => {
    const newProps = {
      parentProcess: props.parentProcess,
      weekNumber: props.weekNumber,
      componentList: props.history.filter(
        h => h.weekDay === weekDay && h.weekNumber === props.weekNumber
      )
    };
    return newProps;
  };
  return (
    <Card>
      <CardHeader>
        <b>Team Progress : Week {props.weekNumber}</b>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                {props.weekDay.toUpperCase() === "MONDAY" ? (
                  <CurrentDayDiv>Monday</CurrentDayDiv>
                ) : (
                  <OtherDayDiv>Monday</OtherDayDiv>
                )}
              </CardHeader>
              <CardBody>
                <Fragment>
                  <div>
                    {" "}
                    {props.history &&
                      parseHistoryToComponentList(getNewProps("MONDAY"))}
                  </div>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                {props.weekDay.toUpperCase() === "TUESDAY" ? (
                  <CurrentDayDiv>Tuesday</CurrentDayDiv>
                ) : (
                  <OtherDayDiv>Tuesday</OtherDayDiv>
                )}
              </CardHeader>
              <CardBody>
                <Fragment>
                  {props.history &&
                    parseHistoryToComponentList(getNewProps("TUESDAY"))}
                </Fragment>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                {props.weekDay.toUpperCase() === "WEDNESDAY" ? (
                  <CurrentDayDiv>Wednesday</CurrentDayDiv>
                ) : (
                  <OtherDayDiv>Wednesday</OtherDayDiv>
                )}
              </CardHeader>
              <CardBody>
                <Fragment>
                  {props.history &&
                    parseHistoryToComponentList(getNewProps("WEDNESDAY"))}
                </Fragment>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                {props.weekDay.toUpperCase() === "THURSDAY" ? (
                  <CurrentDayDiv>Thursday</CurrentDayDiv>
                ) : (
                  <OtherDayDiv>Thursday</OtherDayDiv>
                )}
              </CardHeader>
              <CardBody>
                <Fragment>
                  {props.history &&
                    parseHistoryToComponentList(getNewProps("THURSDAY"))}
                </Fragment>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                {props.weekDay.toUpperCase() === "FRIDAY" ? (
                  <CurrentDayDiv>Friday</CurrentDayDiv>
                ) : (
                  <OtherDayDiv>Friday</OtherDayDiv>
                )}
              </CardHeader>
              <CardBody>
                <Fragment>
                  {props.history &&
                    parseHistoryToComponentList(getNewProps("FRIDAY"))}
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DailyHistory;
