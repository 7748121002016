import React, { Fragment, useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import { useQuery, useMutation } from "@apollo/client";
import { DragDropContext } from "react-beautiful-dnd";
import { ProjectContext } from "GlobalState";
// eslint-disable-next-line no-unused-vars
import { NetworkStatus } from "apollo-client"; // this is necessary to avoid the network error in apollo libray
import Loading, { Error } from "../../components/Loading";
import { STORIES_QUERY } from "../../graphql/queries";
import { CHANGE_STORY_PRIORITY } from "../../graphql/mutations";

import WorkerSelector from "../../components/Worker/WorkerSelector";
import StoryContainerWithDnD from "./components/StoryContainerWithDnD";
import StoryContainerNoDnD from "./components/StoryContainerNoDnD";

import ScrumboardInfoCard from "./components/ScrumboardInfoCard";
import { useAsyncState } from "../../utilities/utils";

// eslint-disable-next-line no-unused-vars

const ScrumBoard = () => {
  const [infoOpen, toggleInfoOpen] = useAsyncState(false);

  const [state] = useContext(ProjectContext);
  const [columns, setState] = useState({
    nextUp: [],
    active: [],
    completed: []
  });

  const { loading, error, data: storiesData, refetch } = useQuery(
    STORIES_QUERY,
    {
      variables: { teamId: state.currentTeamId },
      skip: !state,
      onCompleted: responseData =>
        onCompletedStoriesQueryHandler(responseData.stories),
      fetchPolicy: "no-cache"
    }
  );

  const [changeStoryPriority] = useMutation(CHANGE_STORY_PRIORITY);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const onCompletedStoriesQueryHandler = (stories, teamWorkerId = "all") => {
    const sprintStories = stories.filter(
      story =>
        story.currentSprint && story.currentSprint.id === state.currentSprintId
    );

    let filteredStories = [];

    // filter by team work id
    if (teamWorkerId !== "all") {
      const workerId = parseInt(teamWorkerId, 10);
      for (let i = 0; i < sprintStories.length; i++) {
        const story = sprintStories[i];
        if (story.workersLink.length > 0) {
          if (
            story.workersLink.find(w => w.teamWorker.id === workerId) !==
            undefined
          ) {
            filteredStories.push(story);
          }
        }
      }
    } else {
      filteredStories = [...sprintStories];
    }

    setState({
      nextUp: [...filteredStories.filter(s => s.status === "NEXT_UP")],
      active: [
        ...filteredStories.filter(
          s =>
            s.status === "WORKING" ||
            s.status === "WIP" ||
            s.status === "IN_PROGRESS"
        )
      ],
      completed: [...filteredStories.filter(s => s.status === "COMPLETE")]
    });
  };

  const reorderStories = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async result => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // only swap in the same column for now
    if (destination.droppableId === source.droppableId) {
      // first we need to change order in client side to apply drag and drop
      const sourceStory = columns.nextUp[source.index];
      const destinationStory = columns.nextUp[destination.index];
      const newNextUp = reorderStories(
        columns.nextUp,
        source.index,
        destination.index
      );
      setState({
        ...columns,
        nextUp: newNextUp
      });

      // here we request to change the priorities in the server side
      changeStoryPriority({
        variables: {
          sprintId: sourceStory.currentSprint.id,
          sourceStoryId: sourceStory.id,
          destinationStoryId: destinationStory.id
        }
      }).then(response => {
        // this will synchronize the stories from server in a silent way
        onCompletedStoriesQueryHandler(response.data.changeStoryPriority);
      });
    }
  };

  const applyTeamWorkerFilter = value => {
    onCompletedStoriesQueryHandler(storiesData.stories, value.value);
  };

  const toggleScrumboardInfoCard = () => {
    toggleInfoOpen(!infoOpen);
  };

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <ScrumboardInfoCard
            cardInfoOpen={infoOpen}
            toggleScrumboardInfoCard={toggleScrumboardInfoCard}
          />
        </Col>
      </Row>

      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          <Col>
            <WorkerSelector
              teamId={state.currentTeamId}
              title=""
              includeWholeTeamOption
              defaultValue="all"
              onChange={applyTeamWorkerFilter}
              required
              state={state}
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <StoryContainerWithDnD
              listId="next-up"
              key="nextUp"
              title="Sprint Backlog"
              stories={columns.nextUp}
              refetch={refetch}
              state={state}
            />
          </Col>
          <Col>
            <StoryContainerNoDnD
              listId="active"
              key="active"
              title="In Progress"
              stories={columns.active}
              refetch={refetch}
              state={state}
            />
          </Col>
          <Col>
            <StoryContainerNoDnD
              listId="completed"
              key="completed"
              title="Done"
              stories={columns.completed}
              refetch={refetch}
              state={state}
            />
          </Col>
        </Row>
      </DragDropContext>
    </Fragment>
  );
};

export default ScrumBoard;
