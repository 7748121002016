import React from "react";

const IndividualRewards = () => (
  <div className="box-content">
    <div className="text">
      <table className="table table-hover" style={{ maxWidth: "500px" }}>
        <thead>
          <tr>
            <th className="text-center">Individual Recognition</th>
            <th>Cost to Project</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>$500 gift card</td>
            <td className="text-right">$500</td>
          </tr>
          <tr>
            <td>$250 gift card</td>
            <td className="text-right">$250</td>
          </tr>
          <tr>
            <td>$100 gift card</td>
            <td className="text-right">$100</td>
          </tr>
          <tr>
            <td>VIP parking</td>
            <td className="text-right">$300</td>
          </tr>
          <tr>
            <td>Restaurant gift card</td>
            <td className="text-right">$150</td>
          </tr>
          <tr>
            <td>Coffee gift card</td>
            <td className="text-right">$50</td>
          </tr>
          <tr>
            <td>Company Logo Trinkets</td>
            <td className="text-right">$25</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default IndividualRewards;
