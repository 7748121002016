import React from "react";
import { Card, Label, Input, Collapse } from "reactstrap";
import styled from "styled-components";
import CardHeader from "../../../components/styled/CardHeader";
import ScrollCardBody from "../../../components/ScrollCardBody";

const StyledCardHeader = styled(CardHeader)`{
    display: flex;
    justify-content: space-between;
  
    .switch {
      margin-left: auto;
      margin-bottom: 0;
    }
  
    .switch .switch-input:not(:checked) + .switch-label {
      background-color: #a4b7c1;
      border-color: #869fac;
    }
    .switch .switch-input:not(:checked) + .switch-label + .switch-handle {
      border-color: #869fac;
    }
  }
}

`;
const ChartInfo = props => (
  <Card className="Card-info">
    <StyledCardHeader>
      <span style={{ marginRight: "5px" }}>
        <b>Chart Info</b>
      </span>
      <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
          onClick={() => props.toggleCard()}
          defaultChecked={props.cardInfoOpen}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
    </StyledCardHeader>
    <Collapse isOpen={props.cardOpen}>
      <ScrollCardBody>
        <p>
          The sprint reports include a sprint burn down chart. It is a visual representation of the work for the scrum
          team as it relates to a specific sprint. The sprint burn down chart is represented in terms of story points.
          It represents the rate at which the work is being completed and how much remains to be done. For team members
          and stakeholders, it serves as a good information radiator and makes work progress transparent and visible to
          all. The sprint burn down chart can be represented in terms of person-hours or story points.
        </p>
      </ScrollCardBody>
    </Collapse>
  </Card>
);

export default ChartInfo;
