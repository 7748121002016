/* eslint-disable import/prefer-default-export */
import React from "react";
import ContentDiv from "../../../../components/styled/Reports/ContentDiv";
import people from "../../../../static/images/people";

/*
0 = worker
1 = story


*/

export const Reassign = props => {
  if (!props || !props.properties || props.properties.length < 2) {
    return <ContentDiv />;
  }
  const worker = {
    name: props.properties.find(p => p.itemKey === "worker").itemValue
  };
  const story = props.properties.find(p => p.itemKey === "story").itemValue;

  return (
    <ContentDiv>
      <img
        width="25"
        height="25"
        src={people[`${worker.name.replace(/\s/g, "").replace(/\./g, "")}`]}
        alt={worker.name}
        id={`${worker.name
          .replace(/\s/g, "")
          .replace(/\./g, "")}-story-profile`}
      />
      &nbsp;&nbsp;{worker.name}&nbsp;is now working on:&nbsp;{" "}
      <strong>{story}</strong>
    </ContentDiv>
  );
};
