import React from "react";
import StyledDiv from "./StyledDiv";

const Stakeholders = () => (
  <StyledDiv>
    <h3>Stakeholders</h3>
    <p>
      <b>Michael Russell</b>, VP Human Resources, <b>Sponsor</b>
      <br />
      hard-charging, smart, doesn’t provide any more info than necessary,
      suspicious, can earn his respect and trust over time
    </p>
    <p>
      <b>Phillip Bowden</b>, Manager of Payroll, <b>Customer</b>
      <br />
      very formal, very smart, well-read, and very technical
    </p>
    <p>
      <b>Susan McFee</b>, Manager of Benefits, <b>Customer</b>
      <br />
      not happy that Farley was promoted over her, has an excellent balance of
      work and home life, top HR person for many years, well-liked in the org
    </p>
    <p>
      <b>Farley Gnu</b>, Director of Benefits and Payroll, <b>Product Owner</b>
      <br />
      new to HR, very respected by the most in the organization, has an IT
      background
    </p>
  </StyledDiv>
);

export default Stakeholders;
