import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import classnames from "classnames";
// import styled from "styled-components";
import scrum from "../../static/pdf/2017-Scrum-Guide-US.pdf";

const ProjectReferenceModal = ({ toggleModal }) => (
  // const [activeTab, toggleTab] = useState("project-charter");
  // const [collapse, toggleCollapse] = useState(1);

  <Modal isOpen fade={false} centered toggle={toggleModal} size="lg">
    <ModalHeader>Scrum Guide</ModalHeader>
    <ModalBody>
      {/* <StyledRow>
          <Col>Uniworld Alliance Prototype Project - Company Reference Documents</Col>
          <Col>
            If you can't find an answer here or in the Help Documentation, please{" "}
            <a href="mailto:support@simulationpl.com">contact us</a> for assistance.
          </Col>
        </StyledRow>
        <StyledRow>
          <Col md="4">
            {helpCategories.map(({ title, key, topics }) => (
              <Card key={key}>
                <CardHeader onClick={() => toggleCollapse(key)} data-event={key}>
                  {title}
                </CardHeader>
                <Collapse isOpen={collapse === key}>
                  <CardBody className="p-0">
                    <ListGroup>
                      {topics.map(topic => (
                        <ListGroupItem
                          className={classnames({
                            active: activeTab === slugify(topic)
                          })}
                          onClick={() => {
                            toggleTab(slugify(topic));
                          }}
                        >
                          {topic}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </CardBody>
                </Collapse>
              </Card>
            ))}
          </Col>
          <Col>
            <TabContent activeTab={activeTab}> */}
      {/* The tabId needs to match the return value from slugify(topic) */}
      {/* <TabPane tabId="project-charter">
                <ProjectDocumentationProjectCharter />
              </TabPane>
            </TabContent>
          </Col>
        </StyledRow> */}
      <div className="pdfViewer">
        <iframe src={scrum} width="100%" height="800px" title="Spl"></iframe>
      </div>
    </ModalBody>
    <ModalFooter>
      <Button outline color="primary" onClick={toggleModal}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
);
export default ProjectReferenceModal;
