import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SprintAction from "./SprintAction";

const ActiveStoriesTable = props => {
  const { SearchBar, ClearSearchButton } = Search;

  const formatRequiredStories = cell => {
    if (cell) {
      return "Yes";
    }
    return "No";
  };

  const currentSprintFormatter = cell => {
    if (cell === null || cell === undefined) {
      return "Not Selected";
    }

    return <span>Sprint {cell.sprintNumber}</span>;
  };

  const menuFormatter = (cell, row) => {
    let disabled = false;
    if (row.status === "COMPLETE") {
      disabled = true;
    }
    return (
      <SprintAction
        refetch={props.refetchStories}
        data={props.sprints.sort((a, b) => a.sprintNumber - b.sprintNumber)}
        row={row}
        id={row.id}
        disabled={disabled}
        placement="left"
        sprintNumber={props.sprintNumber}
        sprintId={props.sprintId}
      />
    );
  };

  const storyNameToggleFormat = (cell, row) => {
    return (
      <Button
        onClick={e => {
          e.preventDefault();
          props.toggle(row);
        }}
        color="link"
      >
        <div style={{ fontWeight: "bolder", color: "darkblue" }}>{cell}</div>
      </Button>
    );
  };

  const columns = [
    {
      dataField: "businessPriority",
      text: "Order",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "10%" }
    },
    {
      dataField: "storyName",
      formatter: storyNameToggleFormat,
      text: "Story Name",
      sort: true,
      headerStyle: { width: "35%" }
    },
    {
      dataField: "requiredStory",
      formatter: formatRequiredStories,
      text: "Required for MVP",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "15%" }
    },
    {
      dataField: "storyPoints",
      text: "Story Points",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "10%" }
    },
    {
      dataField: "currentSprint",
      formatter: currentSprintFormatter,
      text: "Sprint",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "15%" }
    },
    {
      dataField: "id",
      formatter: menuFormatter,
      text: "Select Story",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "15%" }
    }
  ];

  const sortedStories = props.stories.sort(
    (a, b) => a.businessPriority - b.businessPriority
  );

  return (
    <ToolkitProvider
      keyField="id"
      id="tkp-active"
      data={sortedStories}
      columns={columns}
      search
    >
      {tableProps => {
        const tProps = { ...tableProps };
        tProps.searchProps.tableId = "active";
        return (
          <Fragment>
            <Container fluid>
              <Row>
                <Col style={{ paddingRight: 0, textAlign: "right" }}>
                  <SearchBar
                    key="sb-active"
                    {...tableProps.searchProps}
                    className="searchBar"
                    id="sb-active"
                  />
                  <ClearSearchButton
                    {...tableProps.searchProps}
                    className="clearSearchButton"
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <BootstrapTable
                    id="active-table"
                    {...tableProps.baseProps}
                    condensed
                    striped
                    bootstrap4
                  />
                </Col>
              </Row>
            </Container>
          </Fragment>
        );
      }}
    </ToolkitProvider>
  );
};

export default ActiveStoriesTable;
