import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import styled from "styled-components";
import Training from "./Training";
import IndividualRewards from "./IndividualRewards";
import TeamRewards from "./TeamRewards";
import ScrumEvents from "./ScrumEvents";

const Layout = styled.div`
  margin-bottom: 1.5rem;

  .nav-tabs .nav-item {
    z-index: 1;
  }

  .tab-content {
    border-color: #c2cfd6;
    overflow-y: scroll;
    max-height: 400px;
  }

  .col {
    max-height: 449px;
  }
`;

const tabs = [
  {
    id: "1",
    title: "Agile Training"
  },
  {
    id: "2",
    title: "Individual Rewards",
    type: "INDVREWARD"
  },
  {
    id: "3",
    title: "Team Rewards",
    type: "TEAMREWARD"
  },
  {
    id: "4",
    title: "Scrum Events",
    type: "SCRUMEVENTS"
  }
];

const InteractionTabs = () => {
  const [activeTab, setTab] = useState("1");
  return (
    <Layout>
      <Nav tabs>
        {tabs.map(tab => (
          <NavItem key={tab.id}>
            <NavLink
              className={classnames({ active: activeTab === tab.id })}
              onClick={() => {
                setTab(tab.id);
              }}
            >
              <b>{tab.title}</b>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            {/* TODO: Samir, Add the quick reference content for each tab here. */}
            <Col>
              <Training />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <IndividualRewards />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <TeamRewards />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col>
              <ScrumEvents />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default InteractionTabs;
