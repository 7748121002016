import React, { useContext } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";
import Worker from "../../../components/Worker/Worker";
import { Error } from "../../../components/Loading";
import { REMOVE_WORKERFROMSTORY_MUTATION } from "../../../graphql/mutations";
import {
  STORIES_QUERY,
  TEAM_QUERY,
  MEMBER_LOAD_QUERY
} from "../../../graphql/queries";
import { ProjectContext } from "../../../GlobalState";

const AssignedWorkerContainer = ({
  okToAssignWorkers,
  okToUnassignWorkers,
  type,
  skillNumber,
  togglePopOver,
  storyId,
  staffingRequired,
  refetch
}) => {
  const [state] = useContext(ProjectContext);

  const { error: errorS, data: storyData } = useQuery(STORIES_QUERY, {
    variables: { teamId: state.currentTeamId }
  });

  const queriesToRefetch = [
    {
      query: STORIES_QUERY,
      variables: { teamId: state.currentTeamId }
    },
    {
      query: TEAM_QUERY,
      variables: { teamId: state.currentTeamId }
    },
    {
      query: MEMBER_LOAD_QUERY,
      variables: {
        teamId: state.currentTeamId,
        sprintId: state.currentSprintId
      }
    }
  ];

  const [removeWorkerFromStory, { error: mutationError }] = useMutation(
    REMOVE_WORKERFROMSTORY_MUTATION,
    {
      refetchQueries: queriesToRefetch
    }
  );

  if (errorS) return <Error error={errorS} />;
  if (mutationError) return <Error error={mutationError} />;

  const removeWorkerHandler = worker => {
    removeWorkerFromStory({
      variables: {
        storyId,
        workerId: parseInt(worker.id, 10)
      }
    }).then(() => {
      refetch();
    });
  };

  const showWorkerListHandler = () => {
    togglePopOver(`${type}sPopoverOpen`);
  };

  const currentStory =
    storyData && storyData.stories.find(s => s.id === storyId);

  const currentAssigns =
    currentStory &&
    currentStory.workersLink
      .map(w => w.teamWorker)
      .filter(t => t.skillNumber === skillNumber);
  return (
    <StyledContainer>
      <>
        {storyData &&
          storyData.stories
            .find(s => s.id === storyId)
            .workersLink.map(w => w.teamWorker)
            .filter(t => t.skillNumber === skillNumber)
            .map(worker => (
              <RemoveWorkerButton
                disabled={
                  currentStory.status === "COMPLETE" || !okToUnassignWorkers
                }
                onClick={() => removeWorkerHandler(worker)}
                key={worker.id}
              >
                <Worker workerData={worker} imageOnly />
              </RemoveWorkerButton>
            ))}
      </>

      <AddButton
        id={`${type}Popover`}
        outline
        disabled={
          (currentAssigns && currentAssigns.length >= staffingRequired) ||
          (currentStory && currentStory.status === "COMPLETE") ||
          !okToAssignWorkers
        }
        color="primary"
        className="add-resource"
        onClick={() => showWorkerListHandler()}
      >
        <i className="fa fa-user-plus" />
      </AddButton>
    </StyledContainer>
  );
};

export default AssignedWorkerContainer;

const StyledContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  > * {
    background-color: #5378ad;
    color: white;
    border-radius: 50%;
    border: 1px solid #5378ad;
    margin-right: 5px;
    display: block;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    padding: 0;
  }
`;

const RemoveWorkerButton = styled(Button)`
  border: 0;
  background-color: transparent;
  position: relative;
  overflow: auto;

  &:after {
    content: "\f00d";
    font-family: "fontAwesome";
    background-color: var(--secondary-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 0;
    }

    img {
      display: inline-block;
    }
  }
`;

const AddButton = styled(Button)`
  &:disabled {
    display: none;
  }
`;
