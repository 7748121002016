import React from "react";

const Training = () => (
  <div id="Training">
    <div>
      <p>
        Uniworld offers a number of opportunities for coaching agile teams on
        principles, practices and team dynamics. Agile coaches offer coaching
        and mentoring in multiple formats that are designed to help team members
        in the areas of teamwork, acquiring knowledge and technical competency.
        Coaching boot camps are offered for teams to promote teamwork, get
        alignment and promote agile/lean values.
      </p>
      <p>The following coaching opportunities are available:</p>
      <strong>Team Training</strong>
      <ul>
        <li>
          <a href="#t1">Agile Bootcamp for Teams (Tue)</a>
        </li>
        <li>
          <a href="#t2">Scrum Master Training ( Thur, Fri)</a>
        </li>
        <li>
          <a href="#t3">Product Owner Training (Tue, Wed)</a>
        </li>
        <li>
          <a href="#t4">Story Mapping Workshop ( Thur)</a>
        </li>
        <li>
          <a href="#t5">Agile Engineering Best Practices ( Mon )</a>
        </li>
        <li>
          <a href="#t6">Communications and Interpersonal Skills ( Mon, Tue)</a>
        </li>
      </ul>
      <strong id="t1">Agile Bootcamp for Teams ( 1-day)</strong>
      <p>
        This is a one day immersion in agile principles, practices and lean
        methods for teams. We recommend entire teams to attend this session to
        get the most benefit out of the bootcamp. Specific topics include:
        history of agile, agile manifesto, agile principles, lean concepts of
        flow, reducing waste and shortening cycle time. The bootcamp will also
        cover the basics of Scrum and Kanban. The cost of the bootcamp is $500
        per participant.
        <br />
      </p>
      <strong id="t2">Scrum Master Training (2-days)</strong>
      <p>
        This interactive and hands on training will include the basics of the
        Scrum framework, the roles, artifacts, ceremonies and Scrum values. This
        training is ideal for aspiring Scrum Masters as well as practicing Scrum
        Masters looking to refresh their knowledge. Specific focus includes the
        role of a Scrum Master to the team, organization and the larger agile
        community. This course will be taught by a certified agile coach and the
        participants have the option to achieve a Scrum Master certification.
        The cost for the Scrum Master training is $900 per participant.
      </p>
      <strong id="t3">Product Owner Training (2-days)</strong>
      <p>
        The Product Owner is responsible for pointing the team in the right
        direction. In this workshop, the participants will learn how to define
        the product backlog, apply techniques to prioritize them backlog,
        develop a product roadmap and understand the Scrum Framework. This
        course will be taught by a certified agile coach and the participants
        have the option to achieve a Scrum Master certification. The cost for
        the Product Owner training is $800 per participant.
      </p>
      <strong id="t4">Story Mapping Workshop (1-day)</strong>
      <p>
        In this one-day story mapping workshop, participants will learn how to
        facilitate and conduct a workshop for their teams. Participants will
        learn about the 4-step process to run a story mapping workshop. The four
        steps are - framing the context, mapping the big picture, adding user
        stories, and mapping out releases. Having a product owner or business
        partner at the story mapping workshop is recommended. The cost for the
        Story Mapping Workshop is $400 per participant.
      </p>
      <strong id="t5">Agile Engineering Best Practices (1-day)</strong>
      <p>
        Facilitated by a technical agile coach, this workshop focuses on
        introducing and enhancing engineering best practices. Specific topics
        include continuous integration and deployment, DevOps, automated
        testing, Test Driven Development and extreme programming practices. The
        cost for the training is $600 per participant.
      </p>
      <strong id="t6">Communication and Interpersonal Skills (2-day)</strong>
      <p>
        This workshop is intended for improving a participant&#39;s
        communication and interpersonal skills through practical exercises and
        tool use. Specific topics, skills and tools include: active listening,
        communication styles, conflict management, diversity, negotiation,
        Myers-Briggs Type Indicator (MBTI), and creativity. This is a 2 day
        workshop and the cost is $750 per participant.
      </p>
    </div>
  </div>
);

export default Training;
