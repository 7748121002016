import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import styled from "styled-components";
import gql from "graphql-tag";
import PerformanceItem from "./components/PerformanceItem";
import Worker from "./Worker";
import { Error } from "../Loading";

const WORKER_PROFILE_QUERY = gql`
  query WORKER_PROFILE_QUERY($teamId: Int!, $workerId: Int!) {
    workers(teamId: $teamId, where: { id: { eq: $workerId } }) {
      id
      name
      skill {
        skillName
      }
      education
      experience
      observations
      objectives
      performance_1
      performance_2
      performance_3
      performance_4
      performance_5
      performance_6
      performance_7
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-header {
    align-items: center !important;
  }

  .modal-title > div {
    padding: 0;
  }
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTable = styled(Table)`
  font-size: 0.7rem;

  &.table td {
    padding: 0.5rem;
  }
`;

const DataBlock = styled.div`
  padding: 10px;
`;

const DivCentered = styled.div`
  text-align: center;
`;

const WorkerModal = ({ toggleModal, isOpen, workerId, teamId }) => {
  const { data, loading, error } = useQuery(WORKER_PROFILE_QUERY, {
    variables: { workerId, teamId },
    skip: !workerId
  });

  const renderLoadingModal = () => {
    return (
      <StyledModal
        isOpen={isOpen}
        toggle={toggleModal}
        fade={false}
        centered
        size="lg"
      >
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody>
          <DivCentered>Loading...</DivCentered>
        </ModalBody>
      </StyledModal>
    );
  };

  if (loading) return renderLoadingModal();
  if (error) return <Error error={error} />;

  const workerData = {};
  workerData.teamWorker = data.workers[0];

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={toggleModal}
      fade={false}
      centered
      size="lg"
    >
      <Fragment>
        <ModalHeader toggle={toggleModal}>
          <Worker workerData={workerData.teamWorker} />
        </ModalHeader>
        <ModalBody>
          <DataBlock>
            <h5>Observations</h5>
            {workerData.teamWorker.observations}
          </DataBlock>
          <Layout>
            <DataBlock>
              <h5>Performance Review</h5>
              <PerformanceItem
                start="Completes tasks late"
                end="Completes tasks early"
                value={workerData.teamWorker.performance_1}
              />
              <PerformanceItem
                start="Low quality work"
                end="High quality work"
                value={workerData.teamWorker.performance_2}
              />
              <PerformanceItem
                start="Needs direction"
                end="Works well without direction"
                value={workerData.teamWorker.performance_3}
              />
              <PerformanceItem
                start="Likes to work in teams"
                end="Likes to work alone"
                value={workerData.teamWorker.performance_4}
              />
              <PerformanceItem
                start="Weak communication"
                end="Strong communication"
                value={workerData.teamWorker.performance_5}
              />
              <PerformanceItem
                start="Open to challenges"
                end="Stays within comfort zone"
                value={workerData.teamWorker.performance_6}
              />
              <PerformanceItem
                start="Abstract thinker"
                end="Detailed thinker"
                value={workerData.teamWorker.performance_7}
              />
            </DataBlock>
            <div>
              <DataBlock>
                <h5>Education</h5>
                <StyledTable>
                  <tbody>
                    <tr>
                      <td colSpan="2">{workerData.teamWorker.education}</td>
                    </tr>
                  </tbody>
                </StyledTable>
              </DataBlock>
              <DataBlock>
                <h5>Experience</h5>
                <StyledTable>
                  <tbody>
                    <tr>
                      <td colSpan="2">{workerData.teamWorker.experience}</td>
                    </tr>
                  </tbody>
                </StyledTable>
              </DataBlock>
            </div>
          </Layout>
          <DataBlock>
            <h5>Objectives</h5>
            <p>{workerData.teamWorker.objectives}</p>
          </DataBlock>
        </ModalBody>
      </Fragment>
    </StyledModal>
  );
};

export default WorkerModal;
