import React from "react";
import { Card, Label, Input, Collapse } from "reactstrap";
import styled from "styled-components";
import CardHeader from "../../../components/styled/CardHeader";
import ScrollCardBody from "../../../components/ScrollCardBody";

const StyledCardHeader = styled(CardHeader)`{
    display: flex;
    justify-content: space-between;
  
    .switch {
      margin-left: auto;
      margin-bottom: 0;
    }
  
    .switch .switch-input:not(:checked) + .switch-label {
      background-color: #a4b7c1;
      border-color: #869fac;
    }
    .switch .switch-input:not(:checked) + .switch-label + .switch-handle {
      border-color: #869fac;
    }
  }
}

`;
const InfoCard = props => (
  <Card className="Card-info">
    <StyledCardHeader>
      <span style={{ marginRight: "5px" }}>
        <b>Run Sprint Info</b>
      </span>
      <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
          onClick={() => props.toggleInfoCard()}
          defaultChecked={props.cardInfoOpen}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
    </StyledCardHeader>
    <Collapse isOpen={props.cardInfoOpen}>
      <ScrollCardBody>
        <p>
          When ready, click on the &quot;Run Sprint&quot; button. This will
          execute the sprint.
        </p>
        <p>
          When responding to the pop-up communications in the Communications
          window, consider the anticipated results of each response before
          selecting your desired response.
        </p>
        <p>
          At the end of the week you will be automatically redirected to the
          Reports module; carefully look over all the reports. The reports are
          explained in detail in the Help Documentation.
        </p>
        <p>
          <em>
            If at any time your sprint execution gets interrupted, just come
            back to the page and click the Run Sprint button. Your work will be
            saved and you can pick up where you left off before the
            interruption.
          </em>
        </p>
      </ScrollCardBody>
    </Collapse>
  </Card>
);

export default InfoCard;
