import React from "react";
import { initialCap } from "../../../../utilities/utils";

/*
0 weekday
*/
export const Weekday = props => (
  <h6 style={{ marginTop: "20px", marginBottom: "20px" }}>
    *****&nbsp;&nbsp;{initialCap(props.properties[0].itemValue)}&nbsp;&nbsp;*****
  </h6>
);
