import React from "react";
import { Link } from "react-router-dom";
import { Jumbotron, Button } from "reactstrap";
import { Browser } from "react-kawaii";
import styled from "styled-components";

import CenteredLayout from "../../components/CenteredLayout";

const StyledJumbotron = styled(Jumbotron)`
  max-width: 600px;
  width: 100%;

  h1 {
    font-size: 5rem;
    font-weight: 600;
  }
`;

const Page404 = ({ location }) => (
  <CenteredLayout>
    <StyledJumbotron>
      <Browser size={100} mood="shocked" color="var(--tertiary-color)" />
      <h1>404</h1>
      <p>
        Looks like there's nothing at <strong>{location.pathname}</strong>
      </p>
      <Button tag={Link} color="primary" to="/">
        Go home
      </Button>
    </StyledJumbotron>
  </CenteredLayout>
);

export default Page404;
