/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import Loading, { Error } from "../../../components/Loading";
import Worker from "../../../components/Worker/Worker";
import {
  STORIES_QUERY,
  TEAM_QUERY,
  MEMBER_LOAD_QUERY
} from "../../../graphql/queries";
import { ASSIGN_WORKERTOSTORY_MUTATION } from "../../../graphql/mutations";
import { ProjectContext } from "../../../GlobalState";

const AvailableWorkerContainer = props => {
  const [state] = useContext(ProjectContext);

  const { data: teamData, loading: loadingT, error: errorT } = useQuery(
    TEAM_QUERY,
    {
      variables: { teamId: state.currentTeamId },
      skip: !state || state.currentTeamId < 1
    }
  );
  const queriesToRefetch = [
    {
      query: STORIES_QUERY,
      variables: { teamId: state.currentTeamId },
      skip: !state || !state.currentTeamId || state.currentTeamId < 1
    },
    {
      query: TEAM_QUERY,
      variables: { teamId: state.currentTeamId },
      skip: !state || state.currentTeamId < 1
    },
    {
      query: MEMBER_LOAD_QUERY,
      variables: {
        teamId: state.currentTeamId,
        sprintId: state.currentSprintId
      },
      skip: !state || state.currentTeamId < 1
    }
  ];

  const [
    assignWorkerToStory,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(ASSIGN_WORKERTOSTORY_MUTATION, {
    refetchQueries: queriesToRefetch
  });

  if (mutationLoading || loadingT) return <Loading text="processing..." />;
  if (mutationError) return <Error error={mutationError} />;

  if (errorT) return <Error error={errorT} />;

  const { skills } = teamData.team;

  const {
    popoverOpen,
    target,
    type,
    skillNumber,
    togglePopOver,
    storyId,
    refetch
  } = props;

  const filterWorkers = () => {
    const workersFiltered = teamData.team.teamWorkers.filter(
      item => item.status === "ONTEAM" && item.skillNumber === skillNumber
    );

    const notTheSameWorkers = [];
    // we don't want the same workers that already has been assigned to the story
    for (let i = 0; i < workersFiltered.length; i++) {
      const alreadyAssigned =
        workersFiltered[i].storyAssignments.filter(s => s.story.id === storyId)
          .length > 0;
      if (!alreadyAssigned) {
        notTheSameWorkers.push(workersFiltered[i]);
      }
    }

    return notTheSameWorkers;
  };

  const assignWorkerHandler = teamWorker => {
    assignWorkerToStory({
      variables: {
        storyId,
        workerId: parseInt(teamWorker.id, 10)
      }
    }).then(() => {
      refetch();
      togglePopOver("developersPopoverOpen");
    });
  };

  return (
    <Popover
      placement="bottom"
      isOpen={popoverOpen}
      trigger="legacy"
      target={target}
      toggle={() => togglePopOver("developersPopoverOpen")}
    >
      <PopoverHeader>Available {type}s</PopoverHeader>
      <StyledPopoverBody>
        {filterWorkers().map(teamWorker => {
          return (
            <div
              key={teamWorker.id}
              onClick={() => assignWorkerHandler(teamWorker)}
            >
              <Worker workerData={teamWorker} skills={skills} />
            </div>
          );
        })}
      </StyledPopoverBody>
    </Popover>
  );
};

export default AvailableWorkerContainer;

const StyledPopoverBody = styled(PopoverBody)`
  &.popover-body {
    max-height: 250px;
    overflow-y: auto;
    padding: 0;
  }
  > * {
    width: 100%;
    &:hover {
      background-color: #fafafa;
    }
  }
`;
