/* eslint-disable no-case-declarations */
import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  ListGroupItem,
  ListGroup
} from "reactstrap";
import classnames from "classnames";
import gql from "graphql-tag";
import TeamMembers from "static/images/TeamMembers.png";
import orgChart from "static/images/orgChart.png";
import { useAsyncState } from "utilities/utils";
import WorkerProfile from "components/Overview/Content/WorkerProfile";
import DefinitionOfDone from "components/Overview/DefinitionOfDone";
import WelcomeOverview from "components/Overview/WelcomeOverview";
import ProductGoal from "components/Overview/ProductGoal";
import Stakeholders from "components/Overview/Stakeholders";
import TeamWorkingAgreement from "components/Overview/TeamWorkingAgreement";
import StyledDiv from "components/Overview/StyledDiv";

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`;

const WORKER_PROFILE_QUERY = gql`
  query WORKER_PROFILE_QUERY($productId: Int!) {
    simWorkers(productId: $productId) {
      id
      firstName
      lastName
      workerName
      workerTitle
      education
      experience
      observations
      objectives
      performance_1
      performance_2
      performance_3
      performance_4
      performance_5
      performance_6
      performance_7
    }
  }
`;

const OrientationModal = props => {
  const { data: workerData } = useQuery(WORKER_PROFILE_QUERY, {
    variables: { productId: 1 }
  });
  // const [profileList, setProfileList] = useAsyncState([]);
  const [selectedKey, setSelectedKey] = useAsyncState("welcome");
  const [collapse, setCollapse] = useAsyncState(1);
  // const [selectedIndex, setSelectedIndex] = useAsyncState(1);
  const profileList = useMemo(() => {
    const pList = [];
    if (
      workerData &&
      workerData.simWorkers &&
      workerData.simWorkers.length > 0
    ) {
      const keys = Object.keys(workerData.simWorkers);
      for (let r = 0; r < keys.length; r++) {
        const worker = workerData.simWorkers[r];
        pList.push({
          menuTitle: worker.workerName,
          refKey: worker.workerName,
          index: r + 1
        });
      }
      return pList;
    }
    return [];
  }, [workerData]);

  // Render the correct components according to the productId (project)
  const renderDocument = () => {
    switch (selectedKey) {
      case "title":
        break;
      case "welcome":
        return <WelcomeOverview />;

      case "product-goal":
        return <ProductGoal />;

      case "org-chart":
        return (
          <img
            src={orgChart}
            style={{ width: "100%" }}
            alt="organization chart"
          />
        );

      case "stakeholders":
        return <Stakeholders />;

      case "team-members":
        return (
          <StyledDiv>
            <img
              src={TeamMembers}
              style={{ width: "100%" }}
              alt="team members"
            />
            <p>
              See Individual Profiles for a description of each team member.
            </p>
          </StyledDiv>
        );
      case "team-working-agreement":
        return <TeamWorkingAgreement />;
      case "definition-of-done":
        return <DefinitionOfDone />;
      default:
        const worker = workerData.simWorkers.find(
          wrkr => wrkr.workerName === selectedKey
        );
        if (worker && worker.workerName) {
          return <WorkerProfile worker={worker} />;
        }
        return <span />;
    }
    return <span />;
  };

  const changeSelectedReport = rk => {
    setSelectedKey(rk);
  };

  const toggleCollapse = e => {
    const { event } = e.target.dataset;
    setCollapse(collapse === Number(event) ? 0 : Number(event));
  };

  return (
    <Modal
      className="PdfPopup"
      size="lg"
      isOpen
      fade={false}
      centered
      toggle={props.toggleModal}
    >
      <ModalHeader toggle={props.toggleModal}>Overview</ModalHeader>
      <ModalBody>
        <StyledRow>
          <Col md="3">
            <Card className="ListGroup-Card" key={1}>
              <CardHeader
                onClick={toggleCollapse}
                data-event={1}
                className={classnames({
                  active: referenceList.some(row => row.refKey === selectedKey)
                })}
              >
                Project Documentation
              </CardHeader>
              <Collapse isOpen={collapse === 1}>
                <CardBody className="p-0">
                  <ListGroup>
                    {referenceList.map(row => (
                      <ListGroupItem
                        key={`item-${row.refKey}`}
                        tag="button"
                        className={classnames({
                          active: selectedKey === row.refKey
                        })}
                        value={row.index}
                        onClick={() => changeSelectedReport(row.refKey)}
                      >
                        {row.menuTitle}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Collapse>
            </Card>

            <Card className="ListGroup-Card" key={4}>
              <CardHeader
                onClick={toggleCollapse}
                data-event={4}
                className={classnames({
                  active: profileList.some(row => row.refKey === selectedKey)
                })}
              >
                Individual Profiles
              </CardHeader>
              <Collapse isOpen={collapse === 4}>
                <CardBody className="p-0">
                  <ListGroup>
                    {profileList.map(row => (
                      <ListGroupItem
                        key={`item-${row.refKey}`}
                        tag="button"
                        className={classnames({
                          active: selectedKey === row.refKey
                        })}
                        value={row.index}
                        onClick={() => changeSelectedReport(row.refKey)}
                      >
                        {row.menuTitle}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
          <Col md="9" className="row-Documentation_Content">
            {renderDocument()}
          </Col>
        </StyledRow>
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={() => props.toggleModal("")}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrientationModal;

const referenceList = [
  {
    menuTitle: "Welcome",
    refKey: "welcome",
    index: 1
  },
  {
    menuTitle: "Product Goal",
    refKey: "product-goal",
    index: 2
  },
  {
    menuTitle: "Org Chart",
    refKey: "org-chart",
    index: 3
  },
  {
    menuTitle: "Stakeholders",
    refKey: "stakeholders",
    index: 4
  },
  {
    menuTitle: "Team Working Agreement",
    refKey: "team-working-agreement",
    index: 5
  },
  {
    menuTitle: "Definition of Done",
    refKey: "definition-of-done",
    index: 6
  },
  {
    menuTitle: "Team Members",
    refKey: "team-members",
    index: 7
  }
];
