/* eslint-disable react/no-unused-state */
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { ProjectContext } from "GlobalState";
import { HISTORY_QUERY } from "../../graphql/queries";
import Loading, { Error } from "../../components/Loading";
import ReportTabs from "./ReportTabs";

const Reports = props => {
  const [state] = useContext(ProjectContext);

  const { loading, error, data } = useQuery(HISTORY_QUERY, {
    variables: { teamId: state.currentTeamId },
    skip: !state || !state.currentTeamId,
    fetchPolicy: "network-only"
  });
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  const { weekNumber } =
    data.historyItems.length !== 0
      ? data.historyItems[data.historyItems.length - 1]
      : 0;
  const sprintNumber = state.currentSprintNumber;
  if (!data || !data.team) {
    return <Loading />;
  }
  return (
    <div>
      <ReportTabs
        currentUser={props.currentUser}
        activeTeam={state}
        activeSprint={sprintNumber}
        activeWeek={weekNumber}
        history={data.historyItems}
      />
    </div>
  );
};

export default Reports;
