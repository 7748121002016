/* eslint-disable import/prefer-default-export */
import React from "react";
import ContentDiv from "../../../../components/styled/Reports/ContentDiv";

/*
 worker
 title
*/
export const Absent = props => {
  if (!props || !props.properties || props.properties.length < 2) {
    return <ContentDiv />;
  }
  const worker = props.properties.find(t => t.itemKey === "worker").itemValue;
  const title = props.properties.find(t => t.itemKey === "title").itemValue;

  return (
    <ContentDiv>
      <h5>
        <span role="img" aria-label="ghost">
          👻
        </span>
        {worker},&nbsp;
        <small>{title}, was absent</small>
      </h5>
    </ContentDiv>
  );
};
