import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Col, Row, Button, Card, CardHeader, CardBody } from "reactstrap";
import ReactToPrint from "react-to-print";
import { ProjectContext } from "GlobalState";
import VelocityTrendChart from "./components/VelocityTrendChart";
import UserHelper from "../../UserHelper";

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;

const VelocityTrend = ({ currentUser }) => {
  const [elementRef, setElementRef] = useState(null);
  const [state] = useContext(ProjectContext);
  const today = new Date();
  const date = `${today.getFullYear()}-${today.getMonth() +
    1}-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  // eslint-disable-next-line no-unused-vars
  let documentTitle = null;

  const afterPrint = () => {
    documentTitle = document.title;
  };

  const beforePrint = newTitle => {
    documentTitle = newTitle;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col md="3"></Col>
          <Col>
            <span className="float-sm-right">
              <ReactToPrint
                trigger={() => (
                  <Button color="secondary">
                    <i className="far fa-file-pdf" /> Print
                  </Button>
                )}
                content={() => elementRef}
                onBeforePrint={() => beforePrint("Velocity Trend")}
                onAfterPrint={() => afterPrint()}
                documentTitle={`${("Velocity Trend",
                "_")}${UserHelper.firstName(
                  currentUser,
                  state
                )}${UserHelper.lastName(
                  currentUser,
                  state
                )}_${date}_${time}_${UserHelper.teamId(currentUser, state)}`}
              />
            </span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {state.currentWeekNumber > 1 && (
          <PrintDiv
            id={`h${state.currentSprintNumber.toString()}`}
            ref={el => {
              setElementRef(el);
            }}
          >
            <VelocityTrendChart
              teamId={UserHelper.teamId(currentUser, state)}
              user={`${UserHelper.firstName(
                currentUser,
                state
              )} ${UserHelper.lastName(currentUser, state)}`}
              runNumber={state.runSequenceNumber}
            />
          </PrintDiv>
        )}
        {state.currentWeekNumber === 1 && <span>No data yet.</span>}
      </CardBody>
    </Card>
  );
};

export default VelocityTrend;
