import React from "react";
import moment from "moment";
// import gql from "graphql-tag";
import Chart from "react-google-charts";
// import { useQuery } from "@apollo/client";
import { Row, Col } from "reactstrap";
// import Loading, { Error } from "../../../components/Loading";
import { getCurrentDate } from "../../../utilities/utils";
import splLogo from "../../../static/images/message/spl-logo.jpg";

// const GET_CUMULATIVE_FLOW = gql`
//   query GET_CUMULATIVE_FLOW($teamId: Int!) {
//     cumulativeFlow(teamId: $teamId) {
//       entries {
//         runDay
//         storiesInBacklog
//         storiesNotStarted
//         storiesInProgress
//         completedStories
//       }
//     }
//   }
// `;

const CumulativeFlowDiagram = ({ teamId, runNumber, user, chartData }) => {

  // const { loading, error, data } = useQuery(GET_CUMULATIVE_FLOW, {
  //   variables: { teamId }, fetchPolicy: "network-only"
  // });

  // if (loading) return <Loading />;
  // if (error) return <Error error={error} />;

  // const chartData = [["days", "Backlog", "Next Up", "WIP", "Completed"]];

  // for(let i = 0; i < data.cumulativeFlow.entries.length; i++) {
  //   var entry = data.cumulativeFlow.entries[i];
  //   chartData.push([entry.runDay, 
  //                   entry.storiesInBacklog, 
  //                   entry.storiesNotStarted, 
  //                   entry.storiesInProgress, 
  //                   entry.completedStories]);
  // }

  const CumulativeChartHeader = {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    marginLeft: "12%"
  };

  const renderDate = dateString => {
    if (dateString === null || dateString === "") {
      return <span />;
    }
    if (Date.parse(dateString) < 0) {
      return <span />;
    }
    const date = new Date(dateString);
    return <span>{moment(date).format("DD-MMM-YYYY")}</span>;
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <span>{user}</span><br />
          <span>Run {runNumber} </span><br />
          <span>{( renderDate( getCurrentDate()))}</span><br /><br />
        </Col>
        <Col>
          <h2 style={CumulativeChartHeader}>Cumulative Flow Diagram</h2>
        </Col>
        <Col className="text-center text-md-right">
          <img src={splLogo} style={{ width: "20%" }} alt="spl logo" />
        </Col>
      </Row>

      <Chart
        width="100%"
        height="550px"
        chartType="AreaChart"
        loader={<div>Loading Chart...</div>}
        data={chartData}
        options={{
          // isStacked: true,
          hAxis: {
            title: "Run Day", minValue: 1, titleTextStyle: { color: "#333" }, viewWindow: {
              max: 40,
              min: 1
            },
            format: '0'
          },
          vAxis: {
            title: "Story Points", minValue: 0, gridlines: {
              count: 10
            },
            format: '0'
          },
          colors: ['green', 'blue', 'violet'],
          chartArea: {
            height: "50%",
            width: "75%",
            top: "8%",
            bottom: "10%",
            left: "10%"
          },
          tooltip: { isHtml: true, trigger: "visible" }
        }}
      />

    </React.Fragment>
  );
};

export default CumulativeFlowDiagram;
