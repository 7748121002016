/* eslint-disable import/prefer-default-export */
// doing an import then export in the index file, easier with non default
import React from "react";

export const RequiredStorySummary = props => {
  const required = props.properties.find(p => p.itemKey === "required")
    .itemValue;

  const completed = props.properties.find(p => p.itemKey === "completed")
    .itemValue;
  return (
    <h6 style={{ marginTop: "20px", marginBottom: "20px" }}>
      {completed} of {required} required stories were completed.
    </h6>
  );
};
