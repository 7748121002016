
import React from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";

const WeekDaySelector = ({controlName = 'dayOfWeek', title = "Day", defaultValue, onChange}) => {

  let daysOfWeek = [
    {value: "MONDAY", label: "Monday"},
    {value: "TUESDAY", label: "Tuesday"},
    {value: "WEDNESDAY", label: "Wednesday"},
    {value: "THURSDAY", label: "Thursday"},
    {value: "FRIDAY", label: "Friday"},
  ];

  return (
    <FormGroup>
      <Label id={ 'label_' + controlName }>{ title }</Label>
      <Select 
        name={ controlName }
        aria-labelledby={ 'label_' + controlName }
        options={ daysOfWeek }
        onChange={ onChange }
        defaultValue={ daysOfWeek.find(d => d.value === defaultValue) }>
      </Select>
    </FormGroup>
  )
}

export default WeekDaySelector;