import React from "react";
import Select from "react-select";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { FormGroup, Label } from "reactstrap";
import { Error } from "../Loading";

const WorkerSelector = ({
  teamId,
  controlName,
  defaultValue,
  onChange,
  title = "Team Member",
  includeWholeTeamOption = false,
  defaultWholeTeamText = "All"
}) => {
  const WORKERS_QUERY = gql`
    query WORKERS_QUERY($teamId: Int!) {
      workers(teamId: $teamId) {
        id
        name
        firstName
        lastName
      }
    }
  `;

  const {
    data: wData,
    loading: wLoading,
    error: wError
  } = useQuery(WORKERS_QUERY, { variables: { teamId } });

  if (wLoading) return "Loading...";
  if (wError) return <Error error={wError} />;

  const workerOptions = () => {
    const options = wData.workers.map(worker => {
      return { value: worker.id, label: worker.name };
    });

    if (includeWholeTeamOption) {
      const arr = [{ value: "all", label: defaultWholeTeamText }, ...options];
      return arr;
    }

    return options;
  };

  return (
    <FormGroup>
      <Label id={`label_${controlName}`}>{title}</Label>
      <Select
        name={controlName}
        aria-labelledby={`label_${controlName}`}
        defaultValue={workerOptions().find(d => d.value === defaultValue)}
        options={workerOptions()}
        onChange={onChange}
      ></Select>
    </FormGroup>
  );
};

export default WorkerSelector;
