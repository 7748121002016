import gql from "graphql-tag";

export const ME_QUERY = gql`
  query ME_QUERY {
    me {
      id
      email
      firstName
      lastName
      welcomeStatus
      acceptedLicense
      viewedHelp
      viewedOrientation
      assignedToClassroom
      startedFirstProject
      role
      isAdmin
      classStudentRuns {
        id
        runSequenceNumber
        classroomId
        status
        classroom {
          className
        }
        team {
          id
          currentStatus
          currentWeekNumber
          currentSprintNumber
          currentSprintId
          startDate
          endDate
        }
      }
      licenses {
        id
        expirationDate
      }
      currentTeam {
        id
        endDate
        currentStatus
        currentWeekNumber
        currentRunDay
        currentWeekDay
        currentSprintNumber
        currentSprintId
        skills {
          skillNumber
          skillName
        }
        sprints {
          id
          sprintNumber
          status
        }
      }
      currentClassroom {
        id
        productNumber
        className
        status
        instructors {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const STORIES_QUERY = gql`
  query STORIES_QUERY($teamId: Int!) {
    stories: storiesByTeamId(teamId: $teamId) {
      id
      storyNumber
      storyName
      storyDescription
      storyPoints
      status
      sprintPriority
      requiredStory
      storyPercentComplete
      businessPriority
      businessPoints
      totalPlannedEffort
      currentSprint {
        id
        sprintNumber
      }
      storySkillStaffing {
        staffingRequired
        skill {
          skillName
          skillNumber
        }
      }
      workersLink {
        priority
        teamWorker {
          id
          name
          skillNumber
        }
        story {
          id
          storyNumber
        }
      }
    }
  }
`;
export const TEAM_QUERY = gql`
  query TEAM_QUERY($teamId: Int!) {
    team(id: $teamId) {
      id
      currentStatus
      endDate
      startDate
      runWeekStatus
      dayIsRunning
      currentRunDay
      currentWeekNumber
      currentWeekDay
      weekIsComplete
      projectIsComplete
      currentSprintId
      currentSprintNumber
      teamWorkers {
        id
        name
        firstName
        lastName
        status
        currentStoryNumber
        storyAssignments {
          story {
            id
            storyNumber
          }
        }
        skillNumber
      }
      sprints {
        id
        status
        sprintNumber
        stories {
          storyNumber
          storyName
          storyPoints
          status
          requiredStory
          runDayActivated
          runDayCompleted
        }
      }
      messages {
        id
        sprintId
        weekNumber
        releaseWeek
        messageFrom
        messageContent
        imageLink
        audioLink
        isRead
      }
      skills {
        skillName
        skillNumber
      }
    }
  }
`;

// instructor panel queries
export const USER_CLASSROOM_QUERY = gql`
  query USER_CLASSROOM_QUERY($userId: Int!) {
    user(userId: $userId) {
      id
      email
      welcomeStatus
      currentClassroom {
        id
        className
        instructors {
          id
          user {
            id
            firstName
            lastName
          }
          type
        }
      }
    }
  }
`;

export const WEEKDAY_INTERACTIONS_QUERY = gql`
  query WEEKDAY_INTERACTIONS_QUERY(
    $teamId: Int!
    $sprintId: Int!
    $weekNumber: Int!
  ) {
    interactions(
      teamId: $teamId
      sprintId: $sprintId
      weekNumber: $weekNumber
    ) {
      id
      weekDay
      weekNumber
      runDay
      type
      status
      conferenceDetail {
        id
        topic
        teamWorker {
          id
          name
        }
        startTime
        duration
        feedback
      }
      meetingDetail {
        id
        topic
        feedback
        startTime
        duration
        recurring
      }
      trainingDetail {
        id
        teamWorker {
          id
          name
        }
        training {
          id
          trainingNumber
          trainingName
          cost
          requiresTeam
        }
      }
      indvRewardDetail {
        id
        rewardKind
        teamWorker {
          id
          name
        }
      }
      teamRewardDetail {
        id
        rewardType
        rewardKind
      }
    }
  }
`;

export const HISTORY_QUERY = gql`
  query HISTORY_QUERY($teamId: Int!) {
    historyItems(teamId: $teamId) {
      id
      weekDay
      weekNumber
      itemComponent
      itemProperties {
        id
        itemKey
        itemValue
      }
    }
  }
`;

export const TEAM_HISTORY_QUERY = gql`
  query TEAM_HISTORY_QUERY($teamId: Int!) {
    team(id: $teamId) {
      id
      currentWeekNumber
      currentSprintNumber
      currentSprintId
      sprints {
        id
        sprintNumber
        sprintName
        status
      }
      history {
        id
        weekDay
        weekNumber
        itemComponent
        itemProperties {
          id
          itemKey
          itemValue
        }
      }
    }
  }
`;

export const GET_VELOCITY_TREND = gql`
  query GET_VELOCITY_TREND($teamId: Int!) {
    velocityTrend(teamId: $teamId) {
      maxVelocity
      velocities {
        average
        completedPoints
        sprintTitle
      }
    }
  }
`;
export const GET_BURNDOWN_QUERY = gql`
  query GET_BURNDOWN_QUERY($teamId: Int!, $sprintId: Int!) {
    burndownReport(teamId: $teamId, sprintId: $sprintId) {
      sprintDays
      pointsDataset {
        data
        label
        borderColor
        backgroundColor
      }
    }
  }
`;

export const MEMBER_LOAD_QUERY = gql`
  query MEMBER_LOAD_QUERY($teamId: Int!, $sprintId: Int!) {
    memberLoadReport(teamId: $teamId, sprintId: $sprintId) {
      totalSprintCapacity
      totalAllocated
      totalAvailable
      memberCapacity {
        id
        name
        sprintCapacity
        allocatedCapacity
        availableCapacity
        status
      }
    }
  }
`;
export const GET_CLASSROOMS = gql`
  query GET_CLASSROOM {
    classrooms {
      id
      className
      startDate
      endDate
      status
      students {
        id
        firstName
        lastName
        email
        welcomeStatus
        collegeOrCompany
        currentClassroom {
          id
          classStudentRuns {
            id
            team {
              id
              currentStatus
              currentWeekNumber
            }
          }
        }
      }
    }
  }
`;

export const INSTRUCTOR_CLASSES_QUERY = gql`
  query INSTRUCTOR_CLASSES_QUERY($instructorId: Int!) {
    instructors: instructorsByUserId(userId: $instructorId) {
      user {
        firstName
        lastName
      }
      classroom {
        id
        className
        startDate
        endDate
        status
        students {
          id
          firstName
          lastName
          email
          welcomeStatus
          collegeOrCompany
          currentClassroom {
            id
            classStudentRuns {
              id
              team {
                id
                currentStatus
                currentWeekNumber
                currentRunDay
              }
            }
          }
        }
      }
    }
  }
`;
export const STUDENTS_QUERY = gql`
  query STUDENTS_QUERY($userId: Int!) {
    user(userId: $userId) {
      id
      currentClassroom {
        id
        className
        startDate
        endDate
        teamSettings {
          id
          allowedToRun
          allowedToPlan
          runSequenceNumber
          classTeamStopWeeks {
            id
            stopAfterWeek
            sprintWeek
            stopMessage
          }
        }
        instructors {
          user {
            id
            firstName
            lastName
          }
        }
        status
        classStudentRuns {
          id
          team {
            id
            currentStatus
          }
          student {
            id
            firstName
            lastName
            email
            welcomeStatus
            collegeOrCompany
            currentClassroom {
              id
              classStudentRuns {
                id
                team {
                  id
                  currentStatus
                  currentWeekNumber
                }
              }
            }
          }
        }
      }
    }
  }
`;

// export const TEAM_CUMULATIVE_DATA_QUERY = gql`
//   query TEAM_CUMULATIVE_DATA_QUERY($teamId: Int!) {
//     team(where: { id: $teamId }) {
//       id
//       dailyStatus {
//         id
//         runDay
//         backlogStories
//         nextUpStories
//         wipStories
//         completedStories
//       }
//     }
//   }
// `;

export const STORY_WORKERS_QUERY = gql`
  query STORY_WORKERS_QUERY($storyNumber: ID) {
    story(where: { id: $storyNumber }) {
      status
      requiredStory
      storyName
      storyNumber
      businessPriority
      businessPoints
      storyPoints
      totalPlannedEffort
      currentSprint {
        id
      }
      storySkillStaffing {
        staffingRequired
        skill {
          skillName
        }
      }
      teamWorkersAssigned {
        id
        name
        skill {
          skillName
        }
      }
    }
  }
`;
// 	TODO	add back in releaseweek_lte criteria messages(where: {isRead:false, teamId: $teamId, releaseWeek_lte: $releaseWeek}) {
export const GET_UNREAD_CHAT_MESSAGES = gql`
  query GET_UNREAD_CHAT_MESSAGES($teamId: Int!, $releaseWeek: Int!) {
    unReadMessages(teamId: $teamId, releaseWeek: $releaseWeek) {
      id
      messageNumber
      isRead
      messageFrom
      messageContent
      imageLink
      audioLink
      videoLink
    }
  }
`;
