/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState } from "react";
import { Form, Button } from "reactstrap";
import gql from "graphql-tag";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { store } from "react-notifications-component";
import { formatIndividualReward } from "../../../../utilities/utils";
import Loading, { Error } from "../../../../components/Loading";
import WeekDaySelector from "../../../../components/WeekDaySelector";
import WorkerSelector from "../../../../components/Worker/WorkerSelector";
import EnumSelector from "../../../../components/EnumSelector";
import {
  WEEKDAY_INTERACTIONS_QUERY,
  MEMBER_LOAD_QUERY
} from "../../../../graphql/queries";

const UPSERT_INTERACTION_MUTATION = gql`
  mutation UpsertIndividualReward(
    $id: Int
    $teamId: Int!
    $sprintId: Int!
    $weekNumber: Int!
    $weekDay: Weekday!
    $rewardKind: IndvRewardKind!
    $teamWorkerId: Int!
  ) {
    upsertIndividualReward(
      id: $id
      teamId: $teamId
      sprintId: $sprintId
      weekNumber: $weekNumber
      weekDay: $weekDay
      rewardKind: $rewardKind
      teamWorkerId: $teamWorkerId
    ) {
      id
    }
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 200px 200px;
  grid-gap: 10px;

  > .last {
    grid-column: 1 / -1;
  }
`;

const IndvRewardForm = ({
  toggleModal,
  teamId,
  weekDay,
  type,
  sprintId,
  weekNumber,
  data
}) => {
  const [form, setValues] = useState({
    id: data ? data.id : null,
    teamId,
    sprintId,
    weekNumber,
    weekDay,
    type,
    rewardKind: data ? data.indvRewardDetail.rewardKind : null,
    teamWorkerId: data ? data.indvRewardDetail.teamWorker.id : null
  });

  const queriesToRefetch = [
    {
      query: WEEKDAY_INTERACTIONS_QUERY,
      variables: { teamId, sprintId, weekNumber }
    },
    {
      query: MEMBER_LOAD_QUERY,
      variables: { teamId, sprintId }
    }
  ];

  const [
    upsertInteraction,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(UPSERT_INTERACTION_MUTATION, {
    refetchQueries: queriesToRefetch
  });

  if (mutationError) return <Error error={mutationError} />;

  const onChangeOption = (value, action) => {
    setValues({
      ...form,
      [action.name]: value.value
    });
  };

  const onSubmitHandler = e => {
    e.preventDefault();

    if (form.weekDay === null) {
      showNotification("Please select a day of the week");
      return;
    }

    if (form.rewardKind === null) {
      showNotification("Please select an option of reward");
      return;
    }

    if (form.teamWorkerId === null) {
      showNotification("Please select a team member");
      return;
    }

    form.teamWorkerId = parseInt(form.teamWorkerId);
    upsertInteraction({ variables: { ...form } }).then(response => {
      toggleModal();
    });
  };

  const showNotification = text => {
    store.addNotification({
      message: text,
      container: "top-right",
      type: "warning",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "bounceOut"],
      dismiss: { duration: 5000 },
      width: 294,
      content: <div className="notification-sim">{text}</div>
    });
  };

  return (
    <StyledForm id="indv-form" onSubmit={onSubmitHandler}>
      <WeekDaySelector
        controlName="weekDay"
        defaultValue={weekDay}
        onChange={onChangeOption}
      />

      <EnumSelector
        enumType="IndvRewardKind"
        title="Reward"
        controlName="rewardKind"
        formatProvider={formatIndividualReward}
        defaultValue={form.rewardKind}
        onChange={onChangeOption}
      />

      <WorkerSelector
        controlName="teamWorkerId"
        teamId={teamId}
        defaultValue={form.teamWorkerId}
        onChange={onChangeOption}
      />

      <Button
        disabled={
          mutationLoading ||
          (data && Object.values(form).some(value => value === null))
        }
        className="last"
        color="primary"
        type="submit"
      >
        {mutationLoading ? (
          <Loading text={data ? "Updating" : "Saving"} />
        ) : data ? (
          "Update"
        ) : (
          "Save"
        )}
      </Button>
    </StyledForm>
  );
};

export default IndvRewardForm;
